import { createUseStyles } from "react-jss";
import { Spin } from "antd";

export const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Spin size="large" />
    </div>
  );
};

const useStyles = createUseStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
});

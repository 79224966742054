import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  jsonb: { input: any; output: any; }
  point: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  uuid: { input: any; output: any; }
};

export type GetDeliveriesInput = {
  endDate?: InputMaybe<Scalars['timestamptz']['input']>;
  startDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

export type GetDeliveriesOutput = {
  __typename?: 'GetDeliveriesOutput';
  affectedRows: Scalars['Int']['output'];
};

export type GetUserInfoInput = {
  lrpToken: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  userRole: Scalars['String']['input'];
};

export type GetUserInfoOutput = {
  __typename?: 'GetUserInfoOutput';
  affectedRows: Scalars['Int']['output'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** A company or a person legally entitled to transport goods by land, water, and air. */
export type Carriers = {
  __typename?: 'carriers';
  clientId: Scalars['uuid']['output'];
  createdAt: Scalars['timestamptz']['output'];
  email: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  users_carriers: Array<Users_Carriers>;
  /** An aggregate relationship */
  users_carriers_aggregate: Users_Carriers_Aggregate;
};


/** A company or a person legally entitled to transport goods by land, water, and air. */
export type CarriersUsers_CarriersArgs = {
  distinct_on?: InputMaybe<Array<Users_Carriers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Carriers_Order_By>>;
  where?: InputMaybe<Users_Carriers_Bool_Exp>;
};


/** A company or a person legally entitled to transport goods by land, water, and air. */
export type CarriersUsers_Carriers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Carriers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Carriers_Order_By>>;
  where?: InputMaybe<Users_Carriers_Bool_Exp>;
};

/** aggregated selection of "carriers" */
export type Carriers_Aggregate = {
  __typename?: 'carriers_aggregate';
  aggregate?: Maybe<Carriers_Aggregate_Fields>;
  nodes: Array<Carriers>;
};

/** aggregate fields of "carriers" */
export type Carriers_Aggregate_Fields = {
  __typename?: 'carriers_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Carriers_Max_Fields>;
  min?: Maybe<Carriers_Min_Fields>;
};


/** aggregate fields of "carriers" */
export type Carriers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Carriers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "carriers". All fields are combined with a logical 'AND'. */
export type Carriers_Bool_Exp = {
  _and?: InputMaybe<Array<Carriers_Bool_Exp>>;
  _not?: InputMaybe<Carriers_Bool_Exp>;
  _or?: InputMaybe<Array<Carriers_Bool_Exp>>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  users_carriers?: InputMaybe<Users_Carriers_Bool_Exp>;
  users_carriers_aggregate?: InputMaybe<Users_Carriers_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "carriers" */
export enum Carriers_Constraint {
  /** unique or primary key constraint on columns "name", "external_id" */
  CarriersNameExternalIdKey = 'carriers_name_external_id_key',
  /** unique or primary key constraint on columns "id" */
  CarriersPkey = 'carriers_pkey'
}

/** input type for inserting data into table "carriers" */
export type Carriers_Insert_Input = {
  clientId?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  users_carriers?: InputMaybe<Users_Carriers_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Carriers_Max_Fields = {
  __typename?: 'carriers_max_fields';
  clientId?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Carriers_Min_Fields = {
  __typename?: 'carriers_min_fields';
  clientId?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "carriers" */
export type Carriers_Mutation_Response = {
  __typename?: 'carriers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Carriers>;
};

/** input type for inserting object relation for remote table "carriers" */
export type Carriers_Obj_Rel_Insert_Input = {
  data: Carriers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Carriers_On_Conflict>;
};

/** on_conflict condition type for table "carriers" */
export type Carriers_On_Conflict = {
  constraint: Carriers_Constraint;
  update_columns?: Array<Carriers_Update_Column>;
  where?: InputMaybe<Carriers_Bool_Exp>;
};

/** Ordering options when selecting data from "carriers". */
export type Carriers_Order_By = {
  clientId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  users_carriers_aggregate?: InputMaybe<Users_Carriers_Aggregate_Order_By>;
};

/** primary key columns input for table: carriers */
export type Carriers_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "carriers" */
export enum Carriers_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "carriers" */
export type Carriers_Set_Input = {
  clientId?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "carriers" */
export type Carriers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Carriers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Carriers_Stream_Cursor_Value_Input = {
  clientId?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "carriers" */
export enum Carriers_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Carriers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Carriers_Set_Input>;
  where: Carriers_Bool_Exp;
};

/** columns and relationships of "client_status" */
export type ClientStatus = {
  __typename?: 'clientStatus';
  value: Scalars['String']['output'];
};

/** aggregated selection of "client_status" */
export type ClientStatus_Aggregate = {
  __typename?: 'clientStatus_aggregate';
  aggregate?: Maybe<ClientStatus_Aggregate_Fields>;
  nodes: Array<ClientStatus>;
};

/** aggregate fields of "client_status" */
export type ClientStatus_Aggregate_Fields = {
  __typename?: 'clientStatus_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ClientStatus_Max_Fields>;
  min?: Maybe<ClientStatus_Min_Fields>;
};


/** aggregate fields of "client_status" */
export type ClientStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ClientStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "client_status". All fields are combined with a logical 'AND'. */
export type ClientStatus_Bool_Exp = {
  _and?: InputMaybe<Array<ClientStatus_Bool_Exp>>;
  _not?: InputMaybe<ClientStatus_Bool_Exp>;
  _or?: InputMaybe<Array<ClientStatus_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_status" */
export enum ClientStatus_Constraint {
  /** unique or primary key constraint on columns "value" */
  ClientStatusPkey = 'client_status_pkey'
}

export enum ClientStatus_Enum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

/** Boolean expression to compare columns of type "clientStatus_enum". All fields are combined with logical 'AND'. */
export type ClientStatus_Enum_Comparison_Exp = {
  _eq?: InputMaybe<ClientStatus_Enum>;
  _in?: InputMaybe<Array<ClientStatus_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ClientStatus_Enum>;
  _nin?: InputMaybe<Array<ClientStatus_Enum>>;
};

/** input type for inserting data into table "client_status" */
export type ClientStatus_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ClientStatus_Max_Fields = {
  __typename?: 'clientStatus_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ClientStatus_Min_Fields = {
  __typename?: 'clientStatus_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "client_status" */
export type ClientStatus_Mutation_Response = {
  __typename?: 'clientStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ClientStatus>;
};

/** on_conflict condition type for table "client_status" */
export type ClientStatus_On_Conflict = {
  constraint: ClientStatus_Constraint;
  update_columns?: Array<ClientStatus_Update_Column>;
  where?: InputMaybe<ClientStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "client_status". */
export type ClientStatus_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_status */
export type ClientStatus_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "client_status" */
export enum ClientStatus_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "client_status" */
export type ClientStatus_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "clientStatus" */
export type ClientStatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ClientStatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ClientStatus_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "client_status" */
export enum ClientStatus_Update_Column {
  /** column name */
  Value = 'value'
}

export type ClientStatus_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClientStatus_Set_Input>;
  where: ClientStatus_Bool_Exp;
};

/** columns and relationships of "clients" */
export type Clients = {
  __typename?: 'clients';
  address?: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  email: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  status: ClientStatus_Enum;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "clients" */
export type Clients_Aggregate = {
  __typename?: 'clients_aggregate';
  aggregate?: Maybe<Clients_Aggregate_Fields>;
  nodes: Array<Clients>;
};

/** aggregate fields of "clients" */
export type Clients_Aggregate_Fields = {
  __typename?: 'clients_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Clients_Max_Fields>;
  min?: Maybe<Clients_Min_Fields>;
};


/** aggregate fields of "clients" */
export type Clients_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Clients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "clients". All fields are combined with a logical 'AND'. */
export type Clients_Bool_Exp = {
  _and?: InputMaybe<Array<Clients_Bool_Exp>>;
  _not?: InputMaybe<Clients_Bool_Exp>;
  _or?: InputMaybe<Array<Clients_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  companyName?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<ClientStatus_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "clients" */
export enum Clients_Constraint {
  /** unique or primary key constraint on columns "name", "external_id" */
  ClientsNameExternalIdKey = 'clients_name_external_id_key',
  /** unique or primary key constraint on columns "id" */
  ClientsPkey = 'clients_pkey'
}

/** input type for inserting data into table "clients" */
export type Clients_Insert_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ClientStatus_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Clients_Max_Fields = {
  __typename?: 'clients_max_fields';
  address?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Clients_Min_Fields = {
  __typename?: 'clients_min_fields';
  address?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "clients" */
export type Clients_Mutation_Response = {
  __typename?: 'clients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Clients>;
};

/** input type for inserting object relation for remote table "clients" */
export type Clients_Obj_Rel_Insert_Input = {
  data: Clients_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Clients_On_Conflict>;
};

/** on_conflict condition type for table "clients" */
export type Clients_On_Conflict = {
  constraint: Clients_Constraint;
  update_columns?: Array<Clients_Update_Column>;
  where?: InputMaybe<Clients_Bool_Exp>;
};

/** Ordering options when selecting data from "clients". */
export type Clients_Order_By = {
  address?: InputMaybe<Order_By>;
  companyName?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clients */
export type Clients_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "clients" */
export enum Clients_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "clients" */
export type Clients_Set_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ClientStatus_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "clients" */
export type Clients_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clients_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clients_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ClientStatus_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "clients" */
export enum Clients_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Clients_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Clients_Set_Input>;
  where: Clients_Bool_Exp;
};

/** A person or company to whom goods is to be delivered to. */
export type Consignees = {
  __typename?: 'consignees';
  address?: Maybe<Scalars['String']['output']>;
  clientId: Scalars['uuid']['output'];
  coordinate?: Maybe<Scalars['point']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  users_consignees: Array<Users_Consignees>;
  /** An aggregate relationship */
  users_consignees_aggregate: Users_Consignees_Aggregate;
};


/** A person or company to whom goods is to be delivered to. */
export type ConsigneesUsers_ConsigneesArgs = {
  distinct_on?: InputMaybe<Array<Users_Consignees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Consignees_Order_By>>;
  where?: InputMaybe<Users_Consignees_Bool_Exp>;
};


/** A person or company to whom goods is to be delivered to. */
export type ConsigneesUsers_Consignees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Consignees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Consignees_Order_By>>;
  where?: InputMaybe<Users_Consignees_Bool_Exp>;
};

/** aggregated selection of "consignees" */
export type Consignees_Aggregate = {
  __typename?: 'consignees_aggregate';
  aggregate?: Maybe<Consignees_Aggregate_Fields>;
  nodes: Array<Consignees>;
};

/** aggregate fields of "consignees" */
export type Consignees_Aggregate_Fields = {
  __typename?: 'consignees_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Consignees_Max_Fields>;
  min?: Maybe<Consignees_Min_Fields>;
};


/** aggregate fields of "consignees" */
export type Consignees_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Consignees_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "consignees". All fields are combined with a logical 'AND'. */
export type Consignees_Bool_Exp = {
  _and?: InputMaybe<Array<Consignees_Bool_Exp>>;
  _not?: InputMaybe<Consignees_Bool_Exp>;
  _or?: InputMaybe<Array<Consignees_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  coordinate?: InputMaybe<Point_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  fullName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  users_consignees?: InputMaybe<Users_Consignees_Bool_Exp>;
  users_consignees_aggregate?: InputMaybe<Users_Consignees_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "consignees" */
export enum Consignees_Constraint {
  /** unique or primary key constraint on columns "name", "external_id" */
  ConsigneesNameExternalIdKey = 'consignees_name_external__id_key',
  /** unique or primary key constraint on columns "id" */
  ConsigneesPkey = 'consignees_pkey'
}

/** input type for inserting data into table "consignees" */
export type Consignees_Insert_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['uuid']['input']>;
  coordinate?: InputMaybe<Scalars['point']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  users_consignees?: InputMaybe<Users_Consignees_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Consignees_Max_Fields = {
  __typename?: 'consignees_max_fields';
  address?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Consignees_Min_Fields = {
  __typename?: 'consignees_min_fields';
  address?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "consignees" */
export type Consignees_Mutation_Response = {
  __typename?: 'consignees_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Consignees>;
};

/** input type for inserting object relation for remote table "consignees" */
export type Consignees_Obj_Rel_Insert_Input = {
  data: Consignees_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Consignees_On_Conflict>;
};

/** on_conflict condition type for table "consignees" */
export type Consignees_On_Conflict = {
  constraint: Consignees_Constraint;
  update_columns?: Array<Consignees_Update_Column>;
  where?: InputMaybe<Consignees_Bool_Exp>;
};

/** Ordering options when selecting data from "consignees". */
export type Consignees_Order_By = {
  address?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  coordinate?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  fullName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  users_consignees_aggregate?: InputMaybe<Users_Consignees_Aggregate_Order_By>;
};

/** primary key columns input for table: consignees */
export type Consignees_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "consignees" */
export enum Consignees_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  Coordinate = 'coordinate',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  FullName = 'fullName',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "consignees" */
export type Consignees_Set_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['uuid']['input']>;
  coordinate?: InputMaybe<Scalars['point']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "consignees" */
export type Consignees_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Consignees_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Consignees_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['uuid']['input']>;
  coordinate?: InputMaybe<Scalars['point']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "consignees" */
export enum Consignees_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  Coordinate = 'coordinate',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  FullName = 'fullName',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Consignees_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Consignees_Set_Input>;
  where: Consignees_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "delays" */
export type Delays = {
  __typename?: 'delays';
  delayTime: Scalars['Int']['output'];
  /** An object relationship */
  delivery: Deliveries;
  deliveryId: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  reason: Scalars['String']['output'];
};

/** aggregated selection of "delays" */
export type Delays_Aggregate = {
  __typename?: 'delays_aggregate';
  aggregate?: Maybe<Delays_Aggregate_Fields>;
  nodes: Array<Delays>;
};

export type Delays_Aggregate_Bool_Exp = {
  count?: InputMaybe<Delays_Aggregate_Bool_Exp_Count>;
};

export type Delays_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Delays_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Delays_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "delays" */
export type Delays_Aggregate_Fields = {
  __typename?: 'delays_aggregate_fields';
  avg?: Maybe<Delays_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Delays_Max_Fields>;
  min?: Maybe<Delays_Min_Fields>;
  stddev?: Maybe<Delays_Stddev_Fields>;
  stddev_pop?: Maybe<Delays_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Delays_Stddev_Samp_Fields>;
  sum?: Maybe<Delays_Sum_Fields>;
  var_pop?: Maybe<Delays_Var_Pop_Fields>;
  var_samp?: Maybe<Delays_Var_Samp_Fields>;
  variance?: Maybe<Delays_Variance_Fields>;
};


/** aggregate fields of "delays" */
export type Delays_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Delays_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "delays" */
export type Delays_Aggregate_Order_By = {
  avg?: InputMaybe<Delays_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Delays_Max_Order_By>;
  min?: InputMaybe<Delays_Min_Order_By>;
  stddev?: InputMaybe<Delays_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Delays_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Delays_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Delays_Sum_Order_By>;
  var_pop?: InputMaybe<Delays_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Delays_Var_Samp_Order_By>;
  variance?: InputMaybe<Delays_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "delays" */
export type Delays_Arr_Rel_Insert_Input = {
  data: Array<Delays_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Delays_On_Conflict>;
};

/** aggregate avg on columns */
export type Delays_Avg_Fields = {
  __typename?: 'delays_avg_fields';
  delayTime?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "delays" */
export type Delays_Avg_Order_By = {
  delayTime?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "delays". All fields are combined with a logical 'AND'. */
export type Delays_Bool_Exp = {
  _and?: InputMaybe<Array<Delays_Bool_Exp>>;
  _not?: InputMaybe<Delays_Bool_Exp>;
  _or?: InputMaybe<Array<Delays_Bool_Exp>>;
  delayTime?: InputMaybe<Int_Comparison_Exp>;
  delivery?: InputMaybe<Deliveries_Bool_Exp>;
  deliveryId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "delays" */
export enum Delays_Constraint {
  /** unique or primary key constraint on columns "id" */
  DelaysPkey = 'delays_pkey'
}

/** input type for incrementing numeric columns in table "delays" */
export type Delays_Inc_Input = {
  delayTime?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "delays" */
export type Delays_Insert_Input = {
  delayTime?: InputMaybe<Scalars['Int']['input']>;
  delivery?: InputMaybe<Deliveries_Obj_Rel_Insert_Input>;
  deliveryId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Delays_Max_Fields = {
  __typename?: 'delays_max_fields';
  delayTime?: Maybe<Scalars['Int']['output']>;
  deliveryId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "delays" */
export type Delays_Max_Order_By = {
  delayTime?: InputMaybe<Order_By>;
  deliveryId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Delays_Min_Fields = {
  __typename?: 'delays_min_fields';
  delayTime?: Maybe<Scalars['Int']['output']>;
  deliveryId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "delays" */
export type Delays_Min_Order_By = {
  delayTime?: InputMaybe<Order_By>;
  deliveryId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "delays" */
export type Delays_Mutation_Response = {
  __typename?: 'delays_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Delays>;
};

/** on_conflict condition type for table "delays" */
export type Delays_On_Conflict = {
  constraint: Delays_Constraint;
  update_columns?: Array<Delays_Update_Column>;
  where?: InputMaybe<Delays_Bool_Exp>;
};

/** Ordering options when selecting data from "delays". */
export type Delays_Order_By = {
  delayTime?: InputMaybe<Order_By>;
  delivery?: InputMaybe<Deliveries_Order_By>;
  deliveryId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
};

/** primary key columns input for table: delays */
export type Delays_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "delays" */
export enum Delays_Select_Column {
  /** column name */
  DelayTime = 'delayTime',
  /** column name */
  DeliveryId = 'deliveryId',
  /** column name */
  Id = 'id',
  /** column name */
  Reason = 'reason'
}

/** input type for updating data in table "delays" */
export type Delays_Set_Input = {
  delayTime?: InputMaybe<Scalars['Int']['input']>;
  deliveryId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Delays_Stddev_Fields = {
  __typename?: 'delays_stddev_fields';
  delayTime?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "delays" */
export type Delays_Stddev_Order_By = {
  delayTime?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Delays_Stddev_Pop_Fields = {
  __typename?: 'delays_stddev_pop_fields';
  delayTime?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "delays" */
export type Delays_Stddev_Pop_Order_By = {
  delayTime?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Delays_Stddev_Samp_Fields = {
  __typename?: 'delays_stddev_samp_fields';
  delayTime?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "delays" */
export type Delays_Stddev_Samp_Order_By = {
  delayTime?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "delays" */
export type Delays_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Delays_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Delays_Stream_Cursor_Value_Input = {
  delayTime?: InputMaybe<Scalars['Int']['input']>;
  deliveryId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Delays_Sum_Fields = {
  __typename?: 'delays_sum_fields';
  delayTime?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "delays" */
export type Delays_Sum_Order_By = {
  delayTime?: InputMaybe<Order_By>;
};

/** update columns of table "delays" */
export enum Delays_Update_Column {
  /** column name */
  DelayTime = 'delayTime',
  /** column name */
  DeliveryId = 'deliveryId',
  /** column name */
  Id = 'id',
  /** column name */
  Reason = 'reason'
}

export type Delays_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Delays_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Delays_Set_Input>;
  where: Delays_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Delays_Var_Pop_Fields = {
  __typename?: 'delays_var_pop_fields';
  delayTime?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "delays" */
export type Delays_Var_Pop_Order_By = {
  delayTime?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Delays_Var_Samp_Fields = {
  __typename?: 'delays_var_samp_fields';
  delayTime?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "delays" */
export type Delays_Var_Samp_Order_By = {
  delayTime?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Delays_Variance_Fields = {
  __typename?: 'delays_variance_fields';
  delayTime?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "delays" */
export type Delays_Variance_Order_By = {
  delayTime?: InputMaybe<Order_By>;
};

/** columns and relationships of "deliveries" */
export type Deliveries = {
  __typename?: 'deliveries';
  /** An object relationship */
  client: Clients;
  clientId: Scalars['uuid']['output'];
  createdAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  delays: Array<Delays>;
  /** An aggregate relationship */
  delays_aggregate: Delays_Aggregate;
  /** An array relationship */
  delivery_guest_accesses: Array<DeliveryGuestAccesses>;
  /** An aggregate relationship */
  delivery_guest_accesses_aggregate: DeliveryGuestAccesses_Aggregate;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  progress: DeliveryProgress_Enum;
  /** An array relationship */
  rides: Array<Rides>;
  /** An aggregate relationship */
  rides_aggregate: Rides_Aggregate;
  status: DeliveryStatus_Enum;
  timeLocal: Scalars['timestamptz']['output'];
  /** An object relationship */
  truck?: Maybe<Trucks>;
  truckId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "deliveries" */
export type DeliveriesDelaysArgs = {
  distinct_on?: InputMaybe<Array<Delays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delays_Order_By>>;
  where?: InputMaybe<Delays_Bool_Exp>;
};


/** columns and relationships of "deliveries" */
export type DeliveriesDelays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delays_Order_By>>;
  where?: InputMaybe<Delays_Bool_Exp>;
};


/** columns and relationships of "deliveries" */
export type DeliveriesDelivery_Guest_AccessesArgs = {
  distinct_on?: InputMaybe<Array<DeliveryGuestAccesses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeliveryGuestAccesses_Order_By>>;
  where?: InputMaybe<DeliveryGuestAccesses_Bool_Exp>;
};


/** columns and relationships of "deliveries" */
export type DeliveriesDelivery_Guest_Accesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DeliveryGuestAccesses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeliveryGuestAccesses_Order_By>>;
  where?: InputMaybe<DeliveryGuestAccesses_Bool_Exp>;
};


/** columns and relationships of "deliveries" */
export type DeliveriesRidesArgs = {
  distinct_on?: InputMaybe<Array<Rides_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rides_Order_By>>;
  where?: InputMaybe<Rides_Bool_Exp>;
};


/** columns and relationships of "deliveries" */
export type DeliveriesRides_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rides_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rides_Order_By>>;
  where?: InputMaybe<Rides_Bool_Exp>;
};

/** aggregated selection of "deliveries" */
export type Deliveries_Aggregate = {
  __typename?: 'deliveries_aggregate';
  aggregate?: Maybe<Deliveries_Aggregate_Fields>;
  nodes: Array<Deliveries>;
};

/** aggregate fields of "deliveries" */
export type Deliveries_Aggregate_Fields = {
  __typename?: 'deliveries_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Deliveries_Max_Fields>;
  min?: Maybe<Deliveries_Min_Fields>;
};


/** aggregate fields of "deliveries" */
export type Deliveries_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deliveries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "deliveries". All fields are combined with a logical 'AND'. */
export type Deliveries_Bool_Exp = {
  _and?: InputMaybe<Array<Deliveries_Bool_Exp>>;
  _not?: InputMaybe<Deliveries_Bool_Exp>;
  _or?: InputMaybe<Array<Deliveries_Bool_Exp>>;
  client?: InputMaybe<Clients_Bool_Exp>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  delays?: InputMaybe<Delays_Bool_Exp>;
  delays_aggregate?: InputMaybe<Delays_Aggregate_Bool_Exp>;
  delivery_guest_accesses?: InputMaybe<DeliveryGuestAccesses_Bool_Exp>;
  delivery_guest_accesses_aggregate?: InputMaybe<DeliveryGuestAccesses_Aggregate_Bool_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  progress?: InputMaybe<DeliveryProgress_Enum_Comparison_Exp>;
  rides?: InputMaybe<Rides_Bool_Exp>;
  rides_aggregate?: InputMaybe<Rides_Aggregate_Bool_Exp>;
  status?: InputMaybe<DeliveryStatus_Enum_Comparison_Exp>;
  timeLocal?: InputMaybe<Timestamptz_Comparison_Exp>;
  truck?: InputMaybe<Trucks_Bool_Exp>;
  truckId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "deliveries" */
export enum Deliveries_Constraint {
  /** unique or primary key constraint on columns "external_id", "client_id" */
  DeliveriesClientIdExternalIdKey = 'deliveries_client_id_external_id_key',
  /** unique or primary key constraint on columns "id" */
  DeliveriesiPkey = 'deliveriesi_pkey'
}

/** input type for inserting data into table "deliveries" */
export type Deliveries_Insert_Input = {
  client?: InputMaybe<Clients_Obj_Rel_Insert_Input>;
  clientId?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  delays?: InputMaybe<Delays_Arr_Rel_Insert_Input>;
  delivery_guest_accesses?: InputMaybe<DeliveryGuestAccesses_Arr_Rel_Insert_Input>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  progress?: InputMaybe<DeliveryProgress_Enum>;
  rides?: InputMaybe<Rides_Arr_Rel_Insert_Input>;
  status?: InputMaybe<DeliveryStatus_Enum>;
  timeLocal?: InputMaybe<Scalars['timestamptz']['input']>;
  truck?: InputMaybe<Trucks_Obj_Rel_Insert_Input>;
  truckId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Deliveries_Max_Fields = {
  __typename?: 'deliveries_max_fields';
  clientId?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  timeLocal?: Maybe<Scalars['timestamptz']['output']>;
  truckId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Deliveries_Min_Fields = {
  __typename?: 'deliveries_min_fields';
  clientId?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  timeLocal?: Maybe<Scalars['timestamptz']['output']>;
  truckId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "deliveries" */
export type Deliveries_Mutation_Response = {
  __typename?: 'deliveries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveries>;
};

/** input type for inserting object relation for remote table "deliveries" */
export type Deliveries_Obj_Rel_Insert_Input = {
  data: Deliveries_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Deliveries_On_Conflict>;
};

/** on_conflict condition type for table "deliveries" */
export type Deliveries_On_Conflict = {
  constraint: Deliveries_Constraint;
  update_columns?: Array<Deliveries_Update_Column>;
  where?: InputMaybe<Deliveries_Bool_Exp>;
};

/** Ordering options when selecting data from "deliveries". */
export type Deliveries_Order_By = {
  client?: InputMaybe<Clients_Order_By>;
  clientId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  delays_aggregate?: InputMaybe<Delays_Aggregate_Order_By>;
  delivery_guest_accesses_aggregate?: InputMaybe<DeliveryGuestAccesses_Aggregate_Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  rides_aggregate?: InputMaybe<Rides_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  timeLocal?: InputMaybe<Order_By>;
  truck?: InputMaybe<Trucks_Order_By>;
  truckId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: deliveries */
export type Deliveries_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "deliveries" */
export enum Deliveries_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  Progress = 'progress',
  /** column name */
  Status = 'status',
  /** column name */
  TimeLocal = 'timeLocal',
  /** column name */
  TruckId = 'truckId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "deliveries" */
export type Deliveries_Set_Input = {
  clientId?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  progress?: InputMaybe<DeliveryProgress_Enum>;
  status?: InputMaybe<DeliveryStatus_Enum>;
  timeLocal?: InputMaybe<Scalars['timestamptz']['input']>;
  truckId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "deliveries" */
export type Deliveries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deliveries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deliveries_Stream_Cursor_Value_Input = {
  clientId?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  progress?: InputMaybe<DeliveryProgress_Enum>;
  status?: InputMaybe<DeliveryStatus_Enum>;
  timeLocal?: InputMaybe<Scalars['timestamptz']['input']>;
  truckId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "deliveries" */
export enum Deliveries_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  Progress = 'progress',
  /** column name */
  Status = 'status',
  /** column name */
  TimeLocal = 'timeLocal',
  /** column name */
  TruckId = 'truckId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Deliveries_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deliveries_Set_Input>;
  where: Deliveries_Bool_Exp;
};

/** columns and relationships of "delivery_guest_accesses" */
export type DeliveryGuestAccesses = {
  __typename?: 'deliveryGuestAccesses';
  createdAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  delivery: Deliveries;
  deliveryId: Scalars['uuid']['output'];
  guestId: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "delivery_guest_accesses" */
export type DeliveryGuestAccesses_Aggregate = {
  __typename?: 'deliveryGuestAccesses_aggregate';
  aggregate?: Maybe<DeliveryGuestAccesses_Aggregate_Fields>;
  nodes: Array<DeliveryGuestAccesses>;
};

export type DeliveryGuestAccesses_Aggregate_Bool_Exp = {
  count?: InputMaybe<DeliveryGuestAccesses_Aggregate_Bool_Exp_Count>;
};

export type DeliveryGuestAccesses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<DeliveryGuestAccesses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<DeliveryGuestAccesses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "delivery_guest_accesses" */
export type DeliveryGuestAccesses_Aggregate_Fields = {
  __typename?: 'deliveryGuestAccesses_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DeliveryGuestAccesses_Max_Fields>;
  min?: Maybe<DeliveryGuestAccesses_Min_Fields>;
};


/** aggregate fields of "delivery_guest_accesses" */
export type DeliveryGuestAccesses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DeliveryGuestAccesses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "delivery_guest_accesses" */
export type DeliveryGuestAccesses_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<DeliveryGuestAccesses_Max_Order_By>;
  min?: InputMaybe<DeliveryGuestAccesses_Min_Order_By>;
};

/** input type for inserting array relation for remote table "delivery_guest_accesses" */
export type DeliveryGuestAccesses_Arr_Rel_Insert_Input = {
  data: Array<DeliveryGuestAccesses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<DeliveryGuestAccesses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "delivery_guest_accesses". All fields are combined with a logical 'AND'. */
export type DeliveryGuestAccesses_Bool_Exp = {
  _and?: InputMaybe<Array<DeliveryGuestAccesses_Bool_Exp>>;
  _not?: InputMaybe<DeliveryGuestAccesses_Bool_Exp>;
  _or?: InputMaybe<Array<DeliveryGuestAccesses_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  delivery?: InputMaybe<Deliveries_Bool_Exp>;
  deliveryId?: InputMaybe<Uuid_Comparison_Exp>;
  guestId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "delivery_guest_accesses" */
export enum DeliveryGuestAccesses_Constraint {
  /** unique or primary key constraint on columns "guest_id", "delivery_id" */
  DeliveryGuestAccessesGuestIdDeliveryIdKey = 'delivery_guest_accesses_guest_id_delivery_id_key',
  /** unique or primary key constraint on columns "id" */
  DeliveryGuestAccessesPkey = 'delivery_guest_accesses_pkey'
}

/** input type for inserting data into table "delivery_guest_accesses" */
export type DeliveryGuestAccesses_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  delivery?: InputMaybe<Deliveries_Obj_Rel_Insert_Input>;
  deliveryId?: InputMaybe<Scalars['uuid']['input']>;
  guestId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type DeliveryGuestAccesses_Max_Fields = {
  __typename?: 'deliveryGuestAccesses_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deliveryId?: Maybe<Scalars['uuid']['output']>;
  guestId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "delivery_guest_accesses" */
export type DeliveryGuestAccesses_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deliveryId?: InputMaybe<Order_By>;
  guestId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type DeliveryGuestAccesses_Min_Fields = {
  __typename?: 'deliveryGuestAccesses_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deliveryId?: Maybe<Scalars['uuid']['output']>;
  guestId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "delivery_guest_accesses" */
export type DeliveryGuestAccesses_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deliveryId?: InputMaybe<Order_By>;
  guestId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "delivery_guest_accesses" */
export type DeliveryGuestAccesses_Mutation_Response = {
  __typename?: 'deliveryGuestAccesses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DeliveryGuestAccesses>;
};

/** on_conflict condition type for table "delivery_guest_accesses" */
export type DeliveryGuestAccesses_On_Conflict = {
  constraint: DeliveryGuestAccesses_Constraint;
  update_columns?: Array<DeliveryGuestAccesses_Update_Column>;
  where?: InputMaybe<DeliveryGuestAccesses_Bool_Exp>;
};

/** Ordering options when selecting data from "delivery_guest_accesses". */
export type DeliveryGuestAccesses_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  delivery?: InputMaybe<Deliveries_Order_By>;
  deliveryId?: InputMaybe<Order_By>;
  guestId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: delivery_guest_accesses */
export type DeliveryGuestAccesses_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "delivery_guest_accesses" */
export enum DeliveryGuestAccesses_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveryId = 'deliveryId',
  /** column name */
  GuestId = 'guestId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "delivery_guest_accesses" */
export type DeliveryGuestAccesses_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deliveryId?: InputMaybe<Scalars['uuid']['input']>;
  guestId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "deliveryGuestAccesses" */
export type DeliveryGuestAccesses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DeliveryGuestAccesses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DeliveryGuestAccesses_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deliveryId?: InputMaybe<Scalars['uuid']['input']>;
  guestId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "delivery_guest_accesses" */
export enum DeliveryGuestAccesses_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveryId = 'deliveryId',
  /** column name */
  GuestId = 'guestId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type DeliveryGuestAccesses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DeliveryGuestAccesses_Set_Input>;
  where: DeliveryGuestAccesses_Bool_Exp;
};

/** columns and relationships of "delivery_progress" */
export type DeliveryProgress = {
  __typename?: 'deliveryProgress';
  value: Scalars['String']['output'];
};

/** aggregated selection of "delivery_progress" */
export type DeliveryProgress_Aggregate = {
  __typename?: 'deliveryProgress_aggregate';
  aggregate?: Maybe<DeliveryProgress_Aggregate_Fields>;
  nodes: Array<DeliveryProgress>;
};

/** aggregate fields of "delivery_progress" */
export type DeliveryProgress_Aggregate_Fields = {
  __typename?: 'deliveryProgress_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DeliveryProgress_Max_Fields>;
  min?: Maybe<DeliveryProgress_Min_Fields>;
};


/** aggregate fields of "delivery_progress" */
export type DeliveryProgress_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DeliveryProgress_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "delivery_progress". All fields are combined with a logical 'AND'. */
export type DeliveryProgress_Bool_Exp = {
  _and?: InputMaybe<Array<DeliveryProgress_Bool_Exp>>;
  _not?: InputMaybe<DeliveryProgress_Bool_Exp>;
  _or?: InputMaybe<Array<DeliveryProgress_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "delivery_progress" */
export enum DeliveryProgress_Constraint {
  /** unique or primary key constraint on columns "value" */
  DeliveryProgressPkey = 'DeliveryProgress_pkey'
}

export enum DeliveryProgress_Enum {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Scheduled = 'SCHEDULED'
}

/** Boolean expression to compare columns of type "deliveryProgress_enum". All fields are combined with logical 'AND'. */
export type DeliveryProgress_Enum_Comparison_Exp = {
  _eq?: InputMaybe<DeliveryProgress_Enum>;
  _in?: InputMaybe<Array<DeliveryProgress_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<DeliveryProgress_Enum>;
  _nin?: InputMaybe<Array<DeliveryProgress_Enum>>;
};

/** input type for inserting data into table "delivery_progress" */
export type DeliveryProgress_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type DeliveryProgress_Max_Fields = {
  __typename?: 'deliveryProgress_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DeliveryProgress_Min_Fields = {
  __typename?: 'deliveryProgress_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "delivery_progress" */
export type DeliveryProgress_Mutation_Response = {
  __typename?: 'deliveryProgress_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DeliveryProgress>;
};

/** on_conflict condition type for table "delivery_progress" */
export type DeliveryProgress_On_Conflict = {
  constraint: DeliveryProgress_Constraint;
  update_columns?: Array<DeliveryProgress_Update_Column>;
  where?: InputMaybe<DeliveryProgress_Bool_Exp>;
};

/** Ordering options when selecting data from "delivery_progress". */
export type DeliveryProgress_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: delivery_progress */
export type DeliveryProgress_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "delivery_progress" */
export enum DeliveryProgress_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "delivery_progress" */
export type DeliveryProgress_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "deliveryProgress" */
export type DeliveryProgress_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DeliveryProgress_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DeliveryProgress_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "delivery_progress" */
export enum DeliveryProgress_Update_Column {
  /** column name */
  Value = 'value'
}

export type DeliveryProgress_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DeliveryProgress_Set_Input>;
  where: DeliveryProgress_Bool_Exp;
};

/** columns and relationships of "delivery_status" */
export type DeliveryStatus = {
  __typename?: 'deliveryStatus';
  value: Scalars['String']['output'];
};

/** aggregated selection of "delivery_status" */
export type DeliveryStatus_Aggregate = {
  __typename?: 'deliveryStatus_aggregate';
  aggregate?: Maybe<DeliveryStatus_Aggregate_Fields>;
  nodes: Array<DeliveryStatus>;
};

/** aggregate fields of "delivery_status" */
export type DeliveryStatus_Aggregate_Fields = {
  __typename?: 'deliveryStatus_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DeliveryStatus_Max_Fields>;
  min?: Maybe<DeliveryStatus_Min_Fields>;
};


/** aggregate fields of "delivery_status" */
export type DeliveryStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DeliveryStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "delivery_status". All fields are combined with a logical 'AND'. */
export type DeliveryStatus_Bool_Exp = {
  _and?: InputMaybe<Array<DeliveryStatus_Bool_Exp>>;
  _not?: InputMaybe<DeliveryStatus_Bool_Exp>;
  _or?: InputMaybe<Array<DeliveryStatus_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "delivery_status" */
export enum DeliveryStatus_Constraint {
  /** unique or primary key constraint on columns "value" */
  DeliveryStatusPkey = 'DeliveryStatus_pkey'
}

export enum DeliveryStatus_Enum {
  Confirmed = 'CONFIRMED',
  Rejected = 'REJECTED',
  Unconfirmed = 'UNCONFIRMED'
}

/** Boolean expression to compare columns of type "deliveryStatus_enum". All fields are combined with logical 'AND'. */
export type DeliveryStatus_Enum_Comparison_Exp = {
  _eq?: InputMaybe<DeliveryStatus_Enum>;
  _in?: InputMaybe<Array<DeliveryStatus_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<DeliveryStatus_Enum>;
  _nin?: InputMaybe<Array<DeliveryStatus_Enum>>;
};

/** input type for inserting data into table "delivery_status" */
export type DeliveryStatus_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type DeliveryStatus_Max_Fields = {
  __typename?: 'deliveryStatus_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DeliveryStatus_Min_Fields = {
  __typename?: 'deliveryStatus_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "delivery_status" */
export type DeliveryStatus_Mutation_Response = {
  __typename?: 'deliveryStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DeliveryStatus>;
};

/** on_conflict condition type for table "delivery_status" */
export type DeliveryStatus_On_Conflict = {
  constraint: DeliveryStatus_Constraint;
  update_columns?: Array<DeliveryStatus_Update_Column>;
  where?: InputMaybe<DeliveryStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "delivery_status". */
export type DeliveryStatus_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: delivery_status */
export type DeliveryStatus_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "delivery_status" */
export enum DeliveryStatus_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "delivery_status" */
export type DeliveryStatus_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "deliveryStatus" */
export type DeliveryStatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DeliveryStatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DeliveryStatus_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "delivery_status" */
export enum DeliveryStatus_Update_Column {
  /** column name */
  Value = 'value'
}

export type DeliveryStatus_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DeliveryStatus_Set_Input>;
  where: DeliveryStatus_Bool_Exp;
};

/** columns and relationships of "events" */
export type Events = {
  __typename?: 'events';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  ride: Rides;
  ride_id: Scalars['uuid']['output'];
  type: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "events" */
export type Events_Aggregate = {
  __typename?: 'events_aggregate';
  aggregate?: Maybe<Events_Aggregate_Fields>;
  nodes: Array<Events>;
};

export type Events_Aggregate_Bool_Exp = {
  count?: InputMaybe<Events_Aggregate_Bool_Exp_Count>;
};

export type Events_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Events_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "events" */
export type Events_Aggregate_Fields = {
  __typename?: 'events_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Events_Max_Fields>;
  min?: Maybe<Events_Min_Fields>;
};


/** aggregate fields of "events" */
export type Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "events" */
export type Events_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Events_Max_Order_By>;
  min?: InputMaybe<Events_Min_Order_By>;
};

/** input type for inserting array relation for remote table "events" */
export type Events_Arr_Rel_Insert_Input = {
  data: Array<Events_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Events_On_Conflict>;
};

/** Boolean expression to filter rows from the table "events". All fields are combined with a logical 'AND'. */
export type Events_Bool_Exp = {
  _and?: InputMaybe<Array<Events_Bool_Exp>>;
  _not?: InputMaybe<Events_Bool_Exp>;
  _or?: InputMaybe<Array<Events_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ride?: InputMaybe<Rides_Bool_Exp>;
  ride_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "events" */
export enum Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  EventsPkey = 'events_pkey'
}

/** input type for inserting data into table "events" */
export type Events_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ride?: InputMaybe<Rides_Obj_Rel_Insert_Input>;
  ride_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Events_Max_Fields = {
  __typename?: 'events_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ride_id?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "events" */
export type Events_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ride_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Events_Min_Fields = {
  __typename?: 'events_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ride_id?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "events" */
export type Events_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ride_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "events" */
export type Events_Mutation_Response = {
  __typename?: 'events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Events>;
};

/** on_conflict condition type for table "events" */
export type Events_On_Conflict = {
  constraint: Events_Constraint;
  update_columns?: Array<Events_Update_Column>;
  where?: InputMaybe<Events_Bool_Exp>;
};

/** Ordering options when selecting data from "events". */
export type Events_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ride?: InputMaybe<Rides_Order_By>;
  ride_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: events */
export type Events_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "events" */
export enum Events_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  RideId = 'ride_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "events" */
export type Events_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ride_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "events" */
export type Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Events_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ride_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "events" */
export enum Events_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  RideId = 'ride_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Events_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Events_Set_Input>;
  where: Events_Bool_Exp;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "carriers" */
  delete_carriers?: Maybe<Carriers_Mutation_Response>;
  /** delete single row from the table: "carriers" */
  delete_carriers_by_pk?: Maybe<Carriers>;
  /** delete data from the table: "client_status" */
  delete_clientStatus?: Maybe<ClientStatus_Mutation_Response>;
  /** delete single row from the table: "client_status" */
  delete_clientStatus_by_pk?: Maybe<ClientStatus>;
  /** delete data from the table: "clients" */
  delete_clients?: Maybe<Clients_Mutation_Response>;
  /** delete single row from the table: "clients" */
  delete_clients_by_pk?: Maybe<Clients>;
  /** delete data from the table: "consignees" */
  delete_consignees?: Maybe<Consignees_Mutation_Response>;
  /** delete single row from the table: "consignees" */
  delete_consignees_by_pk?: Maybe<Consignees>;
  /** delete data from the table: "delays" */
  delete_delays?: Maybe<Delays_Mutation_Response>;
  /** delete single row from the table: "delays" */
  delete_delays_by_pk?: Maybe<Delays>;
  /** delete data from the table: "deliveries" */
  delete_deliveries?: Maybe<Deliveries_Mutation_Response>;
  /** delete single row from the table: "deliveries" */
  delete_deliveries_by_pk?: Maybe<Deliveries>;
  /** delete data from the table: "delivery_guest_accesses" */
  delete_deliveryGuestAccesses?: Maybe<DeliveryGuestAccesses_Mutation_Response>;
  /** delete single row from the table: "delivery_guest_accesses" */
  delete_deliveryGuestAccesses_by_pk?: Maybe<DeliveryGuestAccesses>;
  /** delete data from the table: "delivery_progress" */
  delete_deliveryProgress?: Maybe<DeliveryProgress_Mutation_Response>;
  /** delete single row from the table: "delivery_progress" */
  delete_deliveryProgress_by_pk?: Maybe<DeliveryProgress>;
  /** delete data from the table: "delivery_status" */
  delete_deliveryStatus?: Maybe<DeliveryStatus_Mutation_Response>;
  /** delete single row from the table: "delivery_status" */
  delete_deliveryStatus_by_pk?: Maybe<DeliveryStatus>;
  /** delete data from the table: "events" */
  delete_events?: Maybe<Events_Mutation_Response>;
  /** delete single row from the table: "events" */
  delete_events_by_pk?: Maybe<Events>;
  /** delete data from the table: "product_types" */
  delete_productTypes?: Maybe<ProductTypes_Mutation_Response>;
  /** delete single row from the table: "product_types" */
  delete_productTypes_by_pk?: Maybe<ProductTypes>;
  /** delete data from the table: "products" */
  delete_products?: Maybe<Products_Mutation_Response>;
  /** delete single row from the table: "products" */
  delete_products_by_pk?: Maybe<Products>;
  /** delete data from the table: "ride_status" */
  delete_rideStatus?: Maybe<RideStatus_Mutation_Response>;
  /** delete single row from the table: "ride_status" */
  delete_rideStatus_by_pk?: Maybe<RideStatus>;
  /** delete data from the table: "rides" */
  delete_rides?: Maybe<Rides_Mutation_Response>;
  /** delete single row from the table: "rides" */
  delete_rides_by_pk?: Maybe<Rides>;
  /** delete data from the table: "trucks" */
  delete_trucks?: Maybe<Trucks_Mutation_Response>;
  /** delete single row from the table: "trucks" */
  delete_trucks_by_pk?: Maybe<Trucks>;
  /** delete data from the table: "users_carriers" */
  delete_users_carriers?: Maybe<Users_Carriers_Mutation_Response>;
  /** delete single row from the table: "users_carriers" */
  delete_users_carriers_by_pk?: Maybe<Users_Carriers>;
  /** delete data from the table: "users_consignees" */
  delete_users_consignees?: Maybe<Users_Consignees_Mutation_Response>;
  /** delete single row from the table: "users_consignees" */
  delete_users_consignees_by_pk?: Maybe<Users_Consignees>;
  /** delete data from the table: "users_vendors" */
  delete_users_vendors?: Maybe<Users_Vendors_Mutation_Response>;
  /** delete single row from the table: "users_vendors" */
  delete_users_vendors_by_pk?: Maybe<Users_Vendors>;
  /** delete data from the table: "vendors" */
  delete_vendors?: Maybe<Vendors_Mutation_Response>;
  /** delete single row from the table: "vendors" */
  delete_vendors_by_pk?: Maybe<Vendors>;
  /** insert data into the table: "carriers" */
  insert_carriers?: Maybe<Carriers_Mutation_Response>;
  /** insert a single row into the table: "carriers" */
  insert_carriers_one?: Maybe<Carriers>;
  /** insert data into the table: "client_status" */
  insert_clientStatus?: Maybe<ClientStatus_Mutation_Response>;
  /** insert a single row into the table: "client_status" */
  insert_clientStatus_one?: Maybe<ClientStatus>;
  /** insert data into the table: "clients" */
  insert_clients?: Maybe<Clients_Mutation_Response>;
  /** insert a single row into the table: "clients" */
  insert_clients_one?: Maybe<Clients>;
  /** insert data into the table: "consignees" */
  insert_consignees?: Maybe<Consignees_Mutation_Response>;
  /** insert a single row into the table: "consignees" */
  insert_consignees_one?: Maybe<Consignees>;
  /** insert data into the table: "delays" */
  insert_delays?: Maybe<Delays_Mutation_Response>;
  /** insert a single row into the table: "delays" */
  insert_delays_one?: Maybe<Delays>;
  /** insert data into the table: "deliveries" */
  insert_deliveries?: Maybe<Deliveries_Mutation_Response>;
  /** insert a single row into the table: "deliveries" */
  insert_deliveries_one?: Maybe<Deliveries>;
  /** insert data into the table: "delivery_guest_accesses" */
  insert_deliveryGuestAccesses?: Maybe<DeliveryGuestAccesses_Mutation_Response>;
  /** insert a single row into the table: "delivery_guest_accesses" */
  insert_deliveryGuestAccesses_one?: Maybe<DeliveryGuestAccesses>;
  /** insert data into the table: "delivery_progress" */
  insert_deliveryProgress?: Maybe<DeliveryProgress_Mutation_Response>;
  /** insert a single row into the table: "delivery_progress" */
  insert_deliveryProgress_one?: Maybe<DeliveryProgress>;
  /** insert data into the table: "delivery_status" */
  insert_deliveryStatus?: Maybe<DeliveryStatus_Mutation_Response>;
  /** insert a single row into the table: "delivery_status" */
  insert_deliveryStatus_one?: Maybe<DeliveryStatus>;
  /** insert data into the table: "events" */
  insert_events?: Maybe<Events_Mutation_Response>;
  /** insert a single row into the table: "events" */
  insert_events_one?: Maybe<Events>;
  /** insert data into the table: "product_types" */
  insert_productTypes?: Maybe<ProductTypes_Mutation_Response>;
  /** insert a single row into the table: "product_types" */
  insert_productTypes_one?: Maybe<ProductTypes>;
  /** insert data into the table: "products" */
  insert_products?: Maybe<Products_Mutation_Response>;
  /** insert a single row into the table: "products" */
  insert_products_one?: Maybe<Products>;
  /** insert data into the table: "ride_status" */
  insert_rideStatus?: Maybe<RideStatus_Mutation_Response>;
  /** insert a single row into the table: "ride_status" */
  insert_rideStatus_one?: Maybe<RideStatus>;
  /** insert data into the table: "rides" */
  insert_rides?: Maybe<Rides_Mutation_Response>;
  /** insert a single row into the table: "rides" */
  insert_rides_one?: Maybe<Rides>;
  /** insert data into the table: "trucks" */
  insert_trucks?: Maybe<Trucks_Mutation_Response>;
  /** insert a single row into the table: "trucks" */
  insert_trucks_one?: Maybe<Trucks>;
  /** insert data into the table: "users_carriers" */
  insert_users_carriers?: Maybe<Users_Carriers_Mutation_Response>;
  /** insert a single row into the table: "users_carriers" */
  insert_users_carriers_one?: Maybe<Users_Carriers>;
  /** insert data into the table: "users_consignees" */
  insert_users_consignees?: Maybe<Users_Consignees_Mutation_Response>;
  /** insert a single row into the table: "users_consignees" */
  insert_users_consignees_one?: Maybe<Users_Consignees>;
  /** insert data into the table: "users_vendors" */
  insert_users_vendors?: Maybe<Users_Vendors_Mutation_Response>;
  /** insert a single row into the table: "users_vendors" */
  insert_users_vendors_one?: Maybe<Users_Vendors>;
  /** insert data into the table: "vendors" */
  insert_vendors?: Maybe<Vendors_Mutation_Response>;
  /** insert a single row into the table: "vendors" */
  insert_vendors_one?: Maybe<Vendors>;
  /** update data of the table: "carriers" */
  update_carriers?: Maybe<Carriers_Mutation_Response>;
  /** update single row of the table: "carriers" */
  update_carriers_by_pk?: Maybe<Carriers>;
  /** update multiples rows of table: "carriers" */
  update_carriers_many?: Maybe<Array<Maybe<Carriers_Mutation_Response>>>;
  /** update data of the table: "client_status" */
  update_clientStatus?: Maybe<ClientStatus_Mutation_Response>;
  /** update single row of the table: "client_status" */
  update_clientStatus_by_pk?: Maybe<ClientStatus>;
  /** update multiples rows of table: "client_status" */
  update_clientStatus_many?: Maybe<Array<Maybe<ClientStatus_Mutation_Response>>>;
  /** update data of the table: "clients" */
  update_clients?: Maybe<Clients_Mutation_Response>;
  /** update single row of the table: "clients" */
  update_clients_by_pk?: Maybe<Clients>;
  /** update multiples rows of table: "clients" */
  update_clients_many?: Maybe<Array<Maybe<Clients_Mutation_Response>>>;
  /** update data of the table: "consignees" */
  update_consignees?: Maybe<Consignees_Mutation_Response>;
  /** update single row of the table: "consignees" */
  update_consignees_by_pk?: Maybe<Consignees>;
  /** update multiples rows of table: "consignees" */
  update_consignees_many?: Maybe<Array<Maybe<Consignees_Mutation_Response>>>;
  /** update data of the table: "delays" */
  update_delays?: Maybe<Delays_Mutation_Response>;
  /** update single row of the table: "delays" */
  update_delays_by_pk?: Maybe<Delays>;
  /** update multiples rows of table: "delays" */
  update_delays_many?: Maybe<Array<Maybe<Delays_Mutation_Response>>>;
  /** update data of the table: "deliveries" */
  update_deliveries?: Maybe<Deliveries_Mutation_Response>;
  /** update single row of the table: "deliveries" */
  update_deliveries_by_pk?: Maybe<Deliveries>;
  /** update multiples rows of table: "deliveries" */
  update_deliveries_many?: Maybe<Array<Maybe<Deliveries_Mutation_Response>>>;
  /** update data of the table: "delivery_guest_accesses" */
  update_deliveryGuestAccesses?: Maybe<DeliveryGuestAccesses_Mutation_Response>;
  /** update single row of the table: "delivery_guest_accesses" */
  update_deliveryGuestAccesses_by_pk?: Maybe<DeliveryGuestAccesses>;
  /** update multiples rows of table: "delivery_guest_accesses" */
  update_deliveryGuestAccesses_many?: Maybe<Array<Maybe<DeliveryGuestAccesses_Mutation_Response>>>;
  /** update data of the table: "delivery_progress" */
  update_deliveryProgress?: Maybe<DeliveryProgress_Mutation_Response>;
  /** update single row of the table: "delivery_progress" */
  update_deliveryProgress_by_pk?: Maybe<DeliveryProgress>;
  /** update multiples rows of table: "delivery_progress" */
  update_deliveryProgress_many?: Maybe<Array<Maybe<DeliveryProgress_Mutation_Response>>>;
  /** update data of the table: "delivery_status" */
  update_deliveryStatus?: Maybe<DeliveryStatus_Mutation_Response>;
  /** update single row of the table: "delivery_status" */
  update_deliveryStatus_by_pk?: Maybe<DeliveryStatus>;
  /** update multiples rows of table: "delivery_status" */
  update_deliveryStatus_many?: Maybe<Array<Maybe<DeliveryStatus_Mutation_Response>>>;
  /** update data of the table: "events" */
  update_events?: Maybe<Events_Mutation_Response>;
  /** update single row of the table: "events" */
  update_events_by_pk?: Maybe<Events>;
  /** update multiples rows of table: "events" */
  update_events_many?: Maybe<Array<Maybe<Events_Mutation_Response>>>;
  /** update data of the table: "product_types" */
  update_productTypes?: Maybe<ProductTypes_Mutation_Response>;
  /** update single row of the table: "product_types" */
  update_productTypes_by_pk?: Maybe<ProductTypes>;
  /** update multiples rows of table: "product_types" */
  update_productTypes_many?: Maybe<Array<Maybe<ProductTypes_Mutation_Response>>>;
  /** update data of the table: "products" */
  update_products?: Maybe<Products_Mutation_Response>;
  /** update single row of the table: "products" */
  update_products_by_pk?: Maybe<Products>;
  /** update multiples rows of table: "products" */
  update_products_many?: Maybe<Array<Maybe<Products_Mutation_Response>>>;
  /** update data of the table: "ride_status" */
  update_rideStatus?: Maybe<RideStatus_Mutation_Response>;
  /** update single row of the table: "ride_status" */
  update_rideStatus_by_pk?: Maybe<RideStatus>;
  /** update multiples rows of table: "ride_status" */
  update_rideStatus_many?: Maybe<Array<Maybe<RideStatus_Mutation_Response>>>;
  /** update data of the table: "rides" */
  update_rides?: Maybe<Rides_Mutation_Response>;
  /** update single row of the table: "rides" */
  update_rides_by_pk?: Maybe<Rides>;
  /** update multiples rows of table: "rides" */
  update_rides_many?: Maybe<Array<Maybe<Rides_Mutation_Response>>>;
  /** update data of the table: "trucks" */
  update_trucks?: Maybe<Trucks_Mutation_Response>;
  /** update single row of the table: "trucks" */
  update_trucks_by_pk?: Maybe<Trucks>;
  /** update multiples rows of table: "trucks" */
  update_trucks_many?: Maybe<Array<Maybe<Trucks_Mutation_Response>>>;
  /** update data of the table: "users_carriers" */
  update_users_carriers?: Maybe<Users_Carriers_Mutation_Response>;
  /** update single row of the table: "users_carriers" */
  update_users_carriers_by_pk?: Maybe<Users_Carriers>;
  /** update multiples rows of table: "users_carriers" */
  update_users_carriers_many?: Maybe<Array<Maybe<Users_Carriers_Mutation_Response>>>;
  /** update data of the table: "users_consignees" */
  update_users_consignees?: Maybe<Users_Consignees_Mutation_Response>;
  /** update single row of the table: "users_consignees" */
  update_users_consignees_by_pk?: Maybe<Users_Consignees>;
  /** update multiples rows of table: "users_consignees" */
  update_users_consignees_many?: Maybe<Array<Maybe<Users_Consignees_Mutation_Response>>>;
  /** update data of the table: "users_vendors" */
  update_users_vendors?: Maybe<Users_Vendors_Mutation_Response>;
  /** update single row of the table: "users_vendors" */
  update_users_vendors_by_pk?: Maybe<Users_Vendors>;
  /** update multiples rows of table: "users_vendors" */
  update_users_vendors_many?: Maybe<Array<Maybe<Users_Vendors_Mutation_Response>>>;
  /** update data of the table: "vendors" */
  update_vendors?: Maybe<Vendors_Mutation_Response>;
  /** update single row of the table: "vendors" */
  update_vendors_by_pk?: Maybe<Vendors>;
  /** update multiples rows of table: "vendors" */
  update_vendors_many?: Maybe<Array<Maybe<Vendors_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_CarriersArgs = {
  where: Carriers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Carriers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ClientStatusArgs = {
  where: ClientStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ClientStatus_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ClientsArgs = {
  where: Clients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Clients_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ConsigneesArgs = {
  where: Consignees_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Consignees_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_DelaysArgs = {
  where: Delays_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Delays_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_DeliveriesArgs = {
  where: Deliveries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deliveries_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_DeliveryGuestAccessesArgs = {
  where: DeliveryGuestAccesses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DeliveryGuestAccesses_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_DeliveryProgressArgs = {
  where: DeliveryProgress_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DeliveryProgress_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_DeliveryStatusArgs = {
  where: DeliveryStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DeliveryStatus_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_EventsArgs = {
  where: Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Events_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ProductTypesArgs = {
  where: ProductTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProductTypes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ProductsArgs = {
  where: Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Products_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_RideStatusArgs = {
  where: RideStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RideStatus_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_RidesArgs = {
  where: Rides_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rides_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TrucksArgs = {
  where: Trucks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Trucks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_CarriersArgs = {
  where: Users_Carriers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Carriers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_ConsigneesArgs = {
  where: Users_Consignees_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Consignees_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_VendorsArgs = {
  where: Users_Vendors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Vendors_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_VendorsArgs = {
  where: Vendors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vendors_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootInsert_CarriersArgs = {
  objects: Array<Carriers_Insert_Input>;
  on_conflict?: InputMaybe<Carriers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Carriers_OneArgs = {
  object: Carriers_Insert_Input;
  on_conflict?: InputMaybe<Carriers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClientStatusArgs = {
  objects: Array<ClientStatus_Insert_Input>;
  on_conflict?: InputMaybe<ClientStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClientStatus_OneArgs = {
  object: ClientStatus_Insert_Input;
  on_conflict?: InputMaybe<ClientStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClientsArgs = {
  objects: Array<Clients_Insert_Input>;
  on_conflict?: InputMaybe<Clients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Clients_OneArgs = {
  object: Clients_Insert_Input;
  on_conflict?: InputMaybe<Clients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ConsigneesArgs = {
  objects: Array<Consignees_Insert_Input>;
  on_conflict?: InputMaybe<Consignees_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Consignees_OneArgs = {
  object: Consignees_Insert_Input;
  on_conflict?: InputMaybe<Consignees_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DelaysArgs = {
  objects: Array<Delays_Insert_Input>;
  on_conflict?: InputMaybe<Delays_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Delays_OneArgs = {
  object: Delays_Insert_Input;
  on_conflict?: InputMaybe<Delays_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DeliveriesArgs = {
  objects: Array<Deliveries_Insert_Input>;
  on_conflict?: InputMaybe<Deliveries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deliveries_OneArgs = {
  object: Deliveries_Insert_Input;
  on_conflict?: InputMaybe<Deliveries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DeliveryGuestAccessesArgs = {
  objects: Array<DeliveryGuestAccesses_Insert_Input>;
  on_conflict?: InputMaybe<DeliveryGuestAccesses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DeliveryGuestAccesses_OneArgs = {
  object: DeliveryGuestAccesses_Insert_Input;
  on_conflict?: InputMaybe<DeliveryGuestAccesses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DeliveryProgressArgs = {
  objects: Array<DeliveryProgress_Insert_Input>;
  on_conflict?: InputMaybe<DeliveryProgress_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DeliveryProgress_OneArgs = {
  object: DeliveryProgress_Insert_Input;
  on_conflict?: InputMaybe<DeliveryProgress_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DeliveryStatusArgs = {
  objects: Array<DeliveryStatus_Insert_Input>;
  on_conflict?: InputMaybe<DeliveryStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DeliveryStatus_OneArgs = {
  object: DeliveryStatus_Insert_Input;
  on_conflict?: InputMaybe<DeliveryStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventsArgs = {
  objects: Array<Events_Insert_Input>;
  on_conflict?: InputMaybe<Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Events_OneArgs = {
  object: Events_Insert_Input;
  on_conflict?: InputMaybe<Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductTypesArgs = {
  objects: Array<ProductTypes_Insert_Input>;
  on_conflict?: InputMaybe<ProductTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductTypes_OneArgs = {
  object: ProductTypes_Insert_Input;
  on_conflict?: InputMaybe<ProductTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductsArgs = {
  objects: Array<Products_Insert_Input>;
  on_conflict?: InputMaybe<Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Products_OneArgs = {
  object: Products_Insert_Input;
  on_conflict?: InputMaybe<Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RideStatusArgs = {
  objects: Array<RideStatus_Insert_Input>;
  on_conflict?: InputMaybe<RideStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RideStatus_OneArgs = {
  object: RideStatus_Insert_Input;
  on_conflict?: InputMaybe<RideStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RidesArgs = {
  objects: Array<Rides_Insert_Input>;
  on_conflict?: InputMaybe<Rides_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rides_OneArgs = {
  object: Rides_Insert_Input;
  on_conflict?: InputMaybe<Rides_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TrucksArgs = {
  objects: Array<Trucks_Insert_Input>;
  on_conflict?: InputMaybe<Trucks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trucks_OneArgs = {
  object: Trucks_Insert_Input;
  on_conflict?: InputMaybe<Trucks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_CarriersArgs = {
  objects: Array<Users_Carriers_Insert_Input>;
  on_conflict?: InputMaybe<Users_Carriers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Carriers_OneArgs = {
  object: Users_Carriers_Insert_Input;
  on_conflict?: InputMaybe<Users_Carriers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_ConsigneesArgs = {
  objects: Array<Users_Consignees_Insert_Input>;
  on_conflict?: InputMaybe<Users_Consignees_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Consignees_OneArgs = {
  object: Users_Consignees_Insert_Input;
  on_conflict?: InputMaybe<Users_Consignees_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_VendorsArgs = {
  objects: Array<Users_Vendors_Insert_Input>;
  on_conflict?: InputMaybe<Users_Vendors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Vendors_OneArgs = {
  object: Users_Vendors_Insert_Input;
  on_conflict?: InputMaybe<Users_Vendors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VendorsArgs = {
  objects: Array<Vendors_Insert_Input>;
  on_conflict?: InputMaybe<Vendors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vendors_OneArgs = {
  object: Vendors_Insert_Input;
  on_conflict?: InputMaybe<Vendors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_CarriersArgs = {
  _set?: InputMaybe<Carriers_Set_Input>;
  where: Carriers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Carriers_By_PkArgs = {
  _set?: InputMaybe<Carriers_Set_Input>;
  pk_columns: Carriers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Carriers_ManyArgs = {
  updates: Array<Carriers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ClientStatusArgs = {
  _set?: InputMaybe<ClientStatus_Set_Input>;
  where: ClientStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ClientStatus_By_PkArgs = {
  _set?: InputMaybe<ClientStatus_Set_Input>;
  pk_columns: ClientStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ClientStatus_ManyArgs = {
  updates: Array<ClientStatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ClientsArgs = {
  _set?: InputMaybe<Clients_Set_Input>;
  where: Clients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Clients_By_PkArgs = {
  _set?: InputMaybe<Clients_Set_Input>;
  pk_columns: Clients_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Clients_ManyArgs = {
  updates: Array<Clients_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ConsigneesArgs = {
  _set?: InputMaybe<Consignees_Set_Input>;
  where: Consignees_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Consignees_By_PkArgs = {
  _set?: InputMaybe<Consignees_Set_Input>;
  pk_columns: Consignees_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Consignees_ManyArgs = {
  updates: Array<Consignees_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DelaysArgs = {
  _inc?: InputMaybe<Delays_Inc_Input>;
  _set?: InputMaybe<Delays_Set_Input>;
  where: Delays_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Delays_By_PkArgs = {
  _inc?: InputMaybe<Delays_Inc_Input>;
  _set?: InputMaybe<Delays_Set_Input>;
  pk_columns: Delays_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Delays_ManyArgs = {
  updates: Array<Delays_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DeliveriesArgs = {
  _set?: InputMaybe<Deliveries_Set_Input>;
  where: Deliveries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_By_PkArgs = {
  _set?: InputMaybe<Deliveries_Set_Input>;
  pk_columns: Deliveries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deliveries_ManyArgs = {
  updates: Array<Deliveries_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DeliveryGuestAccessesArgs = {
  _set?: InputMaybe<DeliveryGuestAccesses_Set_Input>;
  where: DeliveryGuestAccesses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_DeliveryGuestAccesses_By_PkArgs = {
  _set?: InputMaybe<DeliveryGuestAccesses_Set_Input>;
  pk_columns: DeliveryGuestAccesses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DeliveryGuestAccesses_ManyArgs = {
  updates: Array<DeliveryGuestAccesses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DeliveryProgressArgs = {
  _set?: InputMaybe<DeliveryProgress_Set_Input>;
  where: DeliveryProgress_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_DeliveryProgress_By_PkArgs = {
  _set?: InputMaybe<DeliveryProgress_Set_Input>;
  pk_columns: DeliveryProgress_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DeliveryProgress_ManyArgs = {
  updates: Array<DeliveryProgress_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DeliveryStatusArgs = {
  _set?: InputMaybe<DeliveryStatus_Set_Input>;
  where: DeliveryStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_DeliveryStatus_By_PkArgs = {
  _set?: InputMaybe<DeliveryStatus_Set_Input>;
  pk_columns: DeliveryStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DeliveryStatus_ManyArgs = {
  updates: Array<DeliveryStatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EventsArgs = {
  _set?: InputMaybe<Events_Set_Input>;
  where: Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Events_By_PkArgs = {
  _set?: InputMaybe<Events_Set_Input>;
  pk_columns: Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Events_ManyArgs = {
  updates: Array<Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProductTypesArgs = {
  _append?: InputMaybe<ProductTypes_Append_Input>;
  _delete_at_path?: InputMaybe<ProductTypes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ProductTypes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ProductTypes_Delete_Key_Input>;
  _prepend?: InputMaybe<ProductTypes_Prepend_Input>;
  _set?: InputMaybe<ProductTypes_Set_Input>;
  where: ProductTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProductTypes_By_PkArgs = {
  _append?: InputMaybe<ProductTypes_Append_Input>;
  _delete_at_path?: InputMaybe<ProductTypes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ProductTypes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ProductTypes_Delete_Key_Input>;
  _prepend?: InputMaybe<ProductTypes_Prepend_Input>;
  _set?: InputMaybe<ProductTypes_Set_Input>;
  pk_columns: ProductTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProductTypes_ManyArgs = {
  updates: Array<ProductTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProductsArgs = {
  _inc?: InputMaybe<Products_Inc_Input>;
  _set?: InputMaybe<Products_Set_Input>;
  where: Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Products_By_PkArgs = {
  _inc?: InputMaybe<Products_Inc_Input>;
  _set?: InputMaybe<Products_Set_Input>;
  pk_columns: Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Products_ManyArgs = {
  updates: Array<Products_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RideStatusArgs = {
  _set?: InputMaybe<RideStatus_Set_Input>;
  where: RideStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RideStatus_By_PkArgs = {
  _set?: InputMaybe<RideStatus_Set_Input>;
  pk_columns: RideStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RideStatus_ManyArgs = {
  updates: Array<RideStatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RidesArgs = {
  _set?: InputMaybe<Rides_Set_Input>;
  where: Rides_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rides_By_PkArgs = {
  _set?: InputMaybe<Rides_Set_Input>;
  pk_columns: Rides_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rides_ManyArgs = {
  updates: Array<Rides_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TrucksArgs = {
  _set?: InputMaybe<Trucks_Set_Input>;
  where: Trucks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Trucks_By_PkArgs = {
  _set?: InputMaybe<Trucks_Set_Input>;
  pk_columns: Trucks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Trucks_ManyArgs = {
  updates: Array<Trucks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_CarriersArgs = {
  _set?: InputMaybe<Users_Carriers_Set_Input>;
  where: Users_Carriers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Carriers_By_PkArgs = {
  _set?: InputMaybe<Users_Carriers_Set_Input>;
  pk_columns: Users_Carriers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Carriers_ManyArgs = {
  updates: Array<Users_Carriers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ConsigneesArgs = {
  _set?: InputMaybe<Users_Consignees_Set_Input>;
  where: Users_Consignees_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Consignees_By_PkArgs = {
  _set?: InputMaybe<Users_Consignees_Set_Input>;
  pk_columns: Users_Consignees_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Consignees_ManyArgs = {
  updates: Array<Users_Consignees_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_VendorsArgs = {
  _set?: InputMaybe<Users_Vendors_Set_Input>;
  where: Users_Vendors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Vendors_By_PkArgs = {
  _set?: InputMaybe<Users_Vendors_Set_Input>;
  pk_columns: Users_Vendors_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Vendors_ManyArgs = {
  updates: Array<Users_Vendors_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VendorsArgs = {
  _append?: InputMaybe<Vendors_Append_Input>;
  _delete_at_path?: InputMaybe<Vendors_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Vendors_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Vendors_Delete_Key_Input>;
  _prepend?: InputMaybe<Vendors_Prepend_Input>;
  _set?: InputMaybe<Vendors_Set_Input>;
  where: Vendors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vendors_By_PkArgs = {
  _append?: InputMaybe<Vendors_Append_Input>;
  _delete_at_path?: InputMaybe<Vendors_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Vendors_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Vendors_Delete_Key_Input>;
  _prepend?: InputMaybe<Vendors_Prepend_Input>;
  _set?: InputMaybe<Vendors_Set_Input>;
  pk_columns: Vendors_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vendors_ManyArgs = {
  updates: Array<Vendors_Updates>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** Boolean expression to compare columns of type "point". All fields are combined with logical 'AND'. */
export type Point_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['point']['input']>;
  _gt?: InputMaybe<Scalars['point']['input']>;
  _gte?: InputMaybe<Scalars['point']['input']>;
  _in?: InputMaybe<Array<Scalars['point']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['point']['input']>;
  _lte?: InputMaybe<Scalars['point']['input']>;
  _neq?: InputMaybe<Scalars['point']['input']>;
  _nin?: InputMaybe<Array<Scalars['point']['input']>>;
};

/** columns and relationships of "product_types" */
export type ProductTypes = {
  __typename?: 'productTypes';
  additional?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  client?: Maybe<Clients>;
  client_id?: Maybe<Scalars['uuid']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "product_types" */
export type ProductTypesAdditionalArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "product_types" */
export type ProductTypes_Aggregate = {
  __typename?: 'productTypes_aggregate';
  aggregate?: Maybe<ProductTypes_Aggregate_Fields>;
  nodes: Array<ProductTypes>;
};

/** aggregate fields of "product_types" */
export type ProductTypes_Aggregate_Fields = {
  __typename?: 'productTypes_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ProductTypes_Max_Fields>;
  min?: Maybe<ProductTypes_Min_Fields>;
};


/** aggregate fields of "product_types" */
export type ProductTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ProductTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ProductTypes_Append_Input = {
  additional?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "product_types". All fields are combined with a logical 'AND'. */
export type ProductTypes_Bool_Exp = {
  _and?: InputMaybe<Array<ProductTypes_Bool_Exp>>;
  _not?: InputMaybe<ProductTypes_Bool_Exp>;
  _or?: InputMaybe<Array<ProductTypes_Bool_Exp>>;
  additional?: InputMaybe<Jsonb_Comparison_Exp>;
  client?: InputMaybe<Clients_Bool_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_types" */
export enum ProductTypes_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductTypePkey = 'product_type_pkey',
  /** unique or primary key constraint on columns "name", "additional" */
  ProductTypesNameAdditionalKey = 'product_types_name_additional_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ProductTypes_Delete_At_Path_Input = {
  additional?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ProductTypes_Delete_Elem_Input = {
  additional?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ProductTypes_Delete_Key_Input = {
  additional?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "product_types" */
export type ProductTypes_Insert_Input = {
  additional?: InputMaybe<Scalars['jsonb']['input']>;
  client?: InputMaybe<Clients_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type ProductTypes_Max_Fields = {
  __typename?: 'productTypes_max_fields';
  client_id?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type ProductTypes_Min_Fields = {
  __typename?: 'productTypes_min_fields';
  client_id?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "product_types" */
export type ProductTypes_Mutation_Response = {
  __typename?: 'productTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ProductTypes>;
};

/** input type for inserting object relation for remote table "product_types" */
export type ProductTypes_Obj_Rel_Insert_Input = {
  data: ProductTypes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ProductTypes_On_Conflict>;
};

/** on_conflict condition type for table "product_types" */
export type ProductTypes_On_Conflict = {
  constraint: ProductTypes_Constraint;
  update_columns?: Array<ProductTypes_Update_Column>;
  where?: InputMaybe<ProductTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "product_types". */
export type ProductTypes_Order_By = {
  additional?: InputMaybe<Order_By>;
  client?: InputMaybe<Clients_Order_By>;
  client_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_types */
export type ProductTypes_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ProductTypes_Prepend_Input = {
  additional?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "product_types" */
export enum ProductTypes_Select_Column {
  /** column name */
  Additional = 'additional',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "product_types" */
export type ProductTypes_Set_Input = {
  additional?: InputMaybe<Scalars['jsonb']['input']>;
  client_id?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "productTypes" */
export type ProductTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProductTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductTypes_Stream_Cursor_Value_Input = {
  additional?: InputMaybe<Scalars['jsonb']['input']>;
  client_id?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "product_types" */
export enum ProductTypes_Update_Column {
  /** column name */
  Additional = 'additional',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ProductTypes_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ProductTypes_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ProductTypes_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ProductTypes_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ProductTypes_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ProductTypes_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProductTypes_Set_Input>;
  where: ProductTypes_Bool_Exp;
};

/** columns and relationships of "products" */
export type Products = {
  __typename?: 'products';
  createdAt: Scalars['timestamptz']['output'];
  deliveredAmount?: Maybe<Scalars['Int']['output']>;
  expectedAmount: Scalars['Int']['output'];
  id: Scalars['uuid']['output'];
  loadedAmount?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  productType: ProductTypes;
  productTypeId: Scalars['uuid']['output'];
  /** An object relationship */
  ride: Rides;
  rideId: Scalars['uuid']['output'];
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "products" */
export type Products_Aggregate = {
  __typename?: 'products_aggregate';
  aggregate?: Maybe<Products_Aggregate_Fields>;
  nodes: Array<Products>;
};

export type Products_Aggregate_Bool_Exp = {
  count?: InputMaybe<Products_Aggregate_Bool_Exp_Count>;
};

export type Products_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Products_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "products" */
export type Products_Aggregate_Fields = {
  __typename?: 'products_aggregate_fields';
  avg?: Maybe<Products_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Products_Max_Fields>;
  min?: Maybe<Products_Min_Fields>;
  stddev?: Maybe<Products_Stddev_Fields>;
  stddev_pop?: Maybe<Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Products_Stddev_Samp_Fields>;
  sum?: Maybe<Products_Sum_Fields>;
  var_pop?: Maybe<Products_Var_Pop_Fields>;
  var_samp?: Maybe<Products_Var_Samp_Fields>;
  variance?: Maybe<Products_Variance_Fields>;
};


/** aggregate fields of "products" */
export type Products_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "products" */
export type Products_Aggregate_Order_By = {
  avg?: InputMaybe<Products_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Products_Max_Order_By>;
  min?: InputMaybe<Products_Min_Order_By>;
  stddev?: InputMaybe<Products_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Products_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Products_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Products_Sum_Order_By>;
  var_pop?: InputMaybe<Products_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Products_Var_Samp_Order_By>;
  variance?: InputMaybe<Products_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "products" */
export type Products_Arr_Rel_Insert_Input = {
  data: Array<Products_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Products_On_Conflict>;
};

/** aggregate avg on columns */
export type Products_Avg_Fields = {
  __typename?: 'products_avg_fields';
  deliveredAmount?: Maybe<Scalars['Float']['output']>;
  expectedAmount?: Maybe<Scalars['Float']['output']>;
  loadedAmount?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "products" */
export type Products_Avg_Order_By = {
  deliveredAmount?: InputMaybe<Order_By>;
  expectedAmount?: InputMaybe<Order_By>;
  loadedAmount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
export type Products_Bool_Exp = {
  _and?: InputMaybe<Array<Products_Bool_Exp>>;
  _not?: InputMaybe<Products_Bool_Exp>;
  _or?: InputMaybe<Array<Products_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deliveredAmount?: InputMaybe<Int_Comparison_Exp>;
  expectedAmount?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  loadedAmount?: InputMaybe<Int_Comparison_Exp>;
  productType?: InputMaybe<ProductTypes_Bool_Exp>;
  productTypeId?: InputMaybe<Uuid_Comparison_Exp>;
  ride?: InputMaybe<Rides_Bool_Exp>;
  rideId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "products" */
export enum Products_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductsPkey = 'products_pkey',
  /** unique or primary key constraint on columns "product_type_id", "ride_id" */
  ProductsProductTypeIdRideIdKey = 'products_product_type_id_ride_id_key'
}

/** input type for incrementing numeric columns in table "products" */
export type Products_Inc_Input = {
  deliveredAmount?: InputMaybe<Scalars['Int']['input']>;
  expectedAmount?: InputMaybe<Scalars['Int']['input']>;
  loadedAmount?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "products" */
export type Products_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deliveredAmount?: InputMaybe<Scalars['Int']['input']>;
  expectedAmount?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  loadedAmount?: InputMaybe<Scalars['Int']['input']>;
  productType?: InputMaybe<ProductTypes_Obj_Rel_Insert_Input>;
  productTypeId?: InputMaybe<Scalars['uuid']['input']>;
  ride?: InputMaybe<Rides_Obj_Rel_Insert_Input>;
  rideId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Products_Max_Fields = {
  __typename?: 'products_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deliveredAmount?: Maybe<Scalars['Int']['output']>;
  expectedAmount?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  loadedAmount?: Maybe<Scalars['Int']['output']>;
  productTypeId?: Maybe<Scalars['uuid']['output']>;
  rideId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "products" */
export type Products_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deliveredAmount?: InputMaybe<Order_By>;
  expectedAmount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loadedAmount?: InputMaybe<Order_By>;
  productTypeId?: InputMaybe<Order_By>;
  rideId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Products_Min_Fields = {
  __typename?: 'products_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deliveredAmount?: Maybe<Scalars['Int']['output']>;
  expectedAmount?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  loadedAmount?: Maybe<Scalars['Int']['output']>;
  productTypeId?: Maybe<Scalars['uuid']['output']>;
  rideId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "products" */
export type Products_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deliveredAmount?: InputMaybe<Order_By>;
  expectedAmount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loadedAmount?: InputMaybe<Order_By>;
  productTypeId?: InputMaybe<Order_By>;
  rideId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "products" */
export type Products_Mutation_Response = {
  __typename?: 'products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Products>;
};

/** on_conflict condition type for table "products" */
export type Products_On_Conflict = {
  constraint: Products_Constraint;
  update_columns?: Array<Products_Update_Column>;
  where?: InputMaybe<Products_Bool_Exp>;
};

/** Ordering options when selecting data from "products". */
export type Products_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deliveredAmount?: InputMaybe<Order_By>;
  expectedAmount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loadedAmount?: InputMaybe<Order_By>;
  productType?: InputMaybe<ProductTypes_Order_By>;
  productTypeId?: InputMaybe<Order_By>;
  ride?: InputMaybe<Rides_Order_By>;
  rideId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: products */
export type Products_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "products" */
export enum Products_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveredAmount = 'deliveredAmount',
  /** column name */
  ExpectedAmount = 'expectedAmount',
  /** column name */
  Id = 'id',
  /** column name */
  LoadedAmount = 'loadedAmount',
  /** column name */
  ProductTypeId = 'productTypeId',
  /** column name */
  RideId = 'rideId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "products" */
export type Products_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deliveredAmount?: InputMaybe<Scalars['Int']['input']>;
  expectedAmount?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  loadedAmount?: InputMaybe<Scalars['Int']['input']>;
  productTypeId?: InputMaybe<Scalars['uuid']['input']>;
  rideId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Products_Stddev_Fields = {
  __typename?: 'products_stddev_fields';
  deliveredAmount?: Maybe<Scalars['Float']['output']>;
  expectedAmount?: Maybe<Scalars['Float']['output']>;
  loadedAmount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "products" */
export type Products_Stddev_Order_By = {
  deliveredAmount?: InputMaybe<Order_By>;
  expectedAmount?: InputMaybe<Order_By>;
  loadedAmount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Products_Stddev_Pop_Fields = {
  __typename?: 'products_stddev_pop_fields';
  deliveredAmount?: Maybe<Scalars['Float']['output']>;
  expectedAmount?: Maybe<Scalars['Float']['output']>;
  loadedAmount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "products" */
export type Products_Stddev_Pop_Order_By = {
  deliveredAmount?: InputMaybe<Order_By>;
  expectedAmount?: InputMaybe<Order_By>;
  loadedAmount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Products_Stddev_Samp_Fields = {
  __typename?: 'products_stddev_samp_fields';
  deliveredAmount?: Maybe<Scalars['Float']['output']>;
  expectedAmount?: Maybe<Scalars['Float']['output']>;
  loadedAmount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "products" */
export type Products_Stddev_Samp_Order_By = {
  deliveredAmount?: InputMaybe<Order_By>;
  expectedAmount?: InputMaybe<Order_By>;
  loadedAmount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "products" */
export type Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Products_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deliveredAmount?: InputMaybe<Scalars['Int']['input']>;
  expectedAmount?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  loadedAmount?: InputMaybe<Scalars['Int']['input']>;
  productTypeId?: InputMaybe<Scalars['uuid']['input']>;
  rideId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Products_Sum_Fields = {
  __typename?: 'products_sum_fields';
  deliveredAmount?: Maybe<Scalars['Int']['output']>;
  expectedAmount?: Maybe<Scalars['Int']['output']>;
  loadedAmount?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "products" */
export type Products_Sum_Order_By = {
  deliveredAmount?: InputMaybe<Order_By>;
  expectedAmount?: InputMaybe<Order_By>;
  loadedAmount?: InputMaybe<Order_By>;
};

/** update columns of table "products" */
export enum Products_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveredAmount = 'deliveredAmount',
  /** column name */
  ExpectedAmount = 'expectedAmount',
  /** column name */
  Id = 'id',
  /** column name */
  LoadedAmount = 'loadedAmount',
  /** column name */
  ProductTypeId = 'productTypeId',
  /** column name */
  RideId = 'rideId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Products_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Products_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Products_Set_Input>;
  where: Products_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Products_Var_Pop_Fields = {
  __typename?: 'products_var_pop_fields';
  deliveredAmount?: Maybe<Scalars['Float']['output']>;
  expectedAmount?: Maybe<Scalars['Float']['output']>;
  loadedAmount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "products" */
export type Products_Var_Pop_Order_By = {
  deliveredAmount?: InputMaybe<Order_By>;
  expectedAmount?: InputMaybe<Order_By>;
  loadedAmount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Products_Var_Samp_Fields = {
  __typename?: 'products_var_samp_fields';
  deliveredAmount?: Maybe<Scalars['Float']['output']>;
  expectedAmount?: Maybe<Scalars['Float']['output']>;
  loadedAmount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "products" */
export type Products_Var_Samp_Order_By = {
  deliveredAmount?: InputMaybe<Order_By>;
  expectedAmount?: InputMaybe<Order_By>;
  loadedAmount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Products_Variance_Fields = {
  __typename?: 'products_variance_fields';
  deliveredAmount?: Maybe<Scalars['Float']['output']>;
  expectedAmount?: Maybe<Scalars['Float']['output']>;
  loadedAmount?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "products" */
export type Products_Variance_Order_By = {
  deliveredAmount?: InputMaybe<Order_By>;
  expectedAmount?: InputMaybe<Order_By>;
  loadedAmount?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** GetDeliveriesFromLRP */
  GetDeliveriesFromLRP?: Maybe<GetDeliveriesOutput>;
  GetUserInfoFromLRP?: Maybe<GetUserInfoOutput>;
  /** fetch data from the table: "carriers" */
  carriers: Array<Carriers>;
  /** fetch aggregated fields from the table: "carriers" */
  carriers_aggregate: Carriers_Aggregate;
  /** fetch data from the table: "carriers" using primary key columns */
  carriers_by_pk?: Maybe<Carriers>;
  /** fetch data from the table: "client_status" */
  clientStatus: Array<ClientStatus>;
  /** fetch aggregated fields from the table: "client_status" */
  clientStatus_aggregate: ClientStatus_Aggregate;
  /** fetch data from the table: "client_status" using primary key columns */
  clientStatus_by_pk?: Maybe<ClientStatus>;
  /** fetch data from the table: "clients" */
  clients: Array<Clients>;
  /** fetch aggregated fields from the table: "clients" */
  clients_aggregate: Clients_Aggregate;
  /** fetch data from the table: "clients" using primary key columns */
  clients_by_pk?: Maybe<Clients>;
  /** fetch data from the table: "consignees" */
  consignees: Array<Consignees>;
  /** fetch aggregated fields from the table: "consignees" */
  consignees_aggregate: Consignees_Aggregate;
  /** fetch data from the table: "consignees" using primary key columns */
  consignees_by_pk?: Maybe<Consignees>;
  /** An array relationship */
  delays: Array<Delays>;
  /** An aggregate relationship */
  delays_aggregate: Delays_Aggregate;
  /** fetch data from the table: "delays" using primary key columns */
  delays_by_pk?: Maybe<Delays>;
  /** fetch data from the table: "deliveries" */
  deliveries: Array<Deliveries>;
  /** fetch aggregated fields from the table: "deliveries" */
  deliveries_aggregate: Deliveries_Aggregate;
  /** fetch data from the table: "deliveries" using primary key columns */
  deliveries_by_pk?: Maybe<Deliveries>;
  /** fetch data from the table: "delivery_guest_accesses" */
  deliveryGuestAccesses: Array<DeliveryGuestAccesses>;
  /** fetch aggregated fields from the table: "delivery_guest_accesses" */
  deliveryGuestAccesses_aggregate: DeliveryGuestAccesses_Aggregate;
  /** fetch data from the table: "delivery_guest_accesses" using primary key columns */
  deliveryGuestAccesses_by_pk?: Maybe<DeliveryGuestAccesses>;
  /** fetch data from the table: "delivery_progress" */
  deliveryProgress: Array<DeliveryProgress>;
  /** fetch aggregated fields from the table: "delivery_progress" */
  deliveryProgress_aggregate: DeliveryProgress_Aggregate;
  /** fetch data from the table: "delivery_progress" using primary key columns */
  deliveryProgress_by_pk?: Maybe<DeliveryProgress>;
  /** fetch data from the table: "delivery_status" */
  deliveryStatus: Array<DeliveryStatus>;
  /** fetch aggregated fields from the table: "delivery_status" */
  deliveryStatus_aggregate: DeliveryStatus_Aggregate;
  /** fetch data from the table: "delivery_status" using primary key columns */
  deliveryStatus_by_pk?: Maybe<DeliveryStatus>;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<Events>;
  /** fetch data from the table: "product_types" */
  productTypes: Array<ProductTypes>;
  /** fetch aggregated fields from the table: "product_types" */
  productTypes_aggregate: ProductTypes_Aggregate;
  /** fetch data from the table: "product_types" using primary key columns */
  productTypes_by_pk?: Maybe<ProductTypes>;
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table: "ride_status" */
  rideStatus: Array<RideStatus>;
  /** fetch aggregated fields from the table: "ride_status" */
  rideStatus_aggregate: RideStatus_Aggregate;
  /** fetch data from the table: "ride_status" using primary key columns */
  rideStatus_by_pk?: Maybe<RideStatus>;
  /** An array relationship */
  rides: Array<Rides>;
  /** An aggregate relationship */
  rides_aggregate: Rides_Aggregate;
  /** fetch data from the table: "rides" using primary key columns */
  rides_by_pk?: Maybe<Rides>;
  /** fetch data from the table: "trucks" */
  trucks: Array<Trucks>;
  /** fetch aggregated fields from the table: "trucks" */
  trucks_aggregate: Trucks_Aggregate;
  /** fetch data from the table: "trucks" using primary key columns */
  trucks_by_pk?: Maybe<Trucks>;
  /** An array relationship */
  users_carriers: Array<Users_Carriers>;
  /** An aggregate relationship */
  users_carriers_aggregate: Users_Carriers_Aggregate;
  /** fetch data from the table: "users_carriers" using primary key columns */
  users_carriers_by_pk?: Maybe<Users_Carriers>;
  /** An array relationship */
  users_consignees: Array<Users_Consignees>;
  /** An aggregate relationship */
  users_consignees_aggregate: Users_Consignees_Aggregate;
  /** fetch data from the table: "users_consignees" using primary key columns */
  users_consignees_by_pk?: Maybe<Users_Consignees>;
  /** An array relationship */
  users_vendors: Array<Users_Vendors>;
  /** An aggregate relationship */
  users_vendors_aggregate: Users_Vendors_Aggregate;
  /** fetch data from the table: "users_vendors" using primary key columns */
  users_vendors_by_pk?: Maybe<Users_Vendors>;
  /** fetch data from the table: "vendors" */
  vendors: Array<Vendors>;
  /** fetch aggregated fields from the table: "vendors" */
  vendors_aggregate: Vendors_Aggregate;
  /** fetch data from the table: "vendors" using primary key columns */
  vendors_by_pk?: Maybe<Vendors>;
};


export type Query_RootGetDeliveriesFromLrpArgs = {
  timePeriod: GetDeliveriesInput;
};


export type Query_RootGetUserInfoFromLrpArgs = {
  user: GetUserInfoInput;
};


export type Query_RootCarriersArgs = {
  distinct_on?: InputMaybe<Array<Carriers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Carriers_Order_By>>;
  where?: InputMaybe<Carriers_Bool_Exp>;
};


export type Query_RootCarriers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Carriers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Carriers_Order_By>>;
  where?: InputMaybe<Carriers_Bool_Exp>;
};


export type Query_RootCarriers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootClientStatusArgs = {
  distinct_on?: InputMaybe<Array<ClientStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ClientStatus_Order_By>>;
  where?: InputMaybe<ClientStatus_Bool_Exp>;
};


export type Query_RootClientStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ClientStatus_Order_By>>;
  where?: InputMaybe<ClientStatus_Bool_Exp>;
};


export type Query_RootClientStatus_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootClientsArgs = {
  distinct_on?: InputMaybe<Array<Clients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clients_Order_By>>;
  where?: InputMaybe<Clients_Bool_Exp>;
};


export type Query_RootClients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clients_Order_By>>;
  where?: InputMaybe<Clients_Bool_Exp>;
};


export type Query_RootClients_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootConsigneesArgs = {
  distinct_on?: InputMaybe<Array<Consignees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Consignees_Order_By>>;
  where?: InputMaybe<Consignees_Bool_Exp>;
};


export type Query_RootConsignees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consignees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Consignees_Order_By>>;
  where?: InputMaybe<Consignees_Bool_Exp>;
};


export type Query_RootConsignees_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootDelaysArgs = {
  distinct_on?: InputMaybe<Array<Delays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delays_Order_By>>;
  where?: InputMaybe<Delays_Bool_Exp>;
};


export type Query_RootDelays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delays_Order_By>>;
  where?: InputMaybe<Delays_Bool_Exp>;
};


export type Query_RootDelays_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootDeliveriesArgs = {
  distinct_on?: InputMaybe<Array<Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Deliveries_Order_By>>;
  where?: InputMaybe<Deliveries_Bool_Exp>;
};


export type Query_RootDeliveries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Deliveries_Order_By>>;
  where?: InputMaybe<Deliveries_Bool_Exp>;
};


export type Query_RootDeliveries_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootDeliveryGuestAccessesArgs = {
  distinct_on?: InputMaybe<Array<DeliveryGuestAccesses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeliveryGuestAccesses_Order_By>>;
  where?: InputMaybe<DeliveryGuestAccesses_Bool_Exp>;
};


export type Query_RootDeliveryGuestAccesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DeliveryGuestAccesses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeliveryGuestAccesses_Order_By>>;
  where?: InputMaybe<DeliveryGuestAccesses_Bool_Exp>;
};


export type Query_RootDeliveryGuestAccesses_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootDeliveryProgressArgs = {
  distinct_on?: InputMaybe<Array<DeliveryProgress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeliveryProgress_Order_By>>;
  where?: InputMaybe<DeliveryProgress_Bool_Exp>;
};


export type Query_RootDeliveryProgress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DeliveryProgress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeliveryProgress_Order_By>>;
  where?: InputMaybe<DeliveryProgress_Bool_Exp>;
};


export type Query_RootDeliveryProgress_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootDeliveryStatusArgs = {
  distinct_on?: InputMaybe<Array<DeliveryStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeliveryStatus_Order_By>>;
  where?: InputMaybe<DeliveryStatus_Bool_Exp>;
};


export type Query_RootDeliveryStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DeliveryStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeliveryStatus_Order_By>>;
  where?: InputMaybe<DeliveryStatus_Bool_Exp>;
};


export type Query_RootDeliveryStatus_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootEventsArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Query_RootEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Query_RootEvents_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProductTypesArgs = {
  distinct_on?: InputMaybe<Array<ProductTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProductTypes_Order_By>>;
  where?: InputMaybe<ProductTypes_Bool_Exp>;
};


export type Query_RootProductTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProductTypes_Order_By>>;
  where?: InputMaybe<ProductTypes_Bool_Exp>;
};


export type Query_RootProductTypes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Query_RootProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Query_RootProducts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootRideStatusArgs = {
  distinct_on?: InputMaybe<Array<RideStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RideStatus_Order_By>>;
  where?: InputMaybe<RideStatus_Bool_Exp>;
};


export type Query_RootRideStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RideStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RideStatus_Order_By>>;
  where?: InputMaybe<RideStatus_Bool_Exp>;
};


export type Query_RootRideStatus_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootRidesArgs = {
  distinct_on?: InputMaybe<Array<Rides_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rides_Order_By>>;
  where?: InputMaybe<Rides_Bool_Exp>;
};


export type Query_RootRides_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rides_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rides_Order_By>>;
  where?: InputMaybe<Rides_Bool_Exp>;
};


export type Query_RootRides_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootTrucksArgs = {
  distinct_on?: InputMaybe<Array<Trucks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trucks_Order_By>>;
  where?: InputMaybe<Trucks_Bool_Exp>;
};


export type Query_RootTrucks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trucks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trucks_Order_By>>;
  where?: InputMaybe<Trucks_Bool_Exp>;
};


export type Query_RootTrucks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUsers_CarriersArgs = {
  distinct_on?: InputMaybe<Array<Users_Carriers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Carriers_Order_By>>;
  where?: InputMaybe<Users_Carriers_Bool_Exp>;
};


export type Query_RootUsers_Carriers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Carriers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Carriers_Order_By>>;
  where?: InputMaybe<Users_Carriers_Bool_Exp>;
};


export type Query_RootUsers_Carriers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUsers_ConsigneesArgs = {
  distinct_on?: InputMaybe<Array<Users_Consignees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Consignees_Order_By>>;
  where?: InputMaybe<Users_Consignees_Bool_Exp>;
};


export type Query_RootUsers_Consignees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Consignees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Consignees_Order_By>>;
  where?: InputMaybe<Users_Consignees_Bool_Exp>;
};


export type Query_RootUsers_Consignees_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUsers_VendorsArgs = {
  distinct_on?: InputMaybe<Array<Users_Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Vendors_Order_By>>;
  where?: InputMaybe<Users_Vendors_Bool_Exp>;
};


export type Query_RootUsers_Vendors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Vendors_Order_By>>;
  where?: InputMaybe<Users_Vendors_Bool_Exp>;
};


export type Query_RootUsers_Vendors_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootVendorsArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};


export type Query_RootVendors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};


export type Query_RootVendors_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** columns and relationships of "ride_status" */
export type RideStatus = {
  __typename?: 'rideStatus';
  value: Scalars['String']['output'];
};

/** aggregated selection of "ride_status" */
export type RideStatus_Aggregate = {
  __typename?: 'rideStatus_aggregate';
  aggregate?: Maybe<RideStatus_Aggregate_Fields>;
  nodes: Array<RideStatus>;
};

/** aggregate fields of "ride_status" */
export type RideStatus_Aggregate_Fields = {
  __typename?: 'rideStatus_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<RideStatus_Max_Fields>;
  min?: Maybe<RideStatus_Min_Fields>;
};


/** aggregate fields of "ride_status" */
export type RideStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RideStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "ride_status". All fields are combined with a logical 'AND'. */
export type RideStatus_Bool_Exp = {
  _and?: InputMaybe<Array<RideStatus_Bool_Exp>>;
  _not?: InputMaybe<RideStatus_Bool_Exp>;
  _or?: InputMaybe<Array<RideStatus_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ride_status" */
export enum RideStatus_Constraint {
  /** unique or primary key constraint on columns "value" */
  RideStatusPkey = 'RideStatus_pkey'
}

export enum RideStatus_Enum {
  Arrived = 'ARRIVED',
  Finished = 'FINISHED',
  Loading = 'LOADING',
  Pending = 'PENDING',
  Started = 'STARTED',
  Unloading = 'UNLOADING'
}

/** Boolean expression to compare columns of type "rideStatus_enum". All fields are combined with logical 'AND'. */
export type RideStatus_Enum_Comparison_Exp = {
  _eq?: InputMaybe<RideStatus_Enum>;
  _in?: InputMaybe<Array<RideStatus_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<RideStatus_Enum>;
  _nin?: InputMaybe<Array<RideStatus_Enum>>;
};

/** input type for inserting data into table "ride_status" */
export type RideStatus_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type RideStatus_Max_Fields = {
  __typename?: 'rideStatus_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RideStatus_Min_Fields = {
  __typename?: 'rideStatus_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "ride_status" */
export type RideStatus_Mutation_Response = {
  __typename?: 'rideStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RideStatus>;
};

/** on_conflict condition type for table "ride_status" */
export type RideStatus_On_Conflict = {
  constraint: RideStatus_Constraint;
  update_columns?: Array<RideStatus_Update_Column>;
  where?: InputMaybe<RideStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "ride_status". */
export type RideStatus_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ride_status */
export type RideStatus_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "ride_status" */
export enum RideStatus_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ride_status" */
export type RideStatus_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "rideStatus" */
export type RideStatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RideStatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RideStatus_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "ride_status" */
export enum RideStatus_Update_Column {
  /** column name */
  Value = 'value'
}

export type RideStatus_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RideStatus_Set_Input>;
  where: RideStatus_Bool_Exp;
};

/** columns and relationships of "rides" */
export type Rides = {
  __typename?: 'rides';
  /** An object relationship */
  consignee?: Maybe<Consignees>;
  consigneeId?: Maybe<Scalars['uuid']['output']>;
  /** Last available coordinates for selected ride */
  coordinate?: Maybe<Scalars['point']['output']>;
  /** Estimated time of arrival based on last coordinates */
  coordinate_eta?: Maybe<Scalars['timestamptz']['output']>;
  /** Time when truck coordinates was received */
  coordinate_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  delivery: Deliveries;
  deliveryId: Scalars['uuid']['output'];
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  status: RideStatus_Enum;
  timeLocal: Scalars['timestamptz']['output'];
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  vendor?: Maybe<Vendors>;
  vendorId?: Maybe<Scalars['uuid']['output']>;
};


/** columns and relationships of "rides" */
export type RidesEventsArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


/** columns and relationships of "rides" */
export type RidesEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


/** columns and relationships of "rides" */
export type RidesProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


/** columns and relationships of "rides" */
export type RidesProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};

/** aggregated selection of "rides" */
export type Rides_Aggregate = {
  __typename?: 'rides_aggregate';
  aggregate?: Maybe<Rides_Aggregate_Fields>;
  nodes: Array<Rides>;
};

export type Rides_Aggregate_Bool_Exp = {
  count?: InputMaybe<Rides_Aggregate_Bool_Exp_Count>;
};

export type Rides_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rides_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rides_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rides" */
export type Rides_Aggregate_Fields = {
  __typename?: 'rides_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Rides_Max_Fields>;
  min?: Maybe<Rides_Min_Fields>;
};


/** aggregate fields of "rides" */
export type Rides_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rides_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rides" */
export type Rides_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rides_Max_Order_By>;
  min?: InputMaybe<Rides_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rides" */
export type Rides_Arr_Rel_Insert_Input = {
  data: Array<Rides_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Rides_On_Conflict>;
};

/** Boolean expression to filter rows from the table "rides". All fields are combined with a logical 'AND'. */
export type Rides_Bool_Exp = {
  _and?: InputMaybe<Array<Rides_Bool_Exp>>;
  _not?: InputMaybe<Rides_Bool_Exp>;
  _or?: InputMaybe<Array<Rides_Bool_Exp>>;
  consignee?: InputMaybe<Consignees_Bool_Exp>;
  consigneeId?: InputMaybe<Uuid_Comparison_Exp>;
  coordinate?: InputMaybe<Point_Comparison_Exp>;
  coordinate_eta?: InputMaybe<Timestamptz_Comparison_Exp>;
  coordinate_updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  delivery?: InputMaybe<Deliveries_Bool_Exp>;
  deliveryId?: InputMaybe<Uuid_Comparison_Exp>;
  events?: InputMaybe<Events_Bool_Exp>;
  events_aggregate?: InputMaybe<Events_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  products?: InputMaybe<Products_Bool_Exp>;
  products_aggregate?: InputMaybe<Products_Aggregate_Bool_Exp>;
  status?: InputMaybe<RideStatus_Enum_Comparison_Exp>;
  timeLocal?: InputMaybe<Timestamptz_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor?: InputMaybe<Vendors_Bool_Exp>;
  vendorId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "rides" */
export enum Rides_Constraint {
  /** unique or primary key constraint on columns "id" */
  RidesPkey = 'rides_pkey',
  /** unique or primary key constraint on columns "delivery_id", "consignee_id", "vendor_id" */
  RidesVendorIdDeliveryIdConsigneeIdKey = 'rides_vendor_id_delivery_id_consignee_id_key'
}

/** input type for inserting data into table "rides" */
export type Rides_Insert_Input = {
  consignee?: InputMaybe<Consignees_Obj_Rel_Insert_Input>;
  consigneeId?: InputMaybe<Scalars['uuid']['input']>;
  /** Last available coordinates for selected ride */
  coordinate?: InputMaybe<Scalars['point']['input']>;
  /** Estimated time of arrival based on last coordinates */
  coordinate_eta?: InputMaybe<Scalars['timestamptz']['input']>;
  /** Time when truck coordinates was received */
  coordinate_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  delivery?: InputMaybe<Deliveries_Obj_Rel_Insert_Input>;
  deliveryId?: InputMaybe<Scalars['uuid']['input']>;
  events?: InputMaybe<Events_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  products?: InputMaybe<Products_Arr_Rel_Insert_Input>;
  status?: InputMaybe<RideStatus_Enum>;
  timeLocal?: InputMaybe<Scalars['timestamptz']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor?: InputMaybe<Vendors_Obj_Rel_Insert_Input>;
  vendorId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Rides_Max_Fields = {
  __typename?: 'rides_max_fields';
  consigneeId?: Maybe<Scalars['uuid']['output']>;
  /** Estimated time of arrival based on last coordinates */
  coordinate_eta?: Maybe<Scalars['timestamptz']['output']>;
  /** Time when truck coordinates was received */
  coordinate_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deliveryId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  timeLocal?: Maybe<Scalars['timestamptz']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  vendorId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "rides" */
export type Rides_Max_Order_By = {
  consigneeId?: InputMaybe<Order_By>;
  /** Estimated time of arrival based on last coordinates */
  coordinate_eta?: InputMaybe<Order_By>;
  /** Time when truck coordinates was received */
  coordinate_updated_at?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deliveryId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timeLocal?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vendorId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rides_Min_Fields = {
  __typename?: 'rides_min_fields';
  consigneeId?: Maybe<Scalars['uuid']['output']>;
  /** Estimated time of arrival based on last coordinates */
  coordinate_eta?: Maybe<Scalars['timestamptz']['output']>;
  /** Time when truck coordinates was received */
  coordinate_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deliveryId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  timeLocal?: Maybe<Scalars['timestamptz']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  vendorId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "rides" */
export type Rides_Min_Order_By = {
  consigneeId?: InputMaybe<Order_By>;
  /** Estimated time of arrival based on last coordinates */
  coordinate_eta?: InputMaybe<Order_By>;
  /** Time when truck coordinates was received */
  coordinate_updated_at?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deliveryId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  timeLocal?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vendorId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rides" */
export type Rides_Mutation_Response = {
  __typename?: 'rides_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Rides>;
};

/** input type for inserting object relation for remote table "rides" */
export type Rides_Obj_Rel_Insert_Input = {
  data: Rides_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Rides_On_Conflict>;
};

/** on_conflict condition type for table "rides" */
export type Rides_On_Conflict = {
  constraint: Rides_Constraint;
  update_columns?: Array<Rides_Update_Column>;
  where?: InputMaybe<Rides_Bool_Exp>;
};

/** Ordering options when selecting data from "rides". */
export type Rides_Order_By = {
  consignee?: InputMaybe<Consignees_Order_By>;
  consigneeId?: InputMaybe<Order_By>;
  coordinate?: InputMaybe<Order_By>;
  coordinate_eta?: InputMaybe<Order_By>;
  coordinate_updated_at?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  delivery?: InputMaybe<Deliveries_Order_By>;
  deliveryId?: InputMaybe<Order_By>;
  events_aggregate?: InputMaybe<Events_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  products_aggregate?: InputMaybe<Products_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  timeLocal?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Vendors_Order_By>;
  vendorId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rides */
export type Rides_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "rides" */
export enum Rides_Select_Column {
  /** column name */
  ConsigneeId = 'consigneeId',
  /** column name */
  Coordinate = 'coordinate',
  /** column name */
  CoordinateEta = 'coordinate_eta',
  /** column name */
  CoordinateUpdatedAt = 'coordinate_updated_at',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveryId = 'deliveryId',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TimeLocal = 'timeLocal',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VendorId = 'vendorId'
}

/** input type for updating data in table "rides" */
export type Rides_Set_Input = {
  consigneeId?: InputMaybe<Scalars['uuid']['input']>;
  /** Last available coordinates for selected ride */
  coordinate?: InputMaybe<Scalars['point']['input']>;
  /** Estimated time of arrival based on last coordinates */
  coordinate_eta?: InputMaybe<Scalars['timestamptz']['input']>;
  /** Time when truck coordinates was received */
  coordinate_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deliveryId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<RideStatus_Enum>;
  timeLocal?: InputMaybe<Scalars['timestamptz']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  vendorId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "rides" */
export type Rides_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rides_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rides_Stream_Cursor_Value_Input = {
  consigneeId?: InputMaybe<Scalars['uuid']['input']>;
  /** Last available coordinates for selected ride */
  coordinate?: InputMaybe<Scalars['point']['input']>;
  /** Estimated time of arrival based on last coordinates */
  coordinate_eta?: InputMaybe<Scalars['timestamptz']['input']>;
  /** Time when truck coordinates was received */
  coordinate_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deliveryId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<RideStatus_Enum>;
  timeLocal?: InputMaybe<Scalars['timestamptz']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  vendorId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "rides" */
export enum Rides_Update_Column {
  /** column name */
  ConsigneeId = 'consigneeId',
  /** column name */
  Coordinate = 'coordinate',
  /** column name */
  CoordinateEta = 'coordinate_eta',
  /** column name */
  CoordinateUpdatedAt = 'coordinate_updated_at',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveryId = 'deliveryId',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TimeLocal = 'timeLocal',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VendorId = 'vendorId'
}

export type Rides_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rides_Set_Input>;
  where: Rides_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "carriers" */
  carriers: Array<Carriers>;
  /** fetch aggregated fields from the table: "carriers" */
  carriers_aggregate: Carriers_Aggregate;
  /** fetch data from the table: "carriers" using primary key columns */
  carriers_by_pk?: Maybe<Carriers>;
  /** fetch data from the table in a streaming manner: "carriers" */
  carriers_stream: Array<Carriers>;
  /** fetch data from the table: "client_status" */
  clientStatus: Array<ClientStatus>;
  /** fetch aggregated fields from the table: "client_status" */
  clientStatus_aggregate: ClientStatus_Aggregate;
  /** fetch data from the table: "client_status" using primary key columns */
  clientStatus_by_pk?: Maybe<ClientStatus>;
  /** fetch data from the table in a streaming manner: "client_status" */
  clientStatus_stream: Array<ClientStatus>;
  /** fetch data from the table: "clients" */
  clients: Array<Clients>;
  /** fetch aggregated fields from the table: "clients" */
  clients_aggregate: Clients_Aggregate;
  /** fetch data from the table: "clients" using primary key columns */
  clients_by_pk?: Maybe<Clients>;
  /** fetch data from the table in a streaming manner: "clients" */
  clients_stream: Array<Clients>;
  /** fetch data from the table: "consignees" */
  consignees: Array<Consignees>;
  /** fetch aggregated fields from the table: "consignees" */
  consignees_aggregate: Consignees_Aggregate;
  /** fetch data from the table: "consignees" using primary key columns */
  consignees_by_pk?: Maybe<Consignees>;
  /** fetch data from the table in a streaming manner: "consignees" */
  consignees_stream: Array<Consignees>;
  /** An array relationship */
  delays: Array<Delays>;
  /** An aggregate relationship */
  delays_aggregate: Delays_Aggregate;
  /** fetch data from the table: "delays" using primary key columns */
  delays_by_pk?: Maybe<Delays>;
  /** fetch data from the table in a streaming manner: "delays" */
  delays_stream: Array<Delays>;
  /** fetch data from the table: "deliveries" */
  deliveries: Array<Deliveries>;
  /** fetch aggregated fields from the table: "deliveries" */
  deliveries_aggregate: Deliveries_Aggregate;
  /** fetch data from the table: "deliveries" using primary key columns */
  deliveries_by_pk?: Maybe<Deliveries>;
  /** fetch data from the table in a streaming manner: "deliveries" */
  deliveries_stream: Array<Deliveries>;
  /** fetch data from the table: "delivery_guest_accesses" */
  deliveryGuestAccesses: Array<DeliveryGuestAccesses>;
  /** fetch aggregated fields from the table: "delivery_guest_accesses" */
  deliveryGuestAccesses_aggregate: DeliveryGuestAccesses_Aggregate;
  /** fetch data from the table: "delivery_guest_accesses" using primary key columns */
  deliveryGuestAccesses_by_pk?: Maybe<DeliveryGuestAccesses>;
  /** fetch data from the table in a streaming manner: "delivery_guest_accesses" */
  deliveryGuestAccesses_stream: Array<DeliveryGuestAccesses>;
  /** fetch data from the table: "delivery_progress" */
  deliveryProgress: Array<DeliveryProgress>;
  /** fetch aggregated fields from the table: "delivery_progress" */
  deliveryProgress_aggregate: DeliveryProgress_Aggregate;
  /** fetch data from the table: "delivery_progress" using primary key columns */
  deliveryProgress_by_pk?: Maybe<DeliveryProgress>;
  /** fetch data from the table in a streaming manner: "delivery_progress" */
  deliveryProgress_stream: Array<DeliveryProgress>;
  /** fetch data from the table: "delivery_status" */
  deliveryStatus: Array<DeliveryStatus>;
  /** fetch aggregated fields from the table: "delivery_status" */
  deliveryStatus_aggregate: DeliveryStatus_Aggregate;
  /** fetch data from the table: "delivery_status" using primary key columns */
  deliveryStatus_by_pk?: Maybe<DeliveryStatus>;
  /** fetch data from the table in a streaming manner: "delivery_status" */
  deliveryStatus_stream: Array<DeliveryStatus>;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  events_aggregate: Events_Aggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<Events>;
  /** fetch data from the table in a streaming manner: "events" */
  events_stream: Array<Events>;
  /** fetch data from the table: "product_types" */
  productTypes: Array<ProductTypes>;
  /** fetch aggregated fields from the table: "product_types" */
  productTypes_aggregate: ProductTypes_Aggregate;
  /** fetch data from the table: "product_types" using primary key columns */
  productTypes_by_pk?: Maybe<ProductTypes>;
  /** fetch data from the table in a streaming manner: "product_types" */
  productTypes_stream: Array<ProductTypes>;
  /** An array relationship */
  products: Array<Products>;
  /** An aggregate relationship */
  products_aggregate: Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table in a streaming manner: "products" */
  products_stream: Array<Products>;
  /** fetch data from the table: "ride_status" */
  rideStatus: Array<RideStatus>;
  /** fetch aggregated fields from the table: "ride_status" */
  rideStatus_aggregate: RideStatus_Aggregate;
  /** fetch data from the table: "ride_status" using primary key columns */
  rideStatus_by_pk?: Maybe<RideStatus>;
  /** fetch data from the table in a streaming manner: "ride_status" */
  rideStatus_stream: Array<RideStatus>;
  /** An array relationship */
  rides: Array<Rides>;
  /** An aggregate relationship */
  rides_aggregate: Rides_Aggregate;
  /** fetch data from the table: "rides" using primary key columns */
  rides_by_pk?: Maybe<Rides>;
  /** fetch data from the table in a streaming manner: "rides" */
  rides_stream: Array<Rides>;
  /** fetch data from the table: "trucks" */
  trucks: Array<Trucks>;
  /** fetch aggregated fields from the table: "trucks" */
  trucks_aggregate: Trucks_Aggregate;
  /** fetch data from the table: "trucks" using primary key columns */
  trucks_by_pk?: Maybe<Trucks>;
  /** fetch data from the table in a streaming manner: "trucks" */
  trucks_stream: Array<Trucks>;
  /** An array relationship */
  users_carriers: Array<Users_Carriers>;
  /** An aggregate relationship */
  users_carriers_aggregate: Users_Carriers_Aggregate;
  /** fetch data from the table: "users_carriers" using primary key columns */
  users_carriers_by_pk?: Maybe<Users_Carriers>;
  /** fetch data from the table in a streaming manner: "users_carriers" */
  users_carriers_stream: Array<Users_Carriers>;
  /** An array relationship */
  users_consignees: Array<Users_Consignees>;
  /** An aggregate relationship */
  users_consignees_aggregate: Users_Consignees_Aggregate;
  /** fetch data from the table: "users_consignees" using primary key columns */
  users_consignees_by_pk?: Maybe<Users_Consignees>;
  /** fetch data from the table in a streaming manner: "users_consignees" */
  users_consignees_stream: Array<Users_Consignees>;
  /** An array relationship */
  users_vendors: Array<Users_Vendors>;
  /** An aggregate relationship */
  users_vendors_aggregate: Users_Vendors_Aggregate;
  /** fetch data from the table: "users_vendors" using primary key columns */
  users_vendors_by_pk?: Maybe<Users_Vendors>;
  /** fetch data from the table in a streaming manner: "users_vendors" */
  users_vendors_stream: Array<Users_Vendors>;
  /** fetch data from the table: "vendors" */
  vendors: Array<Vendors>;
  /** fetch aggregated fields from the table: "vendors" */
  vendors_aggregate: Vendors_Aggregate;
  /** fetch data from the table: "vendors" using primary key columns */
  vendors_by_pk?: Maybe<Vendors>;
  /** fetch data from the table in a streaming manner: "vendors" */
  vendors_stream: Array<Vendors>;
};


export type Subscription_RootCarriersArgs = {
  distinct_on?: InputMaybe<Array<Carriers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Carriers_Order_By>>;
  where?: InputMaybe<Carriers_Bool_Exp>;
};


export type Subscription_RootCarriers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Carriers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Carriers_Order_By>>;
  where?: InputMaybe<Carriers_Bool_Exp>;
};


export type Subscription_RootCarriers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootCarriers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Carriers_Stream_Cursor_Input>>;
  where?: InputMaybe<Carriers_Bool_Exp>;
};


export type Subscription_RootClientStatusArgs = {
  distinct_on?: InputMaybe<Array<ClientStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ClientStatus_Order_By>>;
  where?: InputMaybe<ClientStatus_Bool_Exp>;
};


export type Subscription_RootClientStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ClientStatus_Order_By>>;
  where?: InputMaybe<ClientStatus_Bool_Exp>;
};


export type Subscription_RootClientStatus_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootClientStatus_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ClientStatus_Stream_Cursor_Input>>;
  where?: InputMaybe<ClientStatus_Bool_Exp>;
};


export type Subscription_RootClientsArgs = {
  distinct_on?: InputMaybe<Array<Clients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clients_Order_By>>;
  where?: InputMaybe<Clients_Bool_Exp>;
};


export type Subscription_RootClients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clients_Order_By>>;
  where?: InputMaybe<Clients_Bool_Exp>;
};


export type Subscription_RootClients_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootClients_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Clients_Stream_Cursor_Input>>;
  where?: InputMaybe<Clients_Bool_Exp>;
};


export type Subscription_RootConsigneesArgs = {
  distinct_on?: InputMaybe<Array<Consignees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Consignees_Order_By>>;
  where?: InputMaybe<Consignees_Bool_Exp>;
};


export type Subscription_RootConsignees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consignees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Consignees_Order_By>>;
  where?: InputMaybe<Consignees_Bool_Exp>;
};


export type Subscription_RootConsignees_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootConsignees_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Consignees_Stream_Cursor_Input>>;
  where?: InputMaybe<Consignees_Bool_Exp>;
};


export type Subscription_RootDelaysArgs = {
  distinct_on?: InputMaybe<Array<Delays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delays_Order_By>>;
  where?: InputMaybe<Delays_Bool_Exp>;
};


export type Subscription_RootDelays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Delays_Order_By>>;
  where?: InputMaybe<Delays_Bool_Exp>;
};


export type Subscription_RootDelays_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootDelays_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Delays_Stream_Cursor_Input>>;
  where?: InputMaybe<Delays_Bool_Exp>;
};


export type Subscription_RootDeliveriesArgs = {
  distinct_on?: InputMaybe<Array<Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Deliveries_Order_By>>;
  where?: InputMaybe<Deliveries_Bool_Exp>;
};


export type Subscription_RootDeliveries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deliveries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Deliveries_Order_By>>;
  where?: InputMaybe<Deliveries_Bool_Exp>;
};


export type Subscription_RootDeliveries_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootDeliveries_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Deliveries_Stream_Cursor_Input>>;
  where?: InputMaybe<Deliveries_Bool_Exp>;
};


export type Subscription_RootDeliveryGuestAccessesArgs = {
  distinct_on?: InputMaybe<Array<DeliveryGuestAccesses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeliveryGuestAccesses_Order_By>>;
  where?: InputMaybe<DeliveryGuestAccesses_Bool_Exp>;
};


export type Subscription_RootDeliveryGuestAccesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DeliveryGuestAccesses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeliveryGuestAccesses_Order_By>>;
  where?: InputMaybe<DeliveryGuestAccesses_Bool_Exp>;
};


export type Subscription_RootDeliveryGuestAccesses_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootDeliveryGuestAccesses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DeliveryGuestAccesses_Stream_Cursor_Input>>;
  where?: InputMaybe<DeliveryGuestAccesses_Bool_Exp>;
};


export type Subscription_RootDeliveryProgressArgs = {
  distinct_on?: InputMaybe<Array<DeliveryProgress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeliveryProgress_Order_By>>;
  where?: InputMaybe<DeliveryProgress_Bool_Exp>;
};


export type Subscription_RootDeliveryProgress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DeliveryProgress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeliveryProgress_Order_By>>;
  where?: InputMaybe<DeliveryProgress_Bool_Exp>;
};


export type Subscription_RootDeliveryProgress_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootDeliveryProgress_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DeliveryProgress_Stream_Cursor_Input>>;
  where?: InputMaybe<DeliveryProgress_Bool_Exp>;
};


export type Subscription_RootDeliveryStatusArgs = {
  distinct_on?: InputMaybe<Array<DeliveryStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeliveryStatus_Order_By>>;
  where?: InputMaybe<DeliveryStatus_Bool_Exp>;
};


export type Subscription_RootDeliveryStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DeliveryStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeliveryStatus_Order_By>>;
  where?: InputMaybe<DeliveryStatus_Bool_Exp>;
};


export type Subscription_RootDeliveryStatus_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootDeliveryStatus_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DeliveryStatus_Stream_Cursor_Input>>;
  where?: InputMaybe<DeliveryStatus_Bool_Exp>;
};


export type Subscription_RootEventsArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Subscription_RootEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Subscription_RootEvents_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootEvents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Subscription_RootProductTypesArgs = {
  distinct_on?: InputMaybe<Array<ProductTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProductTypes_Order_By>>;
  where?: InputMaybe<ProductTypes_Bool_Exp>;
};


export type Subscription_RootProductTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProductTypes_Order_By>>;
  where?: InputMaybe<ProductTypes_Bool_Exp>;
};


export type Subscription_RootProductTypes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProductTypes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ProductTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<ProductTypes_Bool_Exp>;
};


export type Subscription_RootProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Subscription_RootProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Subscription_RootProducts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProducts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Products_Stream_Cursor_Input>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Subscription_RootRideStatusArgs = {
  distinct_on?: InputMaybe<Array<RideStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RideStatus_Order_By>>;
  where?: InputMaybe<RideStatus_Bool_Exp>;
};


export type Subscription_RootRideStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RideStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RideStatus_Order_By>>;
  where?: InputMaybe<RideStatus_Bool_Exp>;
};


export type Subscription_RootRideStatus_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootRideStatus_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RideStatus_Stream_Cursor_Input>>;
  where?: InputMaybe<RideStatus_Bool_Exp>;
};


export type Subscription_RootRidesArgs = {
  distinct_on?: InputMaybe<Array<Rides_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rides_Order_By>>;
  where?: InputMaybe<Rides_Bool_Exp>;
};


export type Subscription_RootRides_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rides_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rides_Order_By>>;
  where?: InputMaybe<Rides_Bool_Exp>;
};


export type Subscription_RootRides_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootRides_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Rides_Stream_Cursor_Input>>;
  where?: InputMaybe<Rides_Bool_Exp>;
};


export type Subscription_RootTrucksArgs = {
  distinct_on?: InputMaybe<Array<Trucks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trucks_Order_By>>;
  where?: InputMaybe<Trucks_Bool_Exp>;
};


export type Subscription_RootTrucks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trucks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trucks_Order_By>>;
  where?: InputMaybe<Trucks_Bool_Exp>;
};


export type Subscription_RootTrucks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootTrucks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Trucks_Stream_Cursor_Input>>;
  where?: InputMaybe<Trucks_Bool_Exp>;
};


export type Subscription_RootUsers_CarriersArgs = {
  distinct_on?: InputMaybe<Array<Users_Carriers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Carriers_Order_By>>;
  where?: InputMaybe<Users_Carriers_Bool_Exp>;
};


export type Subscription_RootUsers_Carriers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Carriers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Carriers_Order_By>>;
  where?: InputMaybe<Users_Carriers_Bool_Exp>;
};


export type Subscription_RootUsers_Carriers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUsers_Carriers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Carriers_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Carriers_Bool_Exp>;
};


export type Subscription_RootUsers_ConsigneesArgs = {
  distinct_on?: InputMaybe<Array<Users_Consignees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Consignees_Order_By>>;
  where?: InputMaybe<Users_Consignees_Bool_Exp>;
};


export type Subscription_RootUsers_Consignees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Consignees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Consignees_Order_By>>;
  where?: InputMaybe<Users_Consignees_Bool_Exp>;
};


export type Subscription_RootUsers_Consignees_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUsers_Consignees_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Consignees_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Consignees_Bool_Exp>;
};


export type Subscription_RootUsers_VendorsArgs = {
  distinct_on?: InputMaybe<Array<Users_Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Vendors_Order_By>>;
  where?: InputMaybe<Users_Vendors_Bool_Exp>;
};


export type Subscription_RootUsers_Vendors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Vendors_Order_By>>;
  where?: InputMaybe<Users_Vendors_Bool_Exp>;
};


export type Subscription_RootUsers_Vendors_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUsers_Vendors_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Vendors_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Vendors_Bool_Exp>;
};


export type Subscription_RootVendorsArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};


export type Subscription_RootVendors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};


export type Subscription_RootVendors_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootVendors_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendors_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "trucks" */
export type Trucks = {
  __typename?: 'trucks';
  /** An object relationship */
  carrier: Carriers;
  carrierId: Scalars['uuid']['output'];
  clientId: Scalars['uuid']['output'];
  coordinate?: Maybe<Scalars['point']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  driversName?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  trailerNumber?: Maybe<Scalars['String']['output']>;
  truckLicense: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "trucks" */
export type Trucks_Aggregate = {
  __typename?: 'trucks_aggregate';
  aggregate?: Maybe<Trucks_Aggregate_Fields>;
  nodes: Array<Trucks>;
};

/** aggregate fields of "trucks" */
export type Trucks_Aggregate_Fields = {
  __typename?: 'trucks_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Trucks_Max_Fields>;
  min?: Maybe<Trucks_Min_Fields>;
};


/** aggregate fields of "trucks" */
export type Trucks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Trucks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "trucks". All fields are combined with a logical 'AND'. */
export type Trucks_Bool_Exp = {
  _and?: InputMaybe<Array<Trucks_Bool_Exp>>;
  _not?: InputMaybe<Trucks_Bool_Exp>;
  _or?: InputMaybe<Array<Trucks_Bool_Exp>>;
  carrier?: InputMaybe<Carriers_Bool_Exp>;
  carrierId?: InputMaybe<Uuid_Comparison_Exp>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  coordinate?: InputMaybe<Point_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  driversName?: InputMaybe<String_Comparison_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  trailerNumber?: InputMaybe<String_Comparison_Exp>;
  truckLicense?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "trucks" */
export enum Trucks_Constraint {
  /** unique or primary key constraint on columns "external_id" */
  TrucksExternalIdKey = 'trucks_external_id_key',
  /** unique or primary key constraint on columns "id" */
  TrucksPkey = 'trucks_pkey'
}

/** input type for inserting data into table "trucks" */
export type Trucks_Insert_Input = {
  carrier?: InputMaybe<Carriers_Obj_Rel_Insert_Input>;
  carrierId?: InputMaybe<Scalars['uuid']['input']>;
  clientId?: InputMaybe<Scalars['uuid']['input']>;
  coordinate?: InputMaybe<Scalars['point']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  driversName?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  trailerNumber?: InputMaybe<Scalars['String']['input']>;
  truckLicense?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Trucks_Max_Fields = {
  __typename?: 'trucks_max_fields';
  carrierId?: Maybe<Scalars['uuid']['output']>;
  clientId?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  driversName?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  trailerNumber?: Maybe<Scalars['String']['output']>;
  truckLicense?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Trucks_Min_Fields = {
  __typename?: 'trucks_min_fields';
  carrierId?: Maybe<Scalars['uuid']['output']>;
  clientId?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  driversName?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  trailerNumber?: Maybe<Scalars['String']['output']>;
  truckLicense?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "trucks" */
export type Trucks_Mutation_Response = {
  __typename?: 'trucks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Trucks>;
};

/** input type for inserting object relation for remote table "trucks" */
export type Trucks_Obj_Rel_Insert_Input = {
  data: Trucks_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Trucks_On_Conflict>;
};

/** on_conflict condition type for table "trucks" */
export type Trucks_On_Conflict = {
  constraint: Trucks_Constraint;
  update_columns?: Array<Trucks_Update_Column>;
  where?: InputMaybe<Trucks_Bool_Exp>;
};

/** Ordering options when selecting data from "trucks". */
export type Trucks_Order_By = {
  carrier?: InputMaybe<Carriers_Order_By>;
  carrierId?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  coordinate?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  driversName?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  trailerNumber?: InputMaybe<Order_By>;
  truckLicense?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: trucks */
export type Trucks_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "trucks" */
export enum Trucks_Select_Column {
  /** column name */
  CarrierId = 'carrierId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  Coordinate = 'coordinate',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DriversName = 'driversName',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  TrailerNumber = 'trailerNumber',
  /** column name */
  TruckLicense = 'truckLicense',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "trucks" */
export type Trucks_Set_Input = {
  carrierId?: InputMaybe<Scalars['uuid']['input']>;
  clientId?: InputMaybe<Scalars['uuid']['input']>;
  coordinate?: InputMaybe<Scalars['point']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  driversName?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  trailerNumber?: InputMaybe<Scalars['String']['input']>;
  truckLicense?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "trucks" */
export type Trucks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Trucks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Trucks_Stream_Cursor_Value_Input = {
  carrierId?: InputMaybe<Scalars['uuid']['input']>;
  clientId?: InputMaybe<Scalars['uuid']['input']>;
  coordinate?: InputMaybe<Scalars['point']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  driversName?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  trailerNumber?: InputMaybe<Scalars['String']['input']>;
  truckLicense?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "trucks" */
export enum Trucks_Update_Column {
  /** column name */
  CarrierId = 'carrierId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  Coordinate = 'coordinate',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DriversName = 'driversName',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  TrailerNumber = 'trailerNumber',
  /** column name */
  TruckLicense = 'truckLicense',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Trucks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Trucks_Set_Input>;
  where: Trucks_Bool_Exp;
};

/** columns and relationships of "users_carriers" */
export type Users_Carriers = {
  __typename?: 'users_carriers';
  /** An object relationship */
  carrier: Carriers;
  carrierId: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  userId: Scalars['uuid']['output'];
};

/** aggregated selection of "users_carriers" */
export type Users_Carriers_Aggregate = {
  __typename?: 'users_carriers_aggregate';
  aggregate?: Maybe<Users_Carriers_Aggregate_Fields>;
  nodes: Array<Users_Carriers>;
};

export type Users_Carriers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Users_Carriers_Aggregate_Bool_Exp_Count>;
};

export type Users_Carriers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Carriers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Carriers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_carriers" */
export type Users_Carriers_Aggregate_Fields = {
  __typename?: 'users_carriers_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Carriers_Max_Fields>;
  min?: Maybe<Users_Carriers_Min_Fields>;
};


/** aggregate fields of "users_carriers" */
export type Users_Carriers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Carriers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users_carriers" */
export type Users_Carriers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Carriers_Max_Order_By>;
  min?: InputMaybe<Users_Carriers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users_carriers" */
export type Users_Carriers_Arr_Rel_Insert_Input = {
  data: Array<Users_Carriers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Carriers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users_carriers". All fields are combined with a logical 'AND'. */
export type Users_Carriers_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Carriers_Bool_Exp>>;
  _not?: InputMaybe<Users_Carriers_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Carriers_Bool_Exp>>;
  carrier?: InputMaybe<Carriers_Bool_Exp>;
  carrierId?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_carriers" */
export enum Users_Carriers_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersCarriersPkey = 'users_carriers_pkey',
  /** unique or primary key constraint on columns "user_id", "carrier_id" */
  UsersCarriersUserIdCarrierIdKey = 'users_carriers_user_id_carrier_id_key'
}

/** input type for inserting data into table "users_carriers" */
export type Users_Carriers_Insert_Input = {
  carrier?: InputMaybe<Carriers_Obj_Rel_Insert_Input>;
  carrierId?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Users_Carriers_Max_Fields = {
  __typename?: 'users_carriers_max_fields';
  carrierId?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "users_carriers" */
export type Users_Carriers_Max_Order_By = {
  carrierId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Carriers_Min_Fields = {
  __typename?: 'users_carriers_min_fields';
  carrierId?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "users_carriers" */
export type Users_Carriers_Min_Order_By = {
  carrierId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_carriers" */
export type Users_Carriers_Mutation_Response = {
  __typename?: 'users_carriers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Carriers>;
};

/** on_conflict condition type for table "users_carriers" */
export type Users_Carriers_On_Conflict = {
  constraint: Users_Carriers_Constraint;
  update_columns?: Array<Users_Carriers_Update_Column>;
  where?: InputMaybe<Users_Carriers_Bool_Exp>;
};

/** Ordering options when selecting data from "users_carriers". */
export type Users_Carriers_Order_By = {
  carrier?: InputMaybe<Carriers_Order_By>;
  carrierId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_carriers */
export type Users_Carriers_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "users_carriers" */
export enum Users_Carriers_Select_Column {
  /** column name */
  CarrierId = 'carrierId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "users_carriers" */
export type Users_Carriers_Set_Input = {
  carrierId?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "users_carriers" */
export type Users_Carriers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Carriers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Carriers_Stream_Cursor_Value_Input = {
  carrierId?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "users_carriers" */
export enum Users_Carriers_Update_Column {
  /** column name */
  CarrierId = 'carrierId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type Users_Carriers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Carriers_Set_Input>;
  where: Users_Carriers_Bool_Exp;
};

/** columns and relationships of "users_consignees" */
export type Users_Consignees = {
  __typename?: 'users_consignees';
  /** An object relationship */
  consignee: Consignees;
  consigneeId: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  userId: Scalars['uuid']['output'];
};

/** aggregated selection of "users_consignees" */
export type Users_Consignees_Aggregate = {
  __typename?: 'users_consignees_aggregate';
  aggregate?: Maybe<Users_Consignees_Aggregate_Fields>;
  nodes: Array<Users_Consignees>;
};

export type Users_Consignees_Aggregate_Bool_Exp = {
  count?: InputMaybe<Users_Consignees_Aggregate_Bool_Exp_Count>;
};

export type Users_Consignees_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Consignees_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Consignees_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_consignees" */
export type Users_Consignees_Aggregate_Fields = {
  __typename?: 'users_consignees_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Consignees_Max_Fields>;
  min?: Maybe<Users_Consignees_Min_Fields>;
};


/** aggregate fields of "users_consignees" */
export type Users_Consignees_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Consignees_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users_consignees" */
export type Users_Consignees_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Consignees_Max_Order_By>;
  min?: InputMaybe<Users_Consignees_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users_consignees" */
export type Users_Consignees_Arr_Rel_Insert_Input = {
  data: Array<Users_Consignees_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Consignees_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users_consignees". All fields are combined with a logical 'AND'. */
export type Users_Consignees_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Consignees_Bool_Exp>>;
  _not?: InputMaybe<Users_Consignees_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Consignees_Bool_Exp>>;
  consignee?: InputMaybe<Consignees_Bool_Exp>;
  consigneeId?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_consignees" */
export enum Users_Consignees_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersConsigneesPkey = 'users_consignees_pkey',
  /** unique or primary key constraint on columns "user_id", "consignee_id" */
  UsersConsigneesUserIdConsigneeIdKey = 'users_consignees_user_id_consignee_id_key'
}

/** input type for inserting data into table "users_consignees" */
export type Users_Consignees_Insert_Input = {
  consignee?: InputMaybe<Consignees_Obj_Rel_Insert_Input>;
  consigneeId?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Users_Consignees_Max_Fields = {
  __typename?: 'users_consignees_max_fields';
  consigneeId?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "users_consignees" */
export type Users_Consignees_Max_Order_By = {
  consigneeId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Consignees_Min_Fields = {
  __typename?: 'users_consignees_min_fields';
  consigneeId?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "users_consignees" */
export type Users_Consignees_Min_Order_By = {
  consigneeId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_consignees" */
export type Users_Consignees_Mutation_Response = {
  __typename?: 'users_consignees_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Consignees>;
};

/** on_conflict condition type for table "users_consignees" */
export type Users_Consignees_On_Conflict = {
  constraint: Users_Consignees_Constraint;
  update_columns?: Array<Users_Consignees_Update_Column>;
  where?: InputMaybe<Users_Consignees_Bool_Exp>;
};

/** Ordering options when selecting data from "users_consignees". */
export type Users_Consignees_Order_By = {
  consignee?: InputMaybe<Consignees_Order_By>;
  consigneeId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_consignees */
export type Users_Consignees_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "users_consignees" */
export enum Users_Consignees_Select_Column {
  /** column name */
  ConsigneeId = 'consigneeId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "users_consignees" */
export type Users_Consignees_Set_Input = {
  consigneeId?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "users_consignees" */
export type Users_Consignees_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Consignees_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Consignees_Stream_Cursor_Value_Input = {
  consigneeId?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "users_consignees" */
export enum Users_Consignees_Update_Column {
  /** column name */
  ConsigneeId = 'consigneeId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId'
}

export type Users_Consignees_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Consignees_Set_Input>;
  where: Users_Consignees_Bool_Exp;
};

/** columns and relationships of "users_vendors" */
export type Users_Vendors = {
  __typename?: 'users_vendors';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  userId: Scalars['uuid']['output'];
  /** An object relationship */
  vendor: Vendors;
  vendorId: Scalars['uuid']['output'];
};

/** aggregated selection of "users_vendors" */
export type Users_Vendors_Aggregate = {
  __typename?: 'users_vendors_aggregate';
  aggregate?: Maybe<Users_Vendors_Aggregate_Fields>;
  nodes: Array<Users_Vendors>;
};

export type Users_Vendors_Aggregate_Bool_Exp = {
  count?: InputMaybe<Users_Vendors_Aggregate_Bool_Exp_Count>;
};

export type Users_Vendors_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Vendors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Vendors_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_vendors" */
export type Users_Vendors_Aggregate_Fields = {
  __typename?: 'users_vendors_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Vendors_Max_Fields>;
  min?: Maybe<Users_Vendors_Min_Fields>;
};


/** aggregate fields of "users_vendors" */
export type Users_Vendors_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Vendors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users_vendors" */
export type Users_Vendors_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Vendors_Max_Order_By>;
  min?: InputMaybe<Users_Vendors_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users_vendors" */
export type Users_Vendors_Arr_Rel_Insert_Input = {
  data: Array<Users_Vendors_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Vendors_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users_vendors". All fields are combined with a logical 'AND'. */
export type Users_Vendors_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Vendors_Bool_Exp>>;
  _not?: InputMaybe<Users_Vendors_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Vendors_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  vendor?: InputMaybe<Vendors_Bool_Exp>;
  vendorId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_vendors" */
export enum Users_Vendors_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersVendorsPkey = 'users_vendors_pkey',
  /** unique or primary key constraint on columns "user_id", "vendor_id" */
  UsersVendorsUserIdVendorIdKey = 'users_vendors_user_id_vendor_id_key'
}

/** input type for inserting data into table "users_vendors" */
export type Users_Vendors_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  vendor?: InputMaybe<Vendors_Obj_Rel_Insert_Input>;
  vendorId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Users_Vendors_Max_Fields = {
  __typename?: 'users_vendors_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
  vendorId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "users_vendors" */
export type Users_Vendors_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  vendorId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Vendors_Min_Fields = {
  __typename?: 'users_vendors_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
  vendorId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "users_vendors" */
export type Users_Vendors_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  vendorId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_vendors" */
export type Users_Vendors_Mutation_Response = {
  __typename?: 'users_vendors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Vendors>;
};

/** on_conflict condition type for table "users_vendors" */
export type Users_Vendors_On_Conflict = {
  constraint: Users_Vendors_Constraint;
  update_columns?: Array<Users_Vendors_Update_Column>;
  where?: InputMaybe<Users_Vendors_Bool_Exp>;
};

/** Ordering options when selecting data from "users_vendors". */
export type Users_Vendors_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Vendors_Order_By>;
  vendorId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_vendors */
export type Users_Vendors_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "users_vendors" */
export enum Users_Vendors_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId',
  /** column name */
  VendorId = 'vendorId'
}

/** input type for updating data in table "users_vendors" */
export type Users_Vendors_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  vendorId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "users_vendors" */
export type Users_Vendors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Vendors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Vendors_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
  vendorId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "users_vendors" */
export enum Users_Vendors_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'userId',
  /** column name */
  VendorId = 'vendorId'
}

export type Users_Vendors_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Vendors_Set_Input>;
  where: Users_Vendors_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** A company or an individual that supplies goods or services. */
export type Vendors = {
  __typename?: 'vendors';
  additional?: Maybe<Scalars['jsonb']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  clientId: Scalars['uuid']['output'];
  coordinate?: Maybe<Scalars['point']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  email: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  users_vendors: Array<Users_Vendors>;
  /** An aggregate relationship */
  users_vendors_aggregate: Users_Vendors_Aggregate;
};


/** A company or an individual that supplies goods or services. */
export type VendorsAdditionalArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** A company or an individual that supplies goods or services. */
export type VendorsUsers_VendorsArgs = {
  distinct_on?: InputMaybe<Array<Users_Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Vendors_Order_By>>;
  where?: InputMaybe<Users_Vendors_Bool_Exp>;
};


/** A company or an individual that supplies goods or services. */
export type VendorsUsers_Vendors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Vendors_Order_By>>;
  where?: InputMaybe<Users_Vendors_Bool_Exp>;
};

/** aggregated selection of "vendors" */
export type Vendors_Aggregate = {
  __typename?: 'vendors_aggregate';
  aggregate?: Maybe<Vendors_Aggregate_Fields>;
  nodes: Array<Vendors>;
};

/** aggregate fields of "vendors" */
export type Vendors_Aggregate_Fields = {
  __typename?: 'vendors_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Vendors_Max_Fields>;
  min?: Maybe<Vendors_Min_Fields>;
};


/** aggregate fields of "vendors" */
export type Vendors_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vendors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Vendors_Append_Input = {
  additional?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "vendors". All fields are combined with a logical 'AND'. */
export type Vendors_Bool_Exp = {
  _and?: InputMaybe<Array<Vendors_Bool_Exp>>;
  _not?: InputMaybe<Vendors_Bool_Exp>;
  _or?: InputMaybe<Array<Vendors_Bool_Exp>>;
  additional?: InputMaybe<Jsonb_Comparison_Exp>;
  address?: InputMaybe<String_Comparison_Exp>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  coordinate?: InputMaybe<Point_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  users_vendors?: InputMaybe<Users_Vendors_Bool_Exp>;
  users_vendors_aggregate?: InputMaybe<Users_Vendors_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "vendors" */
export enum Vendors_Constraint {
  /** unique or primary key constraint on columns "external_id" */
  VendorsExternalIdKey = 'vendors_external_id_key',
  /** unique or primary key constraint on columns "id" */
  VendorsPkey = 'vendors_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Vendors_Delete_At_Path_Input = {
  additional?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Vendors_Delete_Elem_Input = {
  additional?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Vendors_Delete_Key_Input = {
  additional?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "vendors" */
export type Vendors_Insert_Input = {
  additional?: InputMaybe<Scalars['jsonb']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['uuid']['input']>;
  coordinate?: InputMaybe<Scalars['point']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  users_vendors?: InputMaybe<Users_Vendors_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Vendors_Max_Fields = {
  __typename?: 'vendors_max_fields';
  address?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Vendors_Min_Fields = {
  __typename?: 'vendors_min_fields';
  address?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "vendors" */
export type Vendors_Mutation_Response = {
  __typename?: 'vendors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendors>;
};

/** input type for inserting object relation for remote table "vendors" */
export type Vendors_Obj_Rel_Insert_Input = {
  data: Vendors_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendors_On_Conflict>;
};

/** on_conflict condition type for table "vendors" */
export type Vendors_On_Conflict = {
  constraint: Vendors_Constraint;
  update_columns?: Array<Vendors_Update_Column>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

/** Ordering options when selecting data from "vendors". */
export type Vendors_Order_By = {
  additional?: InputMaybe<Order_By>;
  address?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  coordinate?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  users_vendors_aggregate?: InputMaybe<Users_Vendors_Aggregate_Order_By>;
};

/** primary key columns input for table: vendors */
export type Vendors_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Vendors_Prepend_Input = {
  additional?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "vendors" */
export enum Vendors_Select_Column {
  /** column name */
  Additional = 'additional',
  /** column name */
  Address = 'address',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  Coordinate = 'coordinate',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "vendors" */
export type Vendors_Set_Input = {
  additional?: InputMaybe<Scalars['jsonb']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['uuid']['input']>;
  coordinate?: InputMaybe<Scalars['point']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "vendors" */
export type Vendors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendors_Stream_Cursor_Value_Input = {
  additional?: InputMaybe<Scalars['jsonb']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['uuid']['input']>;
  coordinate?: InputMaybe<Scalars['point']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "vendors" */
export enum Vendors_Update_Column {
  /** column name */
  Additional = 'additional',
  /** column name */
  Address = 'address',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  Coordinate = 'coordinate',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Vendors_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Vendors_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Vendors_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Vendors_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Vendors_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Vendors_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendors_Set_Input>;
  where: Vendors_Bool_Exp;
};

export type ClientFragment = { __typename?: 'clients', id: any, name: string, companyName: string, status: ClientStatus_Enum, address?: string | null, email: string, phone?: string | null, createdAt: any };

export type VendorFragment = { __typename?: 'vendors', id: any, name: string, email: string, createdAt: any, externalId?: string | null, address?: string | null };

export type CarrierFragment = { __typename?: 'carriers', id: any, name: string, email: string, createdAt: any, externalId?: string | null };

export type ConsigneeFragment = { __typename?: 'consignees', id: any, name: string, email?: string | null, createdAt: any, externalId?: string | null, address?: string | null, fullName?: string | null };

export type TruckFragment = { __typename?: 'trucks', id: any, driversName?: string | null, truckLicense: string, trailerNumber?: string | null, externalId?: string | null, createdAt: any };

export type DeliveryFragment = { __typename?: 'deliveries', id: any, externalId?: string | null, progress: DeliveryProgress_Enum, status: DeliveryStatus_Enum, timeLocal: any, clientId: any, truck?: { __typename?: 'trucks', carrierId: any, id: any, driversName?: string | null, truckLicense: string, trailerNumber?: string | null, externalId?: string | null, createdAt: any } | null, rides: Array<{ __typename?: 'rides', id: any, timeLocal: any, status: RideStatus_Enum, consigneeId?: any | null, vendorId?: any | null, products: Array<{ __typename?: 'products', id: any, expectedAmount: number, productTypeId: any }> }> };

export type CreateClientMutationVariables = Exact<{
  data?: InputMaybe<Clients_Insert_Input>;
}>;


export type CreateClientMutation = { __typename?: 'mutation_root', insert_clients_one?: { __typename?: 'clients', id: any } | null };

export type DeleteClientMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type DeleteClientMutation = { __typename?: 'mutation_root', delete_clients_by_pk?: { __typename?: 'clients', id: any } | null };

export type UpdateClientMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  _set?: InputMaybe<Clients_Set_Input>;
}>;


export type UpdateClientMutation = { __typename?: 'mutation_root', update_clients_by_pk?: { __typename?: 'clients', id: any } | null };

export type CreateVendorMutationVariables = Exact<{
  data: Vendors_Insert_Input;
}>;


export type CreateVendorMutation = { __typename?: 'mutation_root', insert_vendors_one?: { __typename?: 'vendors', id: any } | null };

export type ConnectVendorWithKeycloakMutationVariables = Exact<{
  data: Users_Vendors_Insert_Input;
}>;


export type ConnectVendorWithKeycloakMutation = { __typename?: 'mutation_root', insert_users_vendors_one?: { __typename?: 'users_vendors', id: any } | null };

export type CreateCarrierMutationVariables = Exact<{
  data: Carriers_Insert_Input;
}>;


export type CreateCarrierMutation = { __typename?: 'mutation_root', insert_carriers_one?: { __typename?: 'carriers', id: any } | null };

export type ConnectCarrierWithKeycloakMutationVariables = Exact<{
  data: Users_Carriers_Insert_Input;
}>;


export type ConnectCarrierWithKeycloakMutation = { __typename?: 'mutation_root', insert_users_carriers_one?: { __typename?: 'users_carriers', id: any } | null };

export type CreateConsigneeMutationVariables = Exact<{
  data: Consignees_Insert_Input;
}>;


export type CreateConsigneeMutation = { __typename?: 'mutation_root', insert_consignees_one?: { __typename?: 'consignees', id: any } | null };

export type ConnectConsigneeWithKeycloakMutationVariables = Exact<{
  data: Users_Consignees_Insert_Input;
}>;


export type ConnectConsigneeWithKeycloakMutation = { __typename?: 'mutation_root', insert_users_consignees_one?: { __typename?: 'users_consignees', id: any } | null };

export type UpdateVendorMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  data?: InputMaybe<Vendors_Set_Input>;
}>;


export type UpdateVendorMutation = { __typename?: 'mutation_root', update_vendors_by_pk?: { __typename?: 'vendors', id: any } | null };

export type UpdateCarrierMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  data?: InputMaybe<Carriers_Set_Input>;
}>;


export type UpdateCarrierMutation = { __typename?: 'mutation_root', update_carriers_by_pk?: { __typename?: 'carriers', id: any } | null };

export type UpdateConsigneeMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  data?: InputMaybe<Consignees_Set_Input>;
}>;


export type UpdateConsigneeMutation = { __typename?: 'mutation_root', update_consignees_by_pk?: { __typename?: 'consignees', id: any } | null };

export type DeleteVendorMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type DeleteVendorMutation = { __typename?: 'mutation_root', delete_vendors_by_pk?: { __typename?: 'vendors', id: any } | null, delete_users_vendors?: { __typename?: 'users_vendors_mutation_response', affected_rows: number } | null };

export type DeleteCarrierMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type DeleteCarrierMutation = { __typename?: 'mutation_root', delete_carriers_by_pk?: { __typename?: 'carriers', id: any } | null, delete_users_carriers?: { __typename?: 'users_carriers_mutation_response', affected_rows: number } | null };

export type DeleteConsigneeMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type DeleteConsigneeMutation = { __typename?: 'mutation_root', delete_consignees_by_pk?: { __typename?: 'consignees', id: any } | null, delete_users_consignees?: { __typename?: 'users_consignees_mutation_response', affected_rows: number } | null };

export type CreateTruckMutationVariables = Exact<{
  data: Trucks_Insert_Input;
}>;


export type CreateTruckMutation = { __typename?: 'mutation_root', insert_trucks_one?: { __typename?: 'trucks', id: any } | null };

export type UpdateTruckMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  data?: InputMaybe<Trucks_Set_Input>;
}>;


export type UpdateTruckMutation = { __typename?: 'mutation_root', update_trucks_by_pk?: { __typename?: 'trucks', id: any } | null };

export type DeleteTruckMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type DeleteTruckMutation = { __typename?: 'mutation_root', delete_trucks_by_pk?: { __typename?: 'trucks', id: any } | null };

export type CreateDeliveryMutationVariables = Exact<{
  data: Deliveries_Insert_Input;
}>;


export type CreateDeliveryMutation = { __typename?: 'mutation_root', insert_deliveries_one?: { __typename?: 'deliveries', id: any } | null };

export type DeleteDeliveryMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type DeleteDeliveryMutation = { __typename?: 'mutation_root', delete_deliveries_by_pk?: { __typename?: 'deliveries', id: any } | null };

export type UpdateDeliveryMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  delivery?: InputMaybe<Deliveries_Set_Input>;
  rideUpdates: Array<Rides_Updates> | Rides_Updates;
  productUpdates: Array<Products_Updates> | Products_Updates;
  ridesInsert: Array<Rides_Insert_Input> | Rides_Insert_Input;
  productsInsert: Array<Products_Insert_Input> | Products_Insert_Input;
  rideIdsToDelete: Array<Scalars['uuid']['input']> | Scalars['uuid']['input'];
  productIdsToDelete: Array<Scalars['uuid']['input']> | Scalars['uuid']['input'];
}>;


export type UpdateDeliveryMutation = { __typename?: 'mutation_root', delete_rides?: { __typename?: 'rides_mutation_response', affected_rows: number } | null, delete_products?: { __typename?: 'products_mutation_response', affected_rows: number } | null, update_deliveries_by_pk?: { __typename?: 'deliveries', id: any } | null, update_rides_many?: Array<{ __typename?: 'rides_mutation_response', affected_rows: number } | null> | null, update_products_many?: Array<{ __typename?: 'products_mutation_response', affected_rows: number } | null> | null, insert_rides?: { __typename?: 'rides_mutation_response', affected_rows: number } | null, insert_products?: { __typename?: 'products_mutation_response', affected_rows: number } | null };

export type GetClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClientsQuery = { __typename?: 'query_root', clients: Array<{ __typename?: 'clients', id: any, name: string, companyName: string, status: ClientStatus_Enum, address?: string | null, email: string, phone?: string | null, createdAt: any }> };

export type GetClientByIdQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetClientByIdQuery = { __typename?: 'query_root', clients_by_pk?: { __typename?: 'clients', id: any, name: string, companyName: string, status: ClientStatus_Enum, address?: string | null, email: string, phone?: string | null, createdAt: any } | null };

export type GetDeliveriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDeliveriesQuery = { __typename?: 'query_root', deliveries: Array<{ __typename?: 'deliveries', id: any, progress: DeliveryProgress_Enum, timeLocal: any, delays_aggregate: { __typename?: 'delays_aggregate', aggregate?: { __typename?: 'delays_aggregate_fields', sum?: { __typename?: 'delays_sum_fields', delayTime?: number | null } | null } | null }, truck?: { __typename?: 'trucks', driversName?: string | null, truckLicense: string } | null, rides: Array<{ __typename?: 'rides', consignee?: { __typename?: 'consignees', name: string } | null, vendor?: { __typename?: 'vendors', name: string } | null }> }> };

export type GetDeliveryByIdQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetDeliveryByIdQuery = { __typename?: 'query_root', deliveries_by_pk?: { __typename?: 'deliveries', id: any, externalId?: string | null, progress: DeliveryProgress_Enum, status: DeliveryStatus_Enum, timeLocal: any, clientId: any, truck?: { __typename?: 'trucks', carrierId: any, id: any, driversName?: string | null, truckLicense: string, trailerNumber?: string | null, externalId?: string | null, createdAt: any } | null, rides: Array<{ __typename?: 'rides', id: any, timeLocal: any, status: RideStatus_Enum, consigneeId?: any | null, vendorId?: any | null, products: Array<{ __typename?: 'products', id: any, expectedAmount: number, productTypeId: any }> }> } | null };

export type GetVendorsQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type GetVendorsQuery = { __typename?: 'query_root', vendors: Array<{ __typename?: 'vendors', id: any, name: string, email: string, createdAt: any, externalId?: string | null, address?: string | null }> };

export type GetCarriersQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type GetCarriersQuery = { __typename?: 'query_root', carriers: Array<{ __typename?: 'carriers', id: any, name: string, email: string, createdAt: any, externalId?: string | null }> };

export type GetConsigneesQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type GetConsigneesQuery = { __typename?: 'query_root', consignees: Array<{ __typename?: 'consignees', id: any, name: string, email?: string | null, createdAt: any, externalId?: string | null, address?: string | null, fullName?: string | null }> };

export type GetVendorByIdQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetVendorByIdQuery = { __typename?: 'query_root', vendors_by_pk?: { __typename?: 'vendors', id: any, name: string, email: string, createdAt: any, externalId?: string | null, address?: string | null } | null };

export type GetCarrierByIdQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetCarrierByIdQuery = { __typename?: 'query_root', carriers_by_pk?: { __typename?: 'carriers', id: any, name: string, email: string, createdAt: any, externalId?: string | null } | null };

export type GetConsigneeByIdQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetConsigneeByIdQuery = { __typename?: 'query_root', consignees_by_pk?: { __typename?: 'consignees', id: any, name: string, email?: string | null, createdAt: any, externalId?: string | null, address?: string | null, fullName?: string | null } | null };

export type GetTrucksQueryVariables = Exact<{
  clientId: Scalars['uuid']['input'];
}>;


export type GetTrucksQuery = { __typename?: 'query_root', trucks: Array<{ __typename?: 'trucks', id: any, driversName?: string | null, truckLicense: string, trailerNumber?: string | null, externalId?: string | null, createdAt: any, carrier: { __typename?: 'carriers', name: string } }> };

export type GetTruckByIdQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type GetTruckByIdQuery = { __typename?: 'query_root', trucks_by_pk?: { __typename?: 'trucks', carrierId: any, id: any, driversName?: string | null, truckLicense: string, trailerNumber?: string | null, externalId?: string | null, createdAt: any, carrier: { __typename?: 'carriers', id: any, name: string } } | null };

export type GetTrucksByCarrierIdQueryVariables = Exact<{
  carrierId: Scalars['uuid']['input'];
}>;


export type GetTrucksByCarrierIdQuery = { __typename?: 'query_root', trucks: Array<{ __typename?: 'trucks', id: any, driversName?: string | null, truckLicense: string, trailerNumber?: string | null, externalId?: string | null, createdAt: any }> };

export type GetProductTypesQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type GetProductTypesQuery = { __typename?: 'query_root', productTypes: Array<{ __typename?: 'productTypes', id: any, name: string, externalId?: string | null, createdAt: any }> };

export const ClientFragmentDoc = gql`
    fragment Client on clients {
  id
  name
  companyName
  status
  address
  email
  phone
  createdAt
}
    `;
export const VendorFragmentDoc = gql`
    fragment Vendor on vendors {
  id
  name
  email
  createdAt
  externalId
  address
}
    `;
export const CarrierFragmentDoc = gql`
    fragment Carrier on carriers {
  id
  name
  email
  createdAt
  externalId
}
    `;
export const ConsigneeFragmentDoc = gql`
    fragment Consignee on consignees {
  id
  name
  email
  createdAt
  externalId
  address
  fullName
}
    `;
export const TruckFragmentDoc = gql`
    fragment Truck on trucks {
  id
  driversName
  truckLicense
  trailerNumber
  externalId
  createdAt
}
    `;
export const DeliveryFragmentDoc = gql`
    fragment Delivery on deliveries {
  id
  externalId
  progress
  status
  timeLocal
  clientId
  truck {
    ...Truck
    carrierId
  }
  rides(order_by: {timeLocal: asc}) {
    id
    timeLocal
    status
    consigneeId
    vendorId
    products {
      id
      expectedAmount
      productTypeId
    }
  }
}
    ${TruckFragmentDoc}`;
export const CreateClientDocument = gql`
    mutation CreateClient($data: clients_insert_input = {}) {
  insert_clients_one(object: $data) {
    id
  }
}
    `;
export type CreateClientMutationFn = Apollo.MutationFunction<CreateClientMutation, CreateClientMutationVariables>;

/**
 * __useCreateClientMutation__
 *
 * To run a mutation, you first call `useCreateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientMutation, { data, loading, error }] = useCreateClientMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateClientMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientMutation, CreateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientMutation, CreateClientMutationVariables>(CreateClientDocument, options);
      }
export type CreateClientMutationHookResult = ReturnType<typeof useCreateClientMutation>;
export type CreateClientMutationResult = Apollo.MutationResult<CreateClientMutation>;
export type CreateClientMutationOptions = Apollo.BaseMutationOptions<CreateClientMutation, CreateClientMutationVariables>;
export const DeleteClientDocument = gql`
    mutation DeleteClient($id: uuid!) {
  delete_clients_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteClientMutationFn = Apollo.MutationFunction<DeleteClientMutation, DeleteClientMutationVariables>;

/**
 * __useDeleteClientMutation__
 *
 * To run a mutation, you first call `useDeleteClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientMutation, { data, loading, error }] = useDeleteClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClientMutation, DeleteClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClientMutation, DeleteClientMutationVariables>(DeleteClientDocument, options);
      }
export type DeleteClientMutationHookResult = ReturnType<typeof useDeleteClientMutation>;
export type DeleteClientMutationResult = Apollo.MutationResult<DeleteClientMutation>;
export type DeleteClientMutationOptions = Apollo.BaseMutationOptions<DeleteClientMutation, DeleteClientMutationVariables>;
export const UpdateClientDocument = gql`
    mutation UpdateClient($id: uuid!, $_set: clients_set_input) {
  update_clients_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
  }
}
    `;
export type UpdateClientMutationFn = Apollo.MutationFunction<UpdateClientMutation, UpdateClientMutationVariables>;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateClientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientMutation, UpdateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientMutation, UpdateClientMutationVariables>(UpdateClientDocument, options);
      }
export type UpdateClientMutationHookResult = ReturnType<typeof useUpdateClientMutation>;
export type UpdateClientMutationResult = Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<UpdateClientMutation, UpdateClientMutationVariables>;
export const CreateVendorDocument = gql`
    mutation CreateVendor($data: vendors_insert_input!) {
  insert_vendors_one(object: $data) {
    id
  }
}
    `;
export type CreateVendorMutationFn = Apollo.MutationFunction<CreateVendorMutation, CreateVendorMutationVariables>;

/**
 * __useCreateVendorMutation__
 *
 * To run a mutation, you first call `useCreateVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVendorMutation, { data, loading, error }] = useCreateVendorMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateVendorMutation(baseOptions?: Apollo.MutationHookOptions<CreateVendorMutation, CreateVendorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVendorMutation, CreateVendorMutationVariables>(CreateVendorDocument, options);
      }
export type CreateVendorMutationHookResult = ReturnType<typeof useCreateVendorMutation>;
export type CreateVendorMutationResult = Apollo.MutationResult<CreateVendorMutation>;
export type CreateVendorMutationOptions = Apollo.BaseMutationOptions<CreateVendorMutation, CreateVendorMutationVariables>;
export const ConnectVendorWithKeycloakDocument = gql`
    mutation ConnectVendorWithKeycloak($data: users_vendors_insert_input!) {
  insert_users_vendors_one(object: $data) {
    id
  }
}
    `;
export type ConnectVendorWithKeycloakMutationFn = Apollo.MutationFunction<ConnectVendorWithKeycloakMutation, ConnectVendorWithKeycloakMutationVariables>;

/**
 * __useConnectVendorWithKeycloakMutation__
 *
 * To run a mutation, you first call `useConnectVendorWithKeycloakMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectVendorWithKeycloakMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectVendorWithKeycloakMutation, { data, loading, error }] = useConnectVendorWithKeycloakMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useConnectVendorWithKeycloakMutation(baseOptions?: Apollo.MutationHookOptions<ConnectVendorWithKeycloakMutation, ConnectVendorWithKeycloakMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectVendorWithKeycloakMutation, ConnectVendorWithKeycloakMutationVariables>(ConnectVendorWithKeycloakDocument, options);
      }
export type ConnectVendorWithKeycloakMutationHookResult = ReturnType<typeof useConnectVendorWithKeycloakMutation>;
export type ConnectVendorWithKeycloakMutationResult = Apollo.MutationResult<ConnectVendorWithKeycloakMutation>;
export type ConnectVendorWithKeycloakMutationOptions = Apollo.BaseMutationOptions<ConnectVendorWithKeycloakMutation, ConnectVendorWithKeycloakMutationVariables>;
export const CreateCarrierDocument = gql`
    mutation CreateCarrier($data: carriers_insert_input!) {
  insert_carriers_one(object: $data) {
    id
  }
}
    `;
export type CreateCarrierMutationFn = Apollo.MutationFunction<CreateCarrierMutation, CreateCarrierMutationVariables>;

/**
 * __useCreateCarrierMutation__
 *
 * To run a mutation, you first call `useCreateCarrierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCarrierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCarrierMutation, { data, loading, error }] = useCreateCarrierMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCarrierMutation(baseOptions?: Apollo.MutationHookOptions<CreateCarrierMutation, CreateCarrierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCarrierMutation, CreateCarrierMutationVariables>(CreateCarrierDocument, options);
      }
export type CreateCarrierMutationHookResult = ReturnType<typeof useCreateCarrierMutation>;
export type CreateCarrierMutationResult = Apollo.MutationResult<CreateCarrierMutation>;
export type CreateCarrierMutationOptions = Apollo.BaseMutationOptions<CreateCarrierMutation, CreateCarrierMutationVariables>;
export const ConnectCarrierWithKeycloakDocument = gql`
    mutation ConnectCarrierWithKeycloak($data: users_carriers_insert_input!) {
  insert_users_carriers_one(object: $data) {
    id
  }
}
    `;
export type ConnectCarrierWithKeycloakMutationFn = Apollo.MutationFunction<ConnectCarrierWithKeycloakMutation, ConnectCarrierWithKeycloakMutationVariables>;

/**
 * __useConnectCarrierWithKeycloakMutation__
 *
 * To run a mutation, you first call `useConnectCarrierWithKeycloakMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectCarrierWithKeycloakMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectCarrierWithKeycloakMutation, { data, loading, error }] = useConnectCarrierWithKeycloakMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useConnectCarrierWithKeycloakMutation(baseOptions?: Apollo.MutationHookOptions<ConnectCarrierWithKeycloakMutation, ConnectCarrierWithKeycloakMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectCarrierWithKeycloakMutation, ConnectCarrierWithKeycloakMutationVariables>(ConnectCarrierWithKeycloakDocument, options);
      }
export type ConnectCarrierWithKeycloakMutationHookResult = ReturnType<typeof useConnectCarrierWithKeycloakMutation>;
export type ConnectCarrierWithKeycloakMutationResult = Apollo.MutationResult<ConnectCarrierWithKeycloakMutation>;
export type ConnectCarrierWithKeycloakMutationOptions = Apollo.BaseMutationOptions<ConnectCarrierWithKeycloakMutation, ConnectCarrierWithKeycloakMutationVariables>;
export const CreateConsigneeDocument = gql`
    mutation CreateConsignee($data: consignees_insert_input!) {
  insert_consignees_one(object: $data) {
    id
  }
}
    `;
export type CreateConsigneeMutationFn = Apollo.MutationFunction<CreateConsigneeMutation, CreateConsigneeMutationVariables>;

/**
 * __useCreateConsigneeMutation__
 *
 * To run a mutation, you first call `useCreateConsigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConsigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConsigneeMutation, { data, loading, error }] = useCreateConsigneeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateConsigneeMutation(baseOptions?: Apollo.MutationHookOptions<CreateConsigneeMutation, CreateConsigneeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConsigneeMutation, CreateConsigneeMutationVariables>(CreateConsigneeDocument, options);
      }
export type CreateConsigneeMutationHookResult = ReturnType<typeof useCreateConsigneeMutation>;
export type CreateConsigneeMutationResult = Apollo.MutationResult<CreateConsigneeMutation>;
export type CreateConsigneeMutationOptions = Apollo.BaseMutationOptions<CreateConsigneeMutation, CreateConsigneeMutationVariables>;
export const ConnectConsigneeWithKeycloakDocument = gql`
    mutation ConnectConsigneeWithKeycloak($data: users_consignees_insert_input!) {
  insert_users_consignees_one(object: $data) {
    id
  }
}
    `;
export type ConnectConsigneeWithKeycloakMutationFn = Apollo.MutationFunction<ConnectConsigneeWithKeycloakMutation, ConnectConsigneeWithKeycloakMutationVariables>;

/**
 * __useConnectConsigneeWithKeycloakMutation__
 *
 * To run a mutation, you first call `useConnectConsigneeWithKeycloakMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectConsigneeWithKeycloakMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectConsigneeWithKeycloakMutation, { data, loading, error }] = useConnectConsigneeWithKeycloakMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useConnectConsigneeWithKeycloakMutation(baseOptions?: Apollo.MutationHookOptions<ConnectConsigneeWithKeycloakMutation, ConnectConsigneeWithKeycloakMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectConsigneeWithKeycloakMutation, ConnectConsigneeWithKeycloakMutationVariables>(ConnectConsigneeWithKeycloakDocument, options);
      }
export type ConnectConsigneeWithKeycloakMutationHookResult = ReturnType<typeof useConnectConsigneeWithKeycloakMutation>;
export type ConnectConsigneeWithKeycloakMutationResult = Apollo.MutationResult<ConnectConsigneeWithKeycloakMutation>;
export type ConnectConsigneeWithKeycloakMutationOptions = Apollo.BaseMutationOptions<ConnectConsigneeWithKeycloakMutation, ConnectConsigneeWithKeycloakMutationVariables>;
export const UpdateVendorDocument = gql`
    mutation UpdateVendor($id: uuid!, $data: vendors_set_input) {
  update_vendors_by_pk(pk_columns: {id: $id}, _set: $data) {
    id
  }
}
    `;
export type UpdateVendorMutationFn = Apollo.MutationFunction<UpdateVendorMutation, UpdateVendorMutationVariables>;

/**
 * __useUpdateVendorMutation__
 *
 * To run a mutation, you first call `useUpdateVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorMutation, { data, loading, error }] = useUpdateVendorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateVendorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVendorMutation, UpdateVendorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVendorMutation, UpdateVendorMutationVariables>(UpdateVendorDocument, options);
      }
export type UpdateVendorMutationHookResult = ReturnType<typeof useUpdateVendorMutation>;
export type UpdateVendorMutationResult = Apollo.MutationResult<UpdateVendorMutation>;
export type UpdateVendorMutationOptions = Apollo.BaseMutationOptions<UpdateVendorMutation, UpdateVendorMutationVariables>;
export const UpdateCarrierDocument = gql`
    mutation UpdateCarrier($id: uuid!, $data: carriers_set_input) {
  update_carriers_by_pk(pk_columns: {id: $id}, _set: $data) {
    id
  }
}
    `;
export type UpdateCarrierMutationFn = Apollo.MutationFunction<UpdateCarrierMutation, UpdateCarrierMutationVariables>;

/**
 * __useUpdateCarrierMutation__
 *
 * To run a mutation, you first call `useUpdateCarrierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCarrierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarrierMutation, { data, loading, error }] = useUpdateCarrierMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCarrierMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCarrierMutation, UpdateCarrierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCarrierMutation, UpdateCarrierMutationVariables>(UpdateCarrierDocument, options);
      }
export type UpdateCarrierMutationHookResult = ReturnType<typeof useUpdateCarrierMutation>;
export type UpdateCarrierMutationResult = Apollo.MutationResult<UpdateCarrierMutation>;
export type UpdateCarrierMutationOptions = Apollo.BaseMutationOptions<UpdateCarrierMutation, UpdateCarrierMutationVariables>;
export const UpdateConsigneeDocument = gql`
    mutation UpdateConsignee($id: uuid!, $data: consignees_set_input) {
  update_consignees_by_pk(pk_columns: {id: $id}, _set: $data) {
    id
  }
}
    `;
export type UpdateConsigneeMutationFn = Apollo.MutationFunction<UpdateConsigneeMutation, UpdateConsigneeMutationVariables>;

/**
 * __useUpdateConsigneeMutation__
 *
 * To run a mutation, you first call `useUpdateConsigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsigneeMutation, { data, loading, error }] = useUpdateConsigneeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateConsigneeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConsigneeMutation, UpdateConsigneeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConsigneeMutation, UpdateConsigneeMutationVariables>(UpdateConsigneeDocument, options);
      }
export type UpdateConsigneeMutationHookResult = ReturnType<typeof useUpdateConsigneeMutation>;
export type UpdateConsigneeMutationResult = Apollo.MutationResult<UpdateConsigneeMutation>;
export type UpdateConsigneeMutationOptions = Apollo.BaseMutationOptions<UpdateConsigneeMutation, UpdateConsigneeMutationVariables>;
export const DeleteVendorDocument = gql`
    mutation DeleteVendor($id: uuid!) {
  delete_vendors_by_pk(id: $id) {
    id
  }
  delete_users_vendors(where: {vendorId: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteVendorMutationFn = Apollo.MutationFunction<DeleteVendorMutation, DeleteVendorMutationVariables>;

/**
 * __useDeleteVendorMutation__
 *
 * To run a mutation, you first call `useDeleteVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVendorMutation, { data, loading, error }] = useDeleteVendorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVendorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVendorMutation, DeleteVendorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVendorMutation, DeleteVendorMutationVariables>(DeleteVendorDocument, options);
      }
export type DeleteVendorMutationHookResult = ReturnType<typeof useDeleteVendorMutation>;
export type DeleteVendorMutationResult = Apollo.MutationResult<DeleteVendorMutation>;
export type DeleteVendorMutationOptions = Apollo.BaseMutationOptions<DeleteVendorMutation, DeleteVendorMutationVariables>;
export const DeleteCarrierDocument = gql`
    mutation DeleteCarrier($id: uuid!) {
  delete_carriers_by_pk(id: $id) {
    id
  }
  delete_users_carriers(where: {carrierId: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteCarrierMutationFn = Apollo.MutationFunction<DeleteCarrierMutation, DeleteCarrierMutationVariables>;

/**
 * __useDeleteCarrierMutation__
 *
 * To run a mutation, you first call `useDeleteCarrierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCarrierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCarrierMutation, { data, loading, error }] = useDeleteCarrierMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCarrierMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCarrierMutation, DeleteCarrierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCarrierMutation, DeleteCarrierMutationVariables>(DeleteCarrierDocument, options);
      }
export type DeleteCarrierMutationHookResult = ReturnType<typeof useDeleteCarrierMutation>;
export type DeleteCarrierMutationResult = Apollo.MutationResult<DeleteCarrierMutation>;
export type DeleteCarrierMutationOptions = Apollo.BaseMutationOptions<DeleteCarrierMutation, DeleteCarrierMutationVariables>;
export const DeleteConsigneeDocument = gql`
    mutation DeleteConsignee($id: uuid!) {
  delete_consignees_by_pk(id: $id) {
    id
  }
  delete_users_consignees(where: {consigneeId: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteConsigneeMutationFn = Apollo.MutationFunction<DeleteConsigneeMutation, DeleteConsigneeMutationVariables>;

/**
 * __useDeleteConsigneeMutation__
 *
 * To run a mutation, you first call `useDeleteConsigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConsigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConsigneeMutation, { data, loading, error }] = useDeleteConsigneeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteConsigneeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConsigneeMutation, DeleteConsigneeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConsigneeMutation, DeleteConsigneeMutationVariables>(DeleteConsigneeDocument, options);
      }
export type DeleteConsigneeMutationHookResult = ReturnType<typeof useDeleteConsigneeMutation>;
export type DeleteConsigneeMutationResult = Apollo.MutationResult<DeleteConsigneeMutation>;
export type DeleteConsigneeMutationOptions = Apollo.BaseMutationOptions<DeleteConsigneeMutation, DeleteConsigneeMutationVariables>;
export const CreateTruckDocument = gql`
    mutation CreateTruck($data: trucks_insert_input!) {
  insert_trucks_one(object: $data) {
    id
  }
}
    `;
export type CreateTruckMutationFn = Apollo.MutationFunction<CreateTruckMutation, CreateTruckMutationVariables>;

/**
 * __useCreateTruckMutation__
 *
 * To run a mutation, you first call `useCreateTruckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTruckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTruckMutation, { data, loading, error }] = useCreateTruckMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTruckMutation(baseOptions?: Apollo.MutationHookOptions<CreateTruckMutation, CreateTruckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTruckMutation, CreateTruckMutationVariables>(CreateTruckDocument, options);
      }
export type CreateTruckMutationHookResult = ReturnType<typeof useCreateTruckMutation>;
export type CreateTruckMutationResult = Apollo.MutationResult<CreateTruckMutation>;
export type CreateTruckMutationOptions = Apollo.BaseMutationOptions<CreateTruckMutation, CreateTruckMutationVariables>;
export const UpdateTruckDocument = gql`
    mutation UpdateTruck($id: uuid!, $data: trucks_set_input) {
  update_trucks_by_pk(pk_columns: {id: $id}, _set: $data) {
    id
  }
}
    `;
export type UpdateTruckMutationFn = Apollo.MutationFunction<UpdateTruckMutation, UpdateTruckMutationVariables>;

/**
 * __useUpdateTruckMutation__
 *
 * To run a mutation, you first call `useUpdateTruckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTruckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTruckMutation, { data, loading, error }] = useUpdateTruckMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTruckMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTruckMutation, UpdateTruckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTruckMutation, UpdateTruckMutationVariables>(UpdateTruckDocument, options);
      }
export type UpdateTruckMutationHookResult = ReturnType<typeof useUpdateTruckMutation>;
export type UpdateTruckMutationResult = Apollo.MutationResult<UpdateTruckMutation>;
export type UpdateTruckMutationOptions = Apollo.BaseMutationOptions<UpdateTruckMutation, UpdateTruckMutationVariables>;
export const DeleteTruckDocument = gql`
    mutation DeleteTruck($id: uuid!) {
  delete_trucks_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteTruckMutationFn = Apollo.MutationFunction<DeleteTruckMutation, DeleteTruckMutationVariables>;

/**
 * __useDeleteTruckMutation__
 *
 * To run a mutation, you first call `useDeleteTruckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTruckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTruckMutation, { data, loading, error }] = useDeleteTruckMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTruckMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTruckMutation, DeleteTruckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTruckMutation, DeleteTruckMutationVariables>(DeleteTruckDocument, options);
      }
export type DeleteTruckMutationHookResult = ReturnType<typeof useDeleteTruckMutation>;
export type DeleteTruckMutationResult = Apollo.MutationResult<DeleteTruckMutation>;
export type DeleteTruckMutationOptions = Apollo.BaseMutationOptions<DeleteTruckMutation, DeleteTruckMutationVariables>;
export const CreateDeliveryDocument = gql`
    mutation CreateDelivery($data: deliveries_insert_input!) {
  insert_deliveries_one(object: $data) {
    id
  }
}
    `;
export type CreateDeliveryMutationFn = Apollo.MutationFunction<CreateDeliveryMutation, CreateDeliveryMutationVariables>;

/**
 * __useCreateDeliveryMutation__
 *
 * To run a mutation, you first call `useCreateDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeliveryMutation, { data, loading, error }] = useCreateDeliveryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeliveryMutation, CreateDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeliveryMutation, CreateDeliveryMutationVariables>(CreateDeliveryDocument, options);
      }
export type CreateDeliveryMutationHookResult = ReturnType<typeof useCreateDeliveryMutation>;
export type CreateDeliveryMutationResult = Apollo.MutationResult<CreateDeliveryMutation>;
export type CreateDeliveryMutationOptions = Apollo.BaseMutationOptions<CreateDeliveryMutation, CreateDeliveryMutationVariables>;
export const DeleteDeliveryDocument = gql`
    mutation DeleteDelivery($id: uuid!) {
  delete_deliveries_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteDeliveryMutationFn = Apollo.MutationFunction<DeleteDeliveryMutation, DeleteDeliveryMutationVariables>;

/**
 * __useDeleteDeliveryMutation__
 *
 * To run a mutation, you first call `useDeleteDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeliveryMutation, { data, loading, error }] = useDeleteDeliveryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeliveryMutation, DeleteDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeliveryMutation, DeleteDeliveryMutationVariables>(DeleteDeliveryDocument, options);
      }
export type DeleteDeliveryMutationHookResult = ReturnType<typeof useDeleteDeliveryMutation>;
export type DeleteDeliveryMutationResult = Apollo.MutationResult<DeleteDeliveryMutation>;
export type DeleteDeliveryMutationOptions = Apollo.BaseMutationOptions<DeleteDeliveryMutation, DeleteDeliveryMutationVariables>;
export const UpdateDeliveryDocument = gql`
    mutation UpdateDelivery($id: uuid!, $delivery: deliveries_set_input, $rideUpdates: [rides_updates!]!, $productUpdates: [products_updates!]!, $ridesInsert: [rides_insert_input!]!, $productsInsert: [products_insert_input!]!, $rideIdsToDelete: [uuid!]!, $productIdsToDelete: [uuid!]!) {
  delete_rides(where: {id: {_in: $rideIdsToDelete}}) {
    affected_rows
  }
  delete_products(where: {id: {_in: $productIdsToDelete}}) {
    affected_rows
  }
  update_deliveries_by_pk(pk_columns: {id: $id}, _set: $delivery) {
    id
  }
  update_rides_many(updates: $rideUpdates) {
    affected_rows
  }
  update_products_many(updates: $productUpdates) {
    affected_rows
  }
  insert_rides(objects: $ridesInsert) {
    affected_rows
  }
  insert_products(objects: $productsInsert) {
    affected_rows
  }
}
    `;
export type UpdateDeliveryMutationFn = Apollo.MutationFunction<UpdateDeliveryMutation, UpdateDeliveryMutationVariables>;

/**
 * __useUpdateDeliveryMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveryMutation, { data, loading, error }] = useUpdateDeliveryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      delivery: // value for 'delivery'
 *      rideUpdates: // value for 'rideUpdates'
 *      productUpdates: // value for 'productUpdates'
 *      ridesInsert: // value for 'ridesInsert'
 *      productsInsert: // value for 'productsInsert'
 *      rideIdsToDelete: // value for 'rideIdsToDelete'
 *      productIdsToDelete: // value for 'productIdsToDelete'
 *   },
 * });
 */
export function useUpdateDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeliveryMutation, UpdateDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeliveryMutation, UpdateDeliveryMutationVariables>(UpdateDeliveryDocument, options);
      }
export type UpdateDeliveryMutationHookResult = ReturnType<typeof useUpdateDeliveryMutation>;
export type UpdateDeliveryMutationResult = Apollo.MutationResult<UpdateDeliveryMutation>;
export type UpdateDeliveryMutationOptions = Apollo.BaseMutationOptions<UpdateDeliveryMutation, UpdateDeliveryMutationVariables>;
export const GetClientsDocument = gql`
    query GetClients {
  clients {
    ...Client
  }
}
    ${ClientFragmentDoc}`;

/**
 * __useGetClientsQuery__
 *
 * To run a query within a React component, call `useGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientsQuery(baseOptions?: Apollo.QueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
      }
export function useGetClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
        }
export type GetClientsQueryHookResult = ReturnType<typeof useGetClientsQuery>;
export type GetClientsLazyQueryHookResult = ReturnType<typeof useGetClientsLazyQuery>;
export type GetClientsQueryResult = Apollo.QueryResult<GetClientsQuery, GetClientsQueryVariables>;
export const GetClientByIdDocument = gql`
    query GetClientById($id: uuid!) {
  clients_by_pk(id: $id) {
    ...Client
  }
}
    ${ClientFragmentDoc}`;

/**
 * __useGetClientByIdQuery__
 *
 * To run a query within a React component, call `useGetClientByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientByIdQuery(baseOptions: Apollo.QueryHookOptions<GetClientByIdQuery, GetClientByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientByIdQuery, GetClientByIdQueryVariables>(GetClientByIdDocument, options);
      }
export function useGetClientByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientByIdQuery, GetClientByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientByIdQuery, GetClientByIdQueryVariables>(GetClientByIdDocument, options);
        }
export type GetClientByIdQueryHookResult = ReturnType<typeof useGetClientByIdQuery>;
export type GetClientByIdLazyQueryHookResult = ReturnType<typeof useGetClientByIdLazyQuery>;
export type GetClientByIdQueryResult = Apollo.QueryResult<GetClientByIdQuery, GetClientByIdQueryVariables>;
export const GetDeliveriesDocument = gql`
    query GetDeliveries {
  deliveries(order_by: {createdAt: desc}) {
    id
    progress
    timeLocal
    delays_aggregate {
      aggregate {
        sum {
          delayTime
        }
      }
    }
    truck {
      driversName
      truckLicense
    }
    rides(order_by: {timeLocal: asc}) {
      consignee {
        name
      }
      vendor {
        name
      }
    }
  }
}
    `;

/**
 * __useGetDeliveriesQuery__
 *
 * To run a query within a React component, call `useGetDeliveriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeliveriesQuery(baseOptions?: Apollo.QueryHookOptions<GetDeliveriesQuery, GetDeliveriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveriesQuery, GetDeliveriesQueryVariables>(GetDeliveriesDocument, options);
      }
export function useGetDeliveriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveriesQuery, GetDeliveriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveriesQuery, GetDeliveriesQueryVariables>(GetDeliveriesDocument, options);
        }
export type GetDeliveriesQueryHookResult = ReturnType<typeof useGetDeliveriesQuery>;
export type GetDeliveriesLazyQueryHookResult = ReturnType<typeof useGetDeliveriesLazyQuery>;
export type GetDeliveriesQueryResult = Apollo.QueryResult<GetDeliveriesQuery, GetDeliveriesQueryVariables>;
export const GetDeliveryByIdDocument = gql`
    query GetDeliveryById($id: uuid!) {
  deliveries_by_pk(id: $id) {
    ...Delivery
  }
}
    ${DeliveryFragmentDoc}`;

/**
 * __useGetDeliveryByIdQuery__
 *
 * To run a query within a React component, call `useGetDeliveryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDeliveryByIdQuery(baseOptions: Apollo.QueryHookOptions<GetDeliveryByIdQuery, GetDeliveryByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveryByIdQuery, GetDeliveryByIdQueryVariables>(GetDeliveryByIdDocument, options);
      }
export function useGetDeliveryByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryByIdQuery, GetDeliveryByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveryByIdQuery, GetDeliveryByIdQueryVariables>(GetDeliveryByIdDocument, options);
        }
export type GetDeliveryByIdQueryHookResult = ReturnType<typeof useGetDeliveryByIdQuery>;
export type GetDeliveryByIdLazyQueryHookResult = ReturnType<typeof useGetDeliveryByIdLazyQuery>;
export type GetDeliveryByIdQueryResult = Apollo.QueryResult<GetDeliveryByIdQuery, GetDeliveryByIdQueryVariables>;
export const GetVendorsDocument = gql`
    query GetVendors($clientId: uuid) {
  vendors(order_by: {createdAt: desc}, where: {clientId: {_eq: $clientId}}) {
    ...Vendor
  }
}
    ${VendorFragmentDoc}`;

/**
 * __useGetVendorsQuery__
 *
 * To run a query within a React component, call `useGetVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetVendorsQuery(baseOptions?: Apollo.QueryHookOptions<GetVendorsQuery, GetVendorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorsQuery, GetVendorsQueryVariables>(GetVendorsDocument, options);
      }
export function useGetVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorsQuery, GetVendorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorsQuery, GetVendorsQueryVariables>(GetVendorsDocument, options);
        }
export type GetVendorsQueryHookResult = ReturnType<typeof useGetVendorsQuery>;
export type GetVendorsLazyQueryHookResult = ReturnType<typeof useGetVendorsLazyQuery>;
export type GetVendorsQueryResult = Apollo.QueryResult<GetVendorsQuery, GetVendorsQueryVariables>;
export const GetCarriersDocument = gql`
    query GetCarriers($clientId: uuid) {
  carriers(order_by: {createdAt: desc}, where: {clientId: {_eq: $clientId}}) {
    ...Carrier
  }
}
    ${CarrierFragmentDoc}`;

/**
 * __useGetCarriersQuery__
 *
 * To run a query within a React component, call `useGetCarriersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarriersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarriersQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetCarriersQuery(baseOptions?: Apollo.QueryHookOptions<GetCarriersQuery, GetCarriersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCarriersQuery, GetCarriersQueryVariables>(GetCarriersDocument, options);
      }
export function useGetCarriersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCarriersQuery, GetCarriersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCarriersQuery, GetCarriersQueryVariables>(GetCarriersDocument, options);
        }
export type GetCarriersQueryHookResult = ReturnType<typeof useGetCarriersQuery>;
export type GetCarriersLazyQueryHookResult = ReturnType<typeof useGetCarriersLazyQuery>;
export type GetCarriersQueryResult = Apollo.QueryResult<GetCarriersQuery, GetCarriersQueryVariables>;
export const GetConsigneesDocument = gql`
    query GetConsignees($clientId: uuid) {
  consignees(order_by: {createdAt: desc}, where: {clientId: {_eq: $clientId}}) {
    ...Consignee
  }
}
    ${ConsigneeFragmentDoc}`;

/**
 * __useGetConsigneesQuery__
 *
 * To run a query within a React component, call `useGetConsigneesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsigneesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsigneesQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetConsigneesQuery(baseOptions?: Apollo.QueryHookOptions<GetConsigneesQuery, GetConsigneesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConsigneesQuery, GetConsigneesQueryVariables>(GetConsigneesDocument, options);
      }
export function useGetConsigneesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConsigneesQuery, GetConsigneesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConsigneesQuery, GetConsigneesQueryVariables>(GetConsigneesDocument, options);
        }
export type GetConsigneesQueryHookResult = ReturnType<typeof useGetConsigneesQuery>;
export type GetConsigneesLazyQueryHookResult = ReturnType<typeof useGetConsigneesLazyQuery>;
export type GetConsigneesQueryResult = Apollo.QueryResult<GetConsigneesQuery, GetConsigneesQueryVariables>;
export const GetVendorByIdDocument = gql`
    query GetVendorById($id: uuid!) {
  vendors_by_pk(id: $id) {
    ...Vendor
  }
}
    ${VendorFragmentDoc}`;

/**
 * __useGetVendorByIdQuery__
 *
 * To run a query within a React component, call `useGetVendorByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVendorByIdQuery(baseOptions: Apollo.QueryHookOptions<GetVendorByIdQuery, GetVendorByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorByIdQuery, GetVendorByIdQueryVariables>(GetVendorByIdDocument, options);
      }
export function useGetVendorByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorByIdQuery, GetVendorByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorByIdQuery, GetVendorByIdQueryVariables>(GetVendorByIdDocument, options);
        }
export type GetVendorByIdQueryHookResult = ReturnType<typeof useGetVendorByIdQuery>;
export type GetVendorByIdLazyQueryHookResult = ReturnType<typeof useGetVendorByIdLazyQuery>;
export type GetVendorByIdQueryResult = Apollo.QueryResult<GetVendorByIdQuery, GetVendorByIdQueryVariables>;
export const GetCarrierByIdDocument = gql`
    query GetCarrierById($id: uuid!) {
  carriers_by_pk(id: $id) {
    ...Carrier
  }
}
    ${CarrierFragmentDoc}`;

/**
 * __useGetCarrierByIdQuery__
 *
 * To run a query within a React component, call `useGetCarrierByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarrierByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarrierByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCarrierByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCarrierByIdQuery, GetCarrierByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCarrierByIdQuery, GetCarrierByIdQueryVariables>(GetCarrierByIdDocument, options);
      }
export function useGetCarrierByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCarrierByIdQuery, GetCarrierByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCarrierByIdQuery, GetCarrierByIdQueryVariables>(GetCarrierByIdDocument, options);
        }
export type GetCarrierByIdQueryHookResult = ReturnType<typeof useGetCarrierByIdQuery>;
export type GetCarrierByIdLazyQueryHookResult = ReturnType<typeof useGetCarrierByIdLazyQuery>;
export type GetCarrierByIdQueryResult = Apollo.QueryResult<GetCarrierByIdQuery, GetCarrierByIdQueryVariables>;
export const GetConsigneeByIdDocument = gql`
    query GetConsigneeById($id: uuid!) {
  consignees_by_pk(id: $id) {
    ...Consignee
  }
}
    ${ConsigneeFragmentDoc}`;

/**
 * __useGetConsigneeByIdQuery__
 *
 * To run a query within a React component, call `useGetConsigneeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsigneeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsigneeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConsigneeByIdQuery(baseOptions: Apollo.QueryHookOptions<GetConsigneeByIdQuery, GetConsigneeByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConsigneeByIdQuery, GetConsigneeByIdQueryVariables>(GetConsigneeByIdDocument, options);
      }
export function useGetConsigneeByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConsigneeByIdQuery, GetConsigneeByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConsigneeByIdQuery, GetConsigneeByIdQueryVariables>(GetConsigneeByIdDocument, options);
        }
export type GetConsigneeByIdQueryHookResult = ReturnType<typeof useGetConsigneeByIdQuery>;
export type GetConsigneeByIdLazyQueryHookResult = ReturnType<typeof useGetConsigneeByIdLazyQuery>;
export type GetConsigneeByIdQueryResult = Apollo.QueryResult<GetConsigneeByIdQuery, GetConsigneeByIdQueryVariables>;
export const GetTrucksDocument = gql`
    query GetTrucks($clientId: uuid!) {
  trucks(order_by: {createdAt: desc}, where: {clientId: {_eq: $clientId}}) {
    ...Truck
    carrier {
      name
    }
  }
}
    ${TruckFragmentDoc}`;

/**
 * __useGetTrucksQuery__
 *
 * To run a query within a React component, call `useGetTrucksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrucksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrucksQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetTrucksQuery(baseOptions: Apollo.QueryHookOptions<GetTrucksQuery, GetTrucksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrucksQuery, GetTrucksQueryVariables>(GetTrucksDocument, options);
      }
export function useGetTrucksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrucksQuery, GetTrucksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrucksQuery, GetTrucksQueryVariables>(GetTrucksDocument, options);
        }
export type GetTrucksQueryHookResult = ReturnType<typeof useGetTrucksQuery>;
export type GetTrucksLazyQueryHookResult = ReturnType<typeof useGetTrucksLazyQuery>;
export type GetTrucksQueryResult = Apollo.QueryResult<GetTrucksQuery, GetTrucksQueryVariables>;
export const GetTruckByIdDocument = gql`
    query GetTruckById($id: uuid!) {
  trucks_by_pk(id: $id) {
    ...Truck
    carrierId
    carrier {
      id
      name
    }
  }
}
    ${TruckFragmentDoc}`;

/**
 * __useGetTruckByIdQuery__
 *
 * To run a query within a React component, call `useGetTruckByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTruckByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTruckByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTruckByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTruckByIdQuery, GetTruckByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTruckByIdQuery, GetTruckByIdQueryVariables>(GetTruckByIdDocument, options);
      }
export function useGetTruckByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTruckByIdQuery, GetTruckByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTruckByIdQuery, GetTruckByIdQueryVariables>(GetTruckByIdDocument, options);
        }
export type GetTruckByIdQueryHookResult = ReturnType<typeof useGetTruckByIdQuery>;
export type GetTruckByIdLazyQueryHookResult = ReturnType<typeof useGetTruckByIdLazyQuery>;
export type GetTruckByIdQueryResult = Apollo.QueryResult<GetTruckByIdQuery, GetTruckByIdQueryVariables>;
export const GetTrucksByCarrierIdDocument = gql`
    query GetTrucksByCarrierId($carrierId: uuid!) {
  trucks(where: {carrierId: {_eq: $carrierId}}) {
    ...Truck
  }
}
    ${TruckFragmentDoc}`;

/**
 * __useGetTrucksByCarrierIdQuery__
 *
 * To run a query within a React component, call `useGetTrucksByCarrierIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrucksByCarrierIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrucksByCarrierIdQuery({
 *   variables: {
 *      carrierId: // value for 'carrierId'
 *   },
 * });
 */
export function useGetTrucksByCarrierIdQuery(baseOptions: Apollo.QueryHookOptions<GetTrucksByCarrierIdQuery, GetTrucksByCarrierIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrucksByCarrierIdQuery, GetTrucksByCarrierIdQueryVariables>(GetTrucksByCarrierIdDocument, options);
      }
export function useGetTrucksByCarrierIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrucksByCarrierIdQuery, GetTrucksByCarrierIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrucksByCarrierIdQuery, GetTrucksByCarrierIdQueryVariables>(GetTrucksByCarrierIdDocument, options);
        }
export type GetTrucksByCarrierIdQueryHookResult = ReturnType<typeof useGetTrucksByCarrierIdQuery>;
export type GetTrucksByCarrierIdLazyQueryHookResult = ReturnType<typeof useGetTrucksByCarrierIdLazyQuery>;
export type GetTrucksByCarrierIdQueryResult = Apollo.QueryResult<GetTrucksByCarrierIdQuery, GetTrucksByCarrierIdQueryVariables>;
export const GetProductTypesDocument = gql`
    query GetProductTypes($clientId: uuid) {
  productTypes(where: {client_id: {_eq: $clientId}}) {
    id
    name
    externalId
    createdAt
  }
}
    `;

/**
 * __useGetProductTypesQuery__
 *
 * To run a query within a React component, call `useGetProductTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductTypesQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetProductTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetProductTypesQuery, GetProductTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductTypesQuery, GetProductTypesQueryVariables>(GetProductTypesDocument, options);
      }
export function useGetProductTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductTypesQuery, GetProductTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductTypesQuery, GetProductTypesQueryVariables>(GetProductTypesDocument, options);
        }
export type GetProductTypesQueryHookResult = ReturnType<typeof useGetProductTypesQuery>;
export type GetProductTypesLazyQueryHookResult = ReturnType<typeof useGetProductTypesLazyQuery>;
export type GetProductTypesQueryResult = Apollo.QueryResult<GetProductTypesQuery, GetProductTypesQueryVariables>;
export const useClientFragment = (options:  { from: Apollo.StoreObject | Apollo.Reference } & Omit<Partial<Apollo.UseFragmentOptions<ClientFragment, Apollo.OperationVariables>>, 'from'>) => {
  return Apollo.useFragment<ClientFragment>({
    fragment: ClientFragmentDoc,
    fragmentName: "Client",
    ...options,
        from: {
        __typename: "clients",
        ...options.from
    },
});
}

export const useVendorFragment = (options:  { from: Apollo.StoreObject | Apollo.Reference } & Omit<Partial<Apollo.UseFragmentOptions<VendorFragment, Apollo.OperationVariables>>, 'from'>) => {
  return Apollo.useFragment<VendorFragment>({
    fragment: VendorFragmentDoc,
    fragmentName: "Vendor",
    ...options,
        from: {
        __typename: "vendors",
        ...options.from
    },
});
}

export const useCarrierFragment = (options:  { from: Apollo.StoreObject | Apollo.Reference } & Omit<Partial<Apollo.UseFragmentOptions<CarrierFragment, Apollo.OperationVariables>>, 'from'>) => {
  return Apollo.useFragment<CarrierFragment>({
    fragment: CarrierFragmentDoc,
    fragmentName: "Carrier",
    ...options,
        from: {
        __typename: "carriers",
        ...options.from
    },
});
}

export const useConsigneeFragment = (options:  { from: Apollo.StoreObject | Apollo.Reference } & Omit<Partial<Apollo.UseFragmentOptions<ConsigneeFragment, Apollo.OperationVariables>>, 'from'>) => {
  return Apollo.useFragment<ConsigneeFragment>({
    fragment: ConsigneeFragmentDoc,
    fragmentName: "Consignee",
    ...options,
        from: {
        __typename: "consignees",
        ...options.from
    },
});
}

export const useTruckFragment = (options:  { from: Apollo.StoreObject | Apollo.Reference } & Omit<Partial<Apollo.UseFragmentOptions<TruckFragment, Apollo.OperationVariables>>, 'from'>) => {
  return Apollo.useFragment<TruckFragment>({
    fragment: TruckFragmentDoc,
    fragmentName: "Truck",
    ...options,
        from: {
        __typename: "trucks",
        ...options.from
    },
});
}

export const useDeliveryFragment = (options:  { from: Apollo.StoreObject | Apollo.Reference } & Omit<Partial<Apollo.UseFragmentOptions<DeliveryFragment, Apollo.OperationVariables>>, 'from'>) => {
  return Apollo.useFragment<DeliveryFragment>({
    fragment: DeliveryFragmentDoc,
    fragmentName: "Delivery",
    ...options,
        from: {
        __typename: "deliveries",
        ...options.from
    },
});
}











































export const useGetClientsSuspenseQuery = (options?: Apollo.SuspenseQueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) => {
  return Apollo.useSuspenseQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
};

export const useGetClientsBackgroundQuery = (options?: Apollo.BackgroundQueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) => {
  return Apollo.useBackgroundQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
};

export type GetClientsSuspenseQueryHookResult = ReturnType<typeof useGetClientsSuspenseQuery>;
export type GetClientsBackgroundQueryHookResult = ReturnType<typeof useGetClientsBackgroundQuery>;


export const useGetClientByIdSuspenseQuery = (options?: Apollo.SuspenseQueryHookOptions<GetClientByIdQuery, GetClientByIdQueryVariables>) => {
  return Apollo.useSuspenseQuery<GetClientByIdQuery, GetClientByIdQueryVariables>(GetClientByIdDocument, options);
};

export const useGetClientByIdBackgroundQuery = (options?: Apollo.BackgroundQueryHookOptions<GetClientByIdQuery, GetClientByIdQueryVariables>) => {
  return Apollo.useBackgroundQuery<GetClientByIdQuery, GetClientByIdQueryVariables>(GetClientByIdDocument, options);
};

export type GetClientByIdSuspenseQueryHookResult = ReturnType<typeof useGetClientByIdSuspenseQuery>;
export type GetClientByIdBackgroundQueryHookResult = ReturnType<typeof useGetClientByIdBackgroundQuery>;


export const useGetDeliveriesSuspenseQuery = (options?: Apollo.SuspenseQueryHookOptions<GetDeliveriesQuery, GetDeliveriesQueryVariables>) => {
  return Apollo.useSuspenseQuery<GetDeliveriesQuery, GetDeliveriesQueryVariables>(GetDeliveriesDocument, options);
};

export const useGetDeliveriesBackgroundQuery = (options?: Apollo.BackgroundQueryHookOptions<GetDeliveriesQuery, GetDeliveriesQueryVariables>) => {
  return Apollo.useBackgroundQuery<GetDeliveriesQuery, GetDeliveriesQueryVariables>(GetDeliveriesDocument, options);
};

export type GetDeliveriesSuspenseQueryHookResult = ReturnType<typeof useGetDeliveriesSuspenseQuery>;
export type GetDeliveriesBackgroundQueryHookResult = ReturnType<typeof useGetDeliveriesBackgroundQuery>;


export const useGetDeliveryByIdSuspenseQuery = (options?: Apollo.SuspenseQueryHookOptions<GetDeliveryByIdQuery, GetDeliveryByIdQueryVariables>) => {
  return Apollo.useSuspenseQuery<GetDeliveryByIdQuery, GetDeliveryByIdQueryVariables>(GetDeliveryByIdDocument, options);
};

export const useGetDeliveryByIdBackgroundQuery = (options?: Apollo.BackgroundQueryHookOptions<GetDeliveryByIdQuery, GetDeliveryByIdQueryVariables>) => {
  return Apollo.useBackgroundQuery<GetDeliveryByIdQuery, GetDeliveryByIdQueryVariables>(GetDeliveryByIdDocument, options);
};

export type GetDeliveryByIdSuspenseQueryHookResult = ReturnType<typeof useGetDeliveryByIdSuspenseQuery>;
export type GetDeliveryByIdBackgroundQueryHookResult = ReturnType<typeof useGetDeliveryByIdBackgroundQuery>;


export const useGetVendorsSuspenseQuery = (options?: Apollo.SuspenseQueryHookOptions<GetVendorsQuery, GetVendorsQueryVariables>) => {
  return Apollo.useSuspenseQuery<GetVendorsQuery, GetVendorsQueryVariables>(GetVendorsDocument, options);
};

export const useGetVendorsBackgroundQuery = (options?: Apollo.BackgroundQueryHookOptions<GetVendorsQuery, GetVendorsQueryVariables>) => {
  return Apollo.useBackgroundQuery<GetVendorsQuery, GetVendorsQueryVariables>(GetVendorsDocument, options);
};

export type GetVendorsSuspenseQueryHookResult = ReturnType<typeof useGetVendorsSuspenseQuery>;
export type GetVendorsBackgroundQueryHookResult = ReturnType<typeof useGetVendorsBackgroundQuery>;


export const useGetCarriersSuspenseQuery = (options?: Apollo.SuspenseQueryHookOptions<GetCarriersQuery, GetCarriersQueryVariables>) => {
  return Apollo.useSuspenseQuery<GetCarriersQuery, GetCarriersQueryVariables>(GetCarriersDocument, options);
};

export const useGetCarriersBackgroundQuery = (options?: Apollo.BackgroundQueryHookOptions<GetCarriersQuery, GetCarriersQueryVariables>) => {
  return Apollo.useBackgroundQuery<GetCarriersQuery, GetCarriersQueryVariables>(GetCarriersDocument, options);
};

export type GetCarriersSuspenseQueryHookResult = ReturnType<typeof useGetCarriersSuspenseQuery>;
export type GetCarriersBackgroundQueryHookResult = ReturnType<typeof useGetCarriersBackgroundQuery>;


export const useGetConsigneesSuspenseQuery = (options?: Apollo.SuspenseQueryHookOptions<GetConsigneesQuery, GetConsigneesQueryVariables>) => {
  return Apollo.useSuspenseQuery<GetConsigneesQuery, GetConsigneesQueryVariables>(GetConsigneesDocument, options);
};

export const useGetConsigneesBackgroundQuery = (options?: Apollo.BackgroundQueryHookOptions<GetConsigneesQuery, GetConsigneesQueryVariables>) => {
  return Apollo.useBackgroundQuery<GetConsigneesQuery, GetConsigneesQueryVariables>(GetConsigneesDocument, options);
};

export type GetConsigneesSuspenseQueryHookResult = ReturnType<typeof useGetConsigneesSuspenseQuery>;
export type GetConsigneesBackgroundQueryHookResult = ReturnType<typeof useGetConsigneesBackgroundQuery>;


export const useGetVendorByIdSuspenseQuery = (options?: Apollo.SuspenseQueryHookOptions<GetVendorByIdQuery, GetVendorByIdQueryVariables>) => {
  return Apollo.useSuspenseQuery<GetVendorByIdQuery, GetVendorByIdQueryVariables>(GetVendorByIdDocument, options);
};

export const useGetVendorByIdBackgroundQuery = (options?: Apollo.BackgroundQueryHookOptions<GetVendorByIdQuery, GetVendorByIdQueryVariables>) => {
  return Apollo.useBackgroundQuery<GetVendorByIdQuery, GetVendorByIdQueryVariables>(GetVendorByIdDocument, options);
};

export type GetVendorByIdSuspenseQueryHookResult = ReturnType<typeof useGetVendorByIdSuspenseQuery>;
export type GetVendorByIdBackgroundQueryHookResult = ReturnType<typeof useGetVendorByIdBackgroundQuery>;


export const useGetCarrierByIdSuspenseQuery = (options?: Apollo.SuspenseQueryHookOptions<GetCarrierByIdQuery, GetCarrierByIdQueryVariables>) => {
  return Apollo.useSuspenseQuery<GetCarrierByIdQuery, GetCarrierByIdQueryVariables>(GetCarrierByIdDocument, options);
};

export const useGetCarrierByIdBackgroundQuery = (options?: Apollo.BackgroundQueryHookOptions<GetCarrierByIdQuery, GetCarrierByIdQueryVariables>) => {
  return Apollo.useBackgroundQuery<GetCarrierByIdQuery, GetCarrierByIdQueryVariables>(GetCarrierByIdDocument, options);
};

export type GetCarrierByIdSuspenseQueryHookResult = ReturnType<typeof useGetCarrierByIdSuspenseQuery>;
export type GetCarrierByIdBackgroundQueryHookResult = ReturnType<typeof useGetCarrierByIdBackgroundQuery>;


export const useGetConsigneeByIdSuspenseQuery = (options?: Apollo.SuspenseQueryHookOptions<GetConsigneeByIdQuery, GetConsigneeByIdQueryVariables>) => {
  return Apollo.useSuspenseQuery<GetConsigneeByIdQuery, GetConsigneeByIdQueryVariables>(GetConsigneeByIdDocument, options);
};

export const useGetConsigneeByIdBackgroundQuery = (options?: Apollo.BackgroundQueryHookOptions<GetConsigneeByIdQuery, GetConsigneeByIdQueryVariables>) => {
  return Apollo.useBackgroundQuery<GetConsigneeByIdQuery, GetConsigneeByIdQueryVariables>(GetConsigneeByIdDocument, options);
};

export type GetConsigneeByIdSuspenseQueryHookResult = ReturnType<typeof useGetConsigneeByIdSuspenseQuery>;
export type GetConsigneeByIdBackgroundQueryHookResult = ReturnType<typeof useGetConsigneeByIdBackgroundQuery>;


export const useGetTrucksSuspenseQuery = (options?: Apollo.SuspenseQueryHookOptions<GetTrucksQuery, GetTrucksQueryVariables>) => {
  return Apollo.useSuspenseQuery<GetTrucksQuery, GetTrucksQueryVariables>(GetTrucksDocument, options);
};

export const useGetTrucksBackgroundQuery = (options?: Apollo.BackgroundQueryHookOptions<GetTrucksQuery, GetTrucksQueryVariables>) => {
  return Apollo.useBackgroundQuery<GetTrucksQuery, GetTrucksQueryVariables>(GetTrucksDocument, options);
};

export type GetTrucksSuspenseQueryHookResult = ReturnType<typeof useGetTrucksSuspenseQuery>;
export type GetTrucksBackgroundQueryHookResult = ReturnType<typeof useGetTrucksBackgroundQuery>;


export const useGetTruckByIdSuspenseQuery = (options?: Apollo.SuspenseQueryHookOptions<GetTruckByIdQuery, GetTruckByIdQueryVariables>) => {
  return Apollo.useSuspenseQuery<GetTruckByIdQuery, GetTruckByIdQueryVariables>(GetTruckByIdDocument, options);
};

export const useGetTruckByIdBackgroundQuery = (options?: Apollo.BackgroundQueryHookOptions<GetTruckByIdQuery, GetTruckByIdQueryVariables>) => {
  return Apollo.useBackgroundQuery<GetTruckByIdQuery, GetTruckByIdQueryVariables>(GetTruckByIdDocument, options);
};

export type GetTruckByIdSuspenseQueryHookResult = ReturnType<typeof useGetTruckByIdSuspenseQuery>;
export type GetTruckByIdBackgroundQueryHookResult = ReturnType<typeof useGetTruckByIdBackgroundQuery>;


export const useGetTrucksByCarrierIdSuspenseQuery = (options?: Apollo.SuspenseQueryHookOptions<GetTrucksByCarrierIdQuery, GetTrucksByCarrierIdQueryVariables>) => {
  return Apollo.useSuspenseQuery<GetTrucksByCarrierIdQuery, GetTrucksByCarrierIdQueryVariables>(GetTrucksByCarrierIdDocument, options);
};

export const useGetTrucksByCarrierIdBackgroundQuery = (options?: Apollo.BackgroundQueryHookOptions<GetTrucksByCarrierIdQuery, GetTrucksByCarrierIdQueryVariables>) => {
  return Apollo.useBackgroundQuery<GetTrucksByCarrierIdQuery, GetTrucksByCarrierIdQueryVariables>(GetTrucksByCarrierIdDocument, options);
};

export type GetTrucksByCarrierIdSuspenseQueryHookResult = ReturnType<typeof useGetTrucksByCarrierIdSuspenseQuery>;
export type GetTrucksByCarrierIdBackgroundQueryHookResult = ReturnType<typeof useGetTrucksByCarrierIdBackgroundQuery>;


export const useGetProductTypesSuspenseQuery = (options?: Apollo.SuspenseQueryHookOptions<GetProductTypesQuery, GetProductTypesQueryVariables>) => {
  return Apollo.useSuspenseQuery<GetProductTypesQuery, GetProductTypesQueryVariables>(GetProductTypesDocument, options);
};

export const useGetProductTypesBackgroundQuery = (options?: Apollo.BackgroundQueryHookOptions<GetProductTypesQuery, GetProductTypesQueryVariables>) => {
  return Apollo.useBackgroundQuery<GetProductTypesQuery, GetProductTypesQueryVariables>(GetProductTypesDocument, options);
};

export type GetProductTypesSuspenseQueryHookResult = ReturnType<typeof useGetProductTypesSuspenseQuery>;
export type GetProductTypesBackgroundQueryHookResult = ReturnType<typeof useGetProductTypesBackgroundQuery>;

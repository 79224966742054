import { useParams } from "react-router-dom";
import { PageContainer } from "@ant-design/pro-components";

import { useGetTruckByIdSuspenseQuery } from "graphql-api";

import { TruckForm } from "components";

export const EditTruckPage = () => {
  let { id } = useParams();
  const { data } = useGetTruckByIdSuspenseQuery({ variables: { id } });

  return (
    <PageContainer>
      <TruckForm isEditing initialValues={data.trucks_by_pk ?? undefined} />
    </PageContainer>
  );
};

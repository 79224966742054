import { useCallback, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ApolloProvider } from "@apollo/client";
import { ConfigProvider } from "antd";
import type { Locale } from "antd/es/locale";
import en_GB from "antd/lib/locale/en_GB";
import de_DE from "antd/lib/locale/de_DE";

import { apolloClient } from "config";
import { appTheme } from "theme";
import { AppRouter } from "routes";
import { AuthProvider, NotificationProvider } from "context";
import { Languages } from "constant";

const App = () => {
  const { i18n } = useTranslation();

  const [locale, setLocale] = useState<Locale>(de_DE);

  const handleLanguageChanged = useCallback((lng: string) => {
    if (lng === Languages.DE) {
      setLocale(de_DE);
    } else {
      setLocale(en_GB);
    }
  }, []);

  useEffect(() => {
    i18n.on("languageChanged", handleLanguageChanged);
    return () => {
      i18n.off("languageChanged", handleLanguageChanged);
    };
  }, [handleLanguageChanged, i18n]);

  return (
    <ApolloProvider client={apolloClient}>
      <ConfigProvider theme={appTheme} locale={locale}>
        <AuthProvider>
          <NotificationProvider>
            <BrowserRouter>
              <AppRouter />
            </BrowserRouter>
          </NotificationProvider>
        </AuthProvider>
      </ConfigProvider>
    </ApolloProvider>
  );
};

export default App;

import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { PageContainer } from "@ant-design/pro-components";
import dayjs from "dayjs";

import { useGetDeliveryByIdSuspenseQuery, useUpdateDeliveryMutation } from "graphql-api";

import { useNotificationContext } from "context";
import { DeliveryForm } from "components";
import { CreateEditDelivery } from "interfaces";
import { RoutePath } from "constant";

export const EditDeliveryPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useNotificationContext();
  let { id } = useParams();

  const { data } = useGetDeliveryByIdSuspenseQuery({ variables: { id } });
  const [updateDeliveryMutation] = useUpdateDeliveryMutation();

  const parsedData = useMemo(() => {
    if (data.deliveries_by_pk) {
      const { timeLocal, truck, rides } = data.deliveries_by_pk;

      // Get gid of specific GraphQL fields.
      return {
        ...data.deliveries_by_pk,
        carrierId: truck!.carrierId,
        timeLocal: dayjs(timeLocal),
        rides: rides.map(ride => {
          const { __typename, ...rest } = ride;

          return {
            ...rest,
            timeLocal: dayjs(timeLocal),
            rideToDestination: !!ride.consigneeId,
            products: rest.products.map(product => {
              const { __typename, ...rest } = product;

              return rest;
            }),
          };
        }),
      } as CreateEditDelivery;
    }
  }, [data.deliveries_by_pk]);

  const handleFinish = useCallback(
    (values: CreateEditDelivery, rideIdsToDelete: string[], productIdsToDelete: string[]) => {
      const { rides, carrierId, truck, ...rest } = values;
      const products = rides.flatMap(ride => ride.products);

      // To be sure that delivery has at least one ride to vendor and has ride to destination.
      if (rides.length >= 2 && rides[rides.length - 1].rideToDestination) {
        updateDeliveryMutation({
          variables: {
            id,
            delivery: {
              ...rest,
              truckId: truck!.id,
            },
            // Update existing rides
            rideUpdates: rides
              .filter(ride => !ride.createdInEditMode)
              .map(ride => {
                const { rideToDestination, products, ...rest } = ride;
                return { where: { id: { _eq: rest.id } }, _set: rest };
              }),
            // Update existing products
            productUpdates: products
              .filter(product => !product.createdInEditMode)
              .map(product => ({ where: { id: { _eq: product.id } }, _set: product })),
            // Insert new rides created in edit mode
            ridesInsert: rides
              .filter(ride => ride.createdInEditMode)
              .map(ride => {
                const { rideToDestination, createdInEditMode, products, ...rest } = ride;

                return { ...rest, deliveryId: id };
              }),
            // Insert new products created in edit mode
            productsInsert: products
              .filter(product => product.createdInEditMode)
              .map(product => {
                const { createdInEditMode, ...rest } = product;

                return rest;
              }),
            rideIdsToDelete,
            productIdsToDelete,
          },
          onCompleted: () => {
            notifySuccess(t("notifications.createSuccess", { entity: t("common.delivery") }));
            navigate(RoutePath.Deliveries);
          },
          onError(error) {
            notifyError(t("notifications.graphqlError"), error.message);
          },
        });
      } else {
        notifyError(t("notifications.graphqlError"), t("notifications.emptyRides"));
      }
    },
    [id, navigate, notifyError, notifySuccess, t, updateDeliveryMutation]
  );

  return (
    <PageContainer>
      <DeliveryForm initialValues={parsedData} handleFinish={handleFinish} isEditing />
    </PageContainer>
  );
};

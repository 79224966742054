import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Divider, Flex, Layout, Menu } from "antd";
import type { MenuProps } from "antd";

import { DashboardIcon, UserIcon, SettingsIcon, DeliveryIcon, CompanyIcon, TruckIcon } from "assets";
import { Colors } from "theme";
import { RoutePath, MenuItems, UserRole } from "constant";
import { getCurrentRoute } from "utils";
import { useAuthContext } from "context";
import LogoIcon from "assets/icons/logo.svg";
import CompanyLogoImage from "assets/images/company-logo.svg";

type MenuItem = Required<MenuProps>["items"][number];

export const AppSider = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  let location = useLocation();
  const { userRole } = useAuthContext();
  const [collapsed, setCollapsed] = useState(false);

  const defineSelectedItemKey = () => {
    switch (getCurrentRoute(location.pathname)) {
      case RoutePath.Companies:
      case RoutePath.CreateCompany:
      case RoutePath.EditCompany:
        return MenuItems.Companies;
      case RoutePath.Deliveries:
      case RoutePath.CreateDelivery:
      case RoutePath.EditDelivery:
        return MenuItems.Deliveries;
      case RoutePath.Users:
      case RoutePath.CreateUser:
      case RoutePath.EditUser:
        return MenuItems.Users;
      case RoutePath.Trucks:
      case RoutePath.CreateTruck:
      case RoutePath.EditTruck:
        return MenuItems.Trucks;
      case RoutePath.Settings:
        return MenuItems.Settings;
      default:
        return MenuItems.Dashboard;
    }
  };

  const [selectedItemKey, setSelectedItemKey] = useState(defineSelectedItemKey());

  /** Available for all users. */
  const dashboardItem: MenuItem = {
    label: (
      <Link to={RoutePath.Main}>
        <span className={classes.menuItemFont}>{t("navigation.dashboard")}</span>
      </Link>
    ),
    key: MenuItems.Dashboard,
    icon: <DashboardIcon />,
    style: {
      marginBottom: 20,
      borderLeft: selectedItemKey === MenuItems.Dashboard ? `2px solid ${Colors.Cyprus}` : "none",
    },
  };

  const adminItems: MenuItem[] = [
    {
      label: (
        <Link to={RoutePath.Companies}>
          <span className={classes.menuItemFont}>{t("common.company")}</span>
        </Link>
      ),
      key: MenuItems.Companies,
      icon: <CompanyIcon />,
      style: {
        marginBottom: 20,
        borderLeft: selectedItemKey === MenuItems.Companies ? `2px solid ${Colors.Cyprus}` : "none",
      },
    },
  ];

  const clientItems: MenuItem[] = [
    {
      label: (
        <Link to={RoutePath.Deliveries}>
          <span className={classes.menuItemFont}>{t("navigation.deliveries")}</span>
        </Link>
      ),
      key: MenuItems.Deliveries,
      icon: <DeliveryIcon />,
      style: {
        marginBottom: 20,
        borderLeft: selectedItemKey === MenuItems.Deliveries ? `2px solid ${Colors.Cyprus}` : "none",
      },
    },
    {
      label: (
        <Link to={RoutePath.Users}>
          <span className={classes.menuItemFont}>{t("navigation.users")}</span>
        </Link>
      ),
      key: MenuItems.Users,
      icon: <UserIcon />,
      style: {
        marginBottom: 20,
        borderLeft: selectedItemKey === MenuItems.Users ? `2px solid ${Colors.Cyprus}` : "none",
      },
    },
    {
      label: (
        <Link to={RoutePath.Trucks}>
          <span className={classes.menuItemFont}>{t("navigation.trucks")}</span>
        </Link>
      ),
      key: MenuItems.Trucks,
      icon: <TruckIcon />,
      style: {
        marginBottom: 20,
        borderLeft: selectedItemKey === MenuItems.Trucks ? `2px solid ${Colors.Cyprus}` : "none",
      },
    },
    {
      label: (
        <Link to={RoutePath.Settings}>
          <span className={classes.menuItemFont}>{t("navigation.settings")}</span>
        </Link>
      ),
      key: MenuItems.Settings,
      icon: <SettingsIcon />,
      style: {
        marginBottom: 20,
        borderLeft: selectedItemKey === MenuItems.Settings ? `2px solid ${Colors.Cyprus}` : "none",
      },
    },
  ];

  const defineItemsToRender = () => {
    if (userRole === UserRole.ADMIN) {
      return [dashboardItem, ...adminItems];
    }
    if (userRole === UserRole.CLIENT) {
      return [dashboardItem, ...clientItems];
    }

    return [];
  };

  const handleMenuSelect: Required<MenuProps>["onSelect"] = info => {
    setSelectedItemKey(+info.key);
  };

  return (
    <Layout.Sider
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={value => setCollapsed(value)}
      className={classes.sider}
      width={250}
    >
      <Flex align="center" justify="center">
        <img
          src={collapsed ? LogoIcon : CompanyLogoImage}
          alt="trace.way"
          width={200}
          height={40}
          className={classes.image}
        />
      </Flex>
      <Divider className={classes.divider} />
      <Menu mode="inline" theme="light" items={defineItemsToRender()} onSelect={handleMenuSelect} />
    </Layout.Sider>
  );
};

const useStyles = createUseStyles({
  sider: {
    borderRight: `1px solid ${Colors.LightGray}`,
  },
  image: {
    margin: "30px 25px",
  },
  divider: {
    width: "unset",
    minWidth: "unset",
    backgroundColor: Colors.Silver,
    margin: "0px 20px 25px 20px",
  },
  menuItemFont: {
    fontSize: 22,
  },
});

import { useLocation } from "react-router-dom";
import { PageContainer } from "@ant-design/pro-components";

import { UserCard } from "components";
import { UrlSearchParams } from "constant";

export const CreateUserPage = () => {
  const location = useLocation();

  return (
    <PageContainer>
      <UserCard user={new URLSearchParams(location.search).get(UrlSearchParams.User)!} />
    </PageContainer>
  );
};

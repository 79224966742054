export enum Colors {
  Cyprus = "#0A654D",
  Black = "#202125",
  Grey = "#A7A9B7",
  LightGray = "#F3F3F3",
  White = "#FFFFFF",
  Silver = "#DFE2EB",
  Aluminium = "#83898E",
  IceBlue = "#F8F9FB",
  Red = "#D50000",
}

import { useLocation, useParams } from "react-router-dom";
import { PageContainer } from "@ant-design/pro-components";
import { skipToken } from "@apollo/client";

import {
  useGetVendorByIdSuspenseQuery,
  useGetCarrierByIdSuspenseQuery,
  useGetConsigneeByIdSuspenseQuery,
} from "graphql-api";

import { UserCard } from "components";
import { ClientUsers, UrlSearchParams } from "constant";

export const EditUserPage = () => {
  const location = useLocation();
  const userType = new URLSearchParams(location.search).get(UrlSearchParams.User);
  let { id } = useParams();

  /** https://www.apollographql.com/docs/react/data/suspense/#skipping-suspense-hooks */
  const { data: vendor } = useGetVendorByIdSuspenseQuery(
    userType === ClientUsers.Vendor ? { variables: { id } } : (skipToken as any)
  );
  const { data: carrier } = useGetCarrierByIdSuspenseQuery(
    userType === ClientUsers.Carrier ? { variables: { id } } : (skipToken as any)
  );
  const { data: consignee } = useGetConsigneeByIdSuspenseQuery(
    userType === ClientUsers.Consignee ? { variables: { id } } : (skipToken as any)
  );

  const defineDataToDisplay = () => {
    switch (userType) {
      case ClientUsers.Vendor:
        return vendor?.vendors_by_pk;
      case ClientUsers.Carrier:
        return carrier?.carriers_by_pk;
      case ClientUsers.Consignee:
        return consignee?.consignees_by_pk;
      default:
        return undefined;
    }
  };

  return (
    <PageContainer>
      <UserCard user={userType!} isEditing initialValues={defineDataToDisplay() ?? undefined} />
    </PageContainer>
  );
};

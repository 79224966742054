import { FC } from "react";
import { Tooltip } from "antd";

import { Colors } from "theme";
import { truncate } from "utils";

interface Props {
  value: string;
  textLength?: number;
}

const DEFAULT_TEXT_LENGTH = 13;

export const TruncatedText: FC<Props> = props => {
  const { value, textLength } = props;
  return (
    <Tooltip
      placement="topLeft"
      title={value}
      mouseEnterDelay={0.7}
      color={Colors.White}
      overlayInnerStyle={{ color: Colors.Black }}
    >
      {truncate(value, textLength ?? DEFAULT_TEXT_LENGTH)}
    </Tooltip>
  );
};

import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, DatePicker, Form, Row, Select } from "antd";
import { v4 as uuid } from "uuid";

import { RideStatus_Enum, useGetVendorsSuspenseQuery, useGetConsigneesSuspenseQuery } from "graphql-api";

import { useAuthContext } from "context";
import { DeleteIcon, PlusIcon } from "assets";
import { ProductsFormList } from "components";

interface Props {
  isEditing?: boolean;
  saveRideToDelete: (rideId: string) => void;
  saveProductsToDelete: (productId: string) => void;
}

export const RideFormList: FC<Props> = props => {
  const { isEditing, saveRideToDelete, saveProductsToDelete } = props;
  const { t } = useTranslation();
  const { clientId } = useAuthContext();
  const form = Form.useFormInstance();

  const { data: vendors } = useGetVendorsSuspenseQuery({ variables: { clientId } });
  const { data: consignees } = useGetConsigneesSuspenseQuery({ variables: { clientId } });

  const [isRideToDestinationAdded, setIsRideToDestinationAdded] = useState(false);

  const handleAddRide = (rideToDestination: boolean, callback: (defaultValue?: any) => void) => {
    if (rideToDestination) {
      setIsRideToDestinationAdded(true);
    }

    const rideId = uuid();

    // Creates new ride with default values and initial product card.
    callback({
      id: rideId,
      status: RideStatus_Enum.Pending,
      rideToDestination,
      createdInEditMode: isEditing,
      products: rideToDestination ? [] : [{ id: uuid(), rideId, createdInEditMode: isEditing }],
    });
  };

  const handleDeleteRide = (rideToDestination: boolean, rideId: string, callback: () => void) => {
    if (rideToDestination) {
      setIsRideToDestinationAdded(false);
    }
    if (isEditing) {
      saveRideToDelete(rideId);
    }
    callback();
  };

  useEffect(() => {
    if (isEditing) {
      setIsRideToDestinationAdded(true);
    }
  }, [isEditing]);

  return (
    <Form.List name="rides">
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => {
            const ride = form.getFieldValue("rides")[index];

            return (
              <Card
                key={field.key}
                bordered={false}
                title={
                  ride.rideToDestination
                    ? t("components.rideFormList.destinationRide")
                    : t("components.rideFormList.ride")
                }
                style={{ marginBottom: "16px" }}
                extra={
                  <Button
                    type="text"
                    icon={<DeleteIcon />}
                    onClick={() => handleDeleteRide(ride.rideToDestination, ride.id, () => remove(index))}
                  />
                }
              >
                <Row gutter={[16, 16]}>
                  <Col flex={1} span={12}>
                    <Form.Item
                      label={
                        ride.rideToDestination ? t("form.label.unloadingTime") : t("form.label.loadingTime")
                      }
                      name={[index, "timeLocal"]}
                      rules={[{ required: true }]}
                    >
                      <DatePicker
                        placeholder={
                          ride.rideToDestination
                            ? t("form.placeholder.unloadingTime")
                            : t("form.placeholder.loadingTime")
                        }
                        showTime
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col flex={1} span={12}>
                    {ride.rideToDestination ? (
                      <Form.Item
                        label={t("users.consignee")}
                        name={[index, "consigneeId"]}
                        rules={[{ required: true }]}
                      >
                        <Select
                          placeholder={t("form.placeholder.consignee")}
                          options={consignees.consignees.map(consignee => ({
                            label: consignee.name,
                            value: consignee.id,
                          }))}
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        label={t("users.vendor")}
                        name={[index, "vendorId"]}
                        rules={[{ required: true }]}
                      >
                        <Select
                          placeholder={t("form.placeholder.vendor")}
                          options={vendors.vendors.map(vendors => ({
                            label: vendors.name,
                            value: vendors.id,
                          }))}
                        />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
                {!ride.rideToDestination && (
                  <ProductsFormList
                    rideIndex={index}
                    rideId={ride.id}
                    isEditing={isEditing}
                    saveProductsToDelete={saveProductsToDelete}
                  />
                )}
              </Card>
            );
          })}
          <Row gutter={[16, 16]} justify="end" wrap>
            <Col>
              <Button
                type="primary"
                icon={<PlusIcon />}
                disabled={isRideToDestinationAdded}
                onClick={() => handleAddRide(false, (defaultValue?: any) => add(defaultValue))}
              >
                {t("button.addRide")}
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<PlusIcon />}
                disabled={isRideToDestinationAdded}
                onClick={() => handleAddRide(true, (defaultValue?: any) => add(defaultValue))}
              >
                {t("button.addRideToDestination")}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Form.List>
  );
};

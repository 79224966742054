import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Form, Input, Row } from "antd";
import { DefaultOptionType } from "antd/es/select";

import {
  useCreateVendorMutation,
  useConnectVendorWithKeycloakMutation,
  Vendors_Insert_Input,
  useCreateCarrierMutation,
  useConnectCarrierWithKeycloakMutation,
  Carriers_Insert_Input,
  useCreateConsigneeMutation,
  useConnectConsigneeWithKeycloakMutation,
  Consignees_Insert_Input,
  GetVendorByIdQuery,
  GetCarrierByIdQuery,
  GetConsigneeByIdQuery,
  useUpdateVendorMutation,
  useUpdateCarrierMutation,
  useUpdateConsigneeMutation,
} from "graphql-api";
import { IApiException } from "rest-api";

import { useKeycloakCLI } from "hooks";
import { ClientUsers, RoutePath } from "constant";
import { useAuthContext, useNotificationContext } from "context";
import { Loader, SelectAddress } from "components";
import { parsePositionToPoint } from "utils";

interface Props {
  initialValues?:
    | GetVendorByIdQuery["vendors_by_pk"]
    | GetCarrierByIdQuery["carriers_by_pk"]
    | GetConsigneeByIdQuery["consignees_by_pk"];
  isEditing?: boolean;
  user: string;
}

export const UserCard: FC<Props> = props => {
  const { isEditing, user, initialValues } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { isLoading, getKeycloakRoles, createNewUser } = useKeycloakCLI();
  const { notifySuccess, notifyError } = useNotificationContext();
  const { clientId } = useAuthContext();
  const [isRequestsProcessing, setIsRequestsProcessing] = useState(false);

  const [createVendorMutation] = useCreateVendorMutation();
  const [connectVendorWithKeycloakMutation] = useConnectVendorWithKeycloakMutation();

  const [createCarrierMutation] = useCreateCarrierMutation();
  const [connectCarrierWithKeycloakMutation] = useConnectCarrierWithKeycloakMutation();

  const [createConsigneeMutation] = useCreateConsigneeMutation();
  const [connectConsigneeWithKeycloakMutation] = useConnectConsigneeWithKeycloakMutation();

  const [updateVendorMutation] = useUpdateVendorMutation();
  const [updateCarrierMutation] = useUpdateCarrierMutation();
  const [updateConsigneeMutation] = useUpdateConsigneeMutation();

  const submitNewUser = (values: any) => {
    setIsRequestsProcessing(true);

    if (user === ClientUsers.Vendor) {
      const request = values as Vendors_Insert_Input;

      createNewUser(request, ClientUsers.Vendor).then(
        (userId: string) => {
          createVendorMutation({
            variables: { data: { ...request, clientId } },
            onCompleted: data => {
              const vendorId = data.insert_vendors_one?.id;

              connectVendorWithKeycloakMutation({
                variables: { data: { userId, vendorId } },
                onCompleted: () => {
                  setIsRequestsProcessing(false);
                  notifySuccess(t("notifications.createSuccess", { entity: t("users.vendor") }));
                  navigate({
                    pathname: RoutePath.Users,
                    hash: ClientUsers.Vendor,
                  });
                },
                onError(error) {
                  setIsRequestsProcessing(false);
                  notifyError(t("notifications.graphqlError"), error.message);
                },
              });
            },
            onError(error) {
              setIsRequestsProcessing(false);
              notifyError(t("notifications.graphqlError"), error.message);
            },
          });
        },
        (error: IApiException) => {
          const { status } = error;

          setIsRequestsProcessing(false);
          notifyError(
            t("notifications.error", { statusCode: status }),
            t("notifications.createError", { entity: t("users.vendor"), statusCode: status })
          );
        }
      );
    }

    if (user === ClientUsers.Carrier) {
      const request = values as Carriers_Insert_Input;

      createNewUser(request, ClientUsers.Carrier).then(
        (userId: string) => {
          createCarrierMutation({
            variables: { data: { ...request, clientId } },
            onCompleted: data => {
              const carrierId = data.insert_carriers_one?.id;

              connectCarrierWithKeycloakMutation({
                variables: { data: { userId, carrierId } },
                onCompleted: () => {
                  setIsRequestsProcessing(false);
                  notifySuccess(t("notifications.createSuccess", { entity: t("users.carrier") }));
                  navigate({
                    pathname: RoutePath.Users,
                    hash: ClientUsers.Carrier,
                  });
                },
                onError(error) {
                  setIsRequestsProcessing(false);
                  notifyError(t("notifications.graphqlError"), error.message);
                },
              });
            },
            onError(error) {
              setIsRequestsProcessing(false);
              notifyError(t("notifications.graphqlError"), error.message);
            },
          });
        },
        (error: IApiException) => {
          const { status } = error;

          setIsRequestsProcessing(false);
          notifyError(
            t("notifications.error", { statusCode: status }),
            t("notifications.createError", { entity: t("users.carrier"), statusCode: status })
          );
        }
      );
    }

    if (user === ClientUsers.Consignee) {
      const request = values as Consignees_Insert_Input;

      createNewUser(request, ClientUsers.Consignee).then(
        (userId: string) => {
          createConsigneeMutation({
            variables: { data: { ...request, clientId } },
            onCompleted: data => {
              const consigneeId = data.insert_consignees_one?.id;

              connectConsigneeWithKeycloakMutation({
                variables: { data: { userId, consigneeId } },
                onCompleted: () => {
                  setIsRequestsProcessing(false);
                  notifySuccess(t("notifications.createSuccess", { entity: t("users.consignee") }));
                  navigate({
                    pathname: RoutePath.Users,
                    hash: ClientUsers.Consignee,
                  });
                },
                onError(error) {
                  setIsRequestsProcessing(false);
                  notifyError(t("notifications.graphqlError"), error.message);
                },
              });
            },
            onError(error) {
              setIsRequestsProcessing(false);
              notifyError(t("notifications.graphqlError"), error.message);
            },
          });
        },
        (error: IApiException) => {
          const { status } = error;

          setIsRequestsProcessing(false);
          notifyError(
            t("notifications.error", { statusCode: status }),
            t("notifications.createError", { entity: t("users.consignee"), statusCode: status })
          );
        }
      );
    }
  };

  const updateExistingUser = (values: any) => {
    const { email, ...rest } = values;
    setIsRequestsProcessing(true);

    if (user === ClientUsers.Vendor) {
      const request = rest as Vendors_Insert_Input;

      updateVendorMutation({
        variables: { id: initialValues?.id, data: request },
        onCompleted: () => {
          setIsRequestsProcessing(false);
          notifySuccess(t("notifications.editSuccess", { entity: t("users.vendor") }));
          navigate({
            pathname: RoutePath.Users,
            hash: ClientUsers.Vendor,
          });
        },
        onError(error) {
          setIsRequestsProcessing(false);
          notifyError(t("notifications.graphqlError"), error.message);
        },
      });
    }

    if (user === ClientUsers.Carrier) {
      const request = rest as Carriers_Insert_Input;

      updateCarrierMutation({
        variables: { id: initialValues?.id, data: request },
        onCompleted: () => {
          setIsRequestsProcessing(false);
          notifySuccess(t("notifications.editSuccess", { entity: t("users.carrier") }));
          navigate({
            pathname: RoutePath.Users,
            hash: ClientUsers.Carrier,
          });
        },
        onError(error) {
          setIsRequestsProcessing(false);
          notifyError(t("notifications.graphqlError"), error.message);
        },
      });
    }

    if (user === ClientUsers.Consignee) {
      const request = rest as Consignees_Insert_Input;

      updateConsigneeMutation({
        variables: { id: initialValues?.id, data: request },
        onCompleted: () => {
          setIsRequestsProcessing(false);
          notifySuccess(t("notifications.editSuccess", { entity: t("users.consignee") }));
          navigate({
            pathname: RoutePath.Users,
            hash: ClientUsers.Consignee,
          });
        },
        onError(error) {
          setIsRequestsProcessing(false);
          notifyError(t("notifications.graphqlError"), error.message);
        },
      });
    }
  };

  const handleSubmit = (values: any) => {
    if (!isEditing) {
      submitNewUser(values);
    } else {
      updateExistingUser(values);
    }
  };

  /** Applies coordinates from geocoding API for selected address. */
  const handleSelectAddress = (value: string, option: DefaultOptionType) => {
    form.setFieldValue("coordinate", parsePositionToPoint(option.feature.center));
  };

  /** Fetch Keycloak roles to create user only. */
  useEffect(() => {
    if (!isEditing) {
      getKeycloakRoles();
    }
  }, [getKeycloakRoles, isEditing]);

  if (isRequestsProcessing) {
    return <Loader />;
  }

  return (
    <Card bordered={false} loading={isLoading} title={t(`users.${user}`)} style={{ maxWidth: "500px" }}>
      <Form form={form} layout="vertical" onFinish={handleSubmit} initialValues={initialValues as any}>
        <Form.Item label={t("form.label.name")} name="name" rules={[{ required: true }]}>
          <Input placeholder={t("form.placeholder.name")} />
        </Form.Item>
        <Form.Item label={t("form.label.email")} name="email" rules={[{ required: true, type: "email" }]}>
          <Input placeholder={t("form.placeholder.email")} type="email" disabled={isEditing} />
        </Form.Item>
        {user === ClientUsers.Consignee && (
          <Form.Item label={t("form.label.fullName")} name="fullName">
            <Input placeholder={t("form.placeholder.fullName")} />
          </Form.Item>
        )}
        {user !== ClientUsers.Carrier && (
          <Form.Item label={t("form.label.address")} name="address">
            <SelectAddress onSelect={handleSelectAddress} />
          </Form.Item>
        )}
        <Form.Item hidden name="coordinate" />
        <Form.Item label={t("form.label.externalId")} name="externalId">
          <Input placeholder={t("form.placeholder.externalId")} />
        </Form.Item>

        <Row gutter={[32, 32]} justify="end" wrap>
          <Col>
            <Button type="default" onClick={() => navigate(-1)}>
              {t("button.cancel")}
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              {isEditing ? t("button.save") : t("button.create")}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

import { ReactNode, createContext, useContext } from "react";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

interface NotificationContextType {
  notifySuccess: (description: string) => void;
  notifyError: (message: string, description?: string) => void;
  notifyInfo: (message: string, description: string) => void;
  notifyWarning: (message: string, description: string) => void;
}

const initialState: NotificationContextType = {
  notifySuccess: (description: string) => {},
  notifyError: (message: string, description?: string) => {},
  notifyInfo: (message: string, description: string) => {},
  notifyWarning: (message: string, description: string) => {},
};

export const NotificationContext = createContext<NotificationContextType>(initialState);

export interface NotificationProviderProps {
  children?: ReactNode;
}

export const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();

  const notifySuccess = (description: string) => {
    api.success({
      placement: "topRight",
      message: t("notifications.success"),
      description,
      duration: 3,
    });
  };

  const notifyError = (message: string, description?: string) => {
    api.error({
      placement: "topRight",
      message,
      description,
      duration: 5,
    });
  };

  const notifyInfo = (message: string, description: string) => {
    api.info({
      placement: "topRight",
      message,
      description,
      duration: 3,
    });
  };

  const notifyWarning = (message: string, description: string) => {
    api.warning({
      placement: "topRight",
      message,
      description,
      duration: 5,
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        notifyError,
        notifyInfo,
        notifySuccess,
        notifyWarning,
      }}
    >
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => useContext(NotificationContext);

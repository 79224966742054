import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { v4 as uuid } from "uuid";

import { useGetProductTypesSuspenseQuery } from "graphql-api";

import { useAuthContext } from "context";
import { DeleteIcon, PlusIcon } from "assets";

interface Props {
  rideIndex: number;
  rideId: string;
  isEditing?: boolean;
  saveProductsToDelete: (productId: string) => void;
}

export const ProductsFormList: FC<Props> = props => {
  const { rideIndex, rideId, isEditing, saveProductsToDelete } = props;
  const { t } = useTranslation();
  const { clientId } = useAuthContext();
  const form = Form.useFormInstance();

  const { data: productTypes } = useGetProductTypesSuspenseQuery({ variables: { clientId } });

  const handleDeleteRide = (productId: string, callback: () => void) => {
    if (isEditing) {
      saveProductsToDelete(productId);
    }
    callback();
  };

  return (
    <Form.List name={[rideIndex, "products"]}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => {
            const ride = form.getFieldValue("rides")[rideIndex];

            return (
              <Card
                key={field.key}
                title={t("components.productFormList.title")}
                style={{ marginBottom: "16px" }}
                extra={
                  <Button
                    disabled={fields.length === 1}
                    type="text"
                    icon={<DeleteIcon />}
                    onClick={() => handleDeleteRide(ride.products[index].id, () => remove(index))}
                  />
                }
              >
                <Row gutter={[16, 16]}>
                  <Col flex={1} span={12}>
                    <Form.Item
                      label={t("form.label.expectedAmount")}
                      name={[index, "expectedAmount"]}
                      rules={[{ required: true }]}
                    >
                      <Input type="number" min={1} placeholder={t("form.placeholder.expectedAmount")} />
                    </Form.Item>
                  </Col>
                  <Col flex={1} span={12}>
                    <Form.Item
                      label={t("form.label.productType")}
                      name={[index, "productTypeId"]}
                      rules={[{ required: true }]}
                    >
                      <Select
                        placeholder={t("form.placeholder.productType")}
                        options={productTypes.productTypes.map(productType => ({
                          label: productType.name,
                          value: productType.id,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            );
          })}
          <Row justify="start" wrap>
            <Button
              type="primary"
              icon={<PlusIcon />}
              onClick={() => add({ id: uuid(), rideId, createdInEditMode: isEditing })}
            >
              {t("button.addProduct")}
            </Button>
          </Row>
        </>
      )}
    </Form.List>
  );
};

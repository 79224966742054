import { useTranslation } from "react-i18next";
import { Card, DatePicker, Form, Input } from "antd";

export const DeliveryCard = () => {
  const { t } = useTranslation();

  return (
    <Card bordered={false} title={t("components.deliveryForm.title")}>
      <Form.Item label={t("form.label.deliveryTime")} name="timeLocal" rules={[{ required: true }]}>
        <DatePicker placeholder={t("form.placeholder.deliveryTime")} showTime style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item label={t("form.label.externalId")} name="externalId">
        <Input placeholder={t("form.placeholder.externalId")} />
      </Form.Item>
      <Form.Item hidden name="progress" />
      <Form.Item hidden name="status" />
      <Form.Item hidden name="clientId" />
    </Card>
  );
};

import { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Table } from "antd";
import dayjs from "dayjs";

import { GetTrucksQuery, Trucks, useDeleteTruckMutation } from "graphql-api";

import { DeleteButton, TruncatedText } from "components";
import { EditIcon } from "assets";
import { useNotificationContext } from "context";
import { RoutePath } from "constant";

interface Props {
  dataSource: GetTrucksQuery["trucks"];
}

export const TrucksTable: FC<Props> = props => {
  const { dataSource } = props;
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useNotificationContext();

  const [deleteTruckMutation] = useDeleteTruckMutation();

  const handleDeleteTruck = (id: string) => {
    deleteTruckMutation({
      variables: { id },
      refetchQueries: ["GetTrucks"],
      onCompleted: () => {
        notifySuccess(t("notifications.deleteSuccess", { entity: t("common.truck") }));
      },
      onError(error) {
        notifyError(t("notifications.graphqlError"), error.message);
      },
    });
  };

  return (
    <Table dataSource={dataSource} rowKey={record => record.id} rowClassName="app-table-row">
      <Table.Column<Trucks>
        key="id"
        title={t("table.truckId")}
        dataIndex="id"
        render={value => <TruncatedText value={value} />}
      />
      <Table.Column<Trucks>
        key="driversName"
        title={t("table.driverName")}
        dataIndex="driversName"
        sorter={(a, b) => (a.driversName ?? "").localeCompare(b.driversName ?? "")}
      />
      <Table.Column<Trucks>
        key="truckLicense"
        title={t("table.truckLicense")}
        dataIndex="truckLicense"
        sorter={(a, b) => (a.truckLicense ?? "").localeCompare(b.truckLicense ?? "")}
      />
      <Table.Column<Trucks>
        key="carrier.name"
        title={t("table.driver")}
        dataIndex={["carrier", "name"]}
        sorter={(a, b) => a.carrier?.name.localeCompare(b.carrier?.name)}
      />
      <Table.Column<Trucks>
        key="createdAt"
        title={t("table.dateAdded")}
        dataIndex="createdAt"
        sorter={(a, b) => a.createdAt.localeCompare(b.createdAt)}
        render={value => <span>{dayjs(value).format("DD.MM.YYYY")}</span>}
      />
      <Table.Column<Trucks>
        key="actions"
        title={t("common.actions")}
        fixed="right"
        align="center"
        render={(_, record) => (
          <>
            <Link to={`${RoutePath.EditTruck}/${record.id}`}>
              <Button type="text" icon={<EditIcon />} />
            </Link>
            <DeleteButton onDelete={() => handleDeleteTruck(record.id)} />
          </>
        )}
      />
    </Table>
  );
};

import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Flex, Table, Tag } from "antd";
import dayjs from "dayjs";

import { Clients, ClientStatus_Enum, useDeleteClientMutation } from "graphql-api";

import { Colors } from "theme";
import { EditIcon } from "assets";
import { useNotificationContext } from "context";
import { DeleteButton, TruncatedText } from "components";
import { RoutePath } from "constant";

interface Props {
  dataSource: Clients[];
}

export const CompaniesTable: FC<Props> = props => {
  const { dataSource } = props;
  const { t } = useTranslation();
  const [deleteClientMutation] = useDeleteClientMutation();
  const { notifySuccess, notifyError } = useNotificationContext();

  const handleDeleteCompany = (id: string) => {
    // TODO: TBD https://dev.azure.com/dev-house-gmbh/trace.way/_backlogs/backlog/trace.way%20Team/Epics/?workitem=1273
    deleteClientMutation({
      variables: { id },
      refetchQueries: ["GetClients"],
      onCompleted: () => {
        notifySuccess(t("notifications.deleteSuccess", { entity: t("common.company") }));
      },
      onError(error) {
        notifyError(t("notifications.graphqlError"), error.message);
      },
    });
  };

  return (
    <Table<Clients> dataSource={dataSource} rowKey={record => record.id} rowClassName="app-table-row">
      <Table.Column<Clients>
        key="id"
        title={t("table.companyId")}
        dataIndex="id"
        render={value => <TruncatedText value={value} />}
      />
      <Table.Column<Clients>
        key="companyName"
        title={t("table.companyName")}
        dataIndex="companyName"
        sorter={(a, b) => a.companyName.localeCompare(b.companyName)}
      />
      <Table.Column<Clients>
        key="name"
        title={t("table.contactName")}
        dataIndex="name"
        sorter={(a, b) => a.name.localeCompare(b.name)}
      />
      <Table.Column<Clients>
        key="createdAt"
        title={t("table.registered")}
        dataIndex="createdAt"
        sorter={(a, b) => a.createdAt.localeCompare(b.createdAt)}
        render={value => <span>{dayjs(value).format("DD.MM.YYYY")}</span>}
      />
      <Table.Column<Clients>
        key="status"
        title={t("common.status")}
        dataIndex="status"
        sorter={(a, b) => a.status.localeCompare(b.status)}
        render={value => (
          <Tag color={value === ClientStatus_Enum.Active ? Colors.Cyprus : Colors.Aluminium}>{value}</Tag>
        )}
      />
      <Table.Column<Clients>
        key="actions"
        title={t("common.actions")}
        fixed="right"
        align="center"
        render={(_, record) => (
          <Flex>
            <Link to={`${RoutePath.EditCompany}/${record.id}`}>
              <Button type="text" icon={<EditIcon />} />
            </Link>
            <DeleteButton onDelete={() => handleDeleteCompany(record.id)} />
          </Flex>
        )}
      />
    </Table>
  );
};

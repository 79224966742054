import { Colors } from "./colors";

export const appTheme = {
  token: {
    colorPrimary: Colors.Cyprus,
    colorBgLayout: Colors.IceBlue,
    fontFamily: "Avenir",
  },
  components: {
    Menu: {
      itemBg: "transparent",
      itemColor: Colors.Black,
      itemSelectedColor: Colors.Black,
      itemHoverColor: Colors.Black,
      itemSelectedBg: Colors.IceBlue,
      groupTitleFontSize: 22,
      iconSize: 26,
      collapsedIconSize: 26,
    },
    Table: {
      headerBg: Colors.White,
      headerSplitColor: Colors.White,
      headerBorderRadius: 0,
      cellFontSize: 16,
      rowHoverBg: Colors.Silver,
    },
    Button: {
      fontWeight: 500,
      defaultBg: Colors.Silver,
    },
    Form: {
      labelFontSize: 16,
    },
    Tabs: {
      titleFontSize: 16,
    },
  },
};

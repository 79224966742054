import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PageContainer } from "@ant-design/pro-components";
import { Button } from "antd";

import { useGetTrucksSuspenseQuery } from "graphql-api";

import { TrucksTable } from "components";
import { PlusIcon } from "assets";
import { RoutePath } from "constant";
import { useAuthContext } from "context";

export const TrucksPage = () => {
  const { t } = useTranslation();
  const { clientId } = useAuthContext();

  const { data } = useGetTrucksSuspenseQuery({ variables: { clientId } });

  return (
    <PageContainer
      extra={
        <Link to={RoutePath.CreateTruck}>
          <Button type="primary" icon={<PlusIcon />}>
            {t("button.createTruck")}
          </Button>
        </Link>
      }
    >
      <TrucksTable dataSource={data.trucks} />
    </PageContainer>
  );
};

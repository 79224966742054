import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PageContainer } from "@ant-design/pro-components";
import { Button } from "antd";

import { useGetClientsSuspenseQuery } from "graphql-api";

import { CompaniesTable } from "components";
import { PlusIcon } from "assets";
import { RoutePath } from "constant";

export const CompaniesPage = () => {
  const { t } = useTranslation();
  const { data } = useGetClientsSuspenseQuery();

  return (
    <PageContainer
      extra={
        <Link to={RoutePath.CreateCompany}>
          <Button type="primary" icon={<PlusIcon />}>
            {t("button.createCompany")}
          </Button>
        </Link>
      }
    >
      <CompaniesTable dataSource={data.clients} />
    </PageContainer>
  );
};

import { useEffect, useState } from "react";
import { PageContainer, PageHeader } from "@ant-design/pro-components";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Button } from "antd";

import { useGetVendorsLazyQuery, useGetCarriersLazyQuery, useGetConsigneesLazyQuery } from "graphql-api";

import { PlusIcon } from "assets";
import { ClientUsers, RoutePath, UrlSearchParams } from "constant";
import { UsersTable } from "components";
import { useAuthContext } from "context";

export const UsersPage = () => {
  const { t } = useTranslation();
  const { hash } = useLocation();

  const [activeTabKey, setActiveTabKey] = useState<string>(ClientUsers.Vendor);
  const { clientId } = useAuthContext();

  const [fetchVendors, { data: vendors, loading: vendorsLoading }] = useGetVendorsLazyQuery({
    variables: { clientId },
  });
  const [fetchCarriers, { data: carriers, loading: carriersLoading }] = useGetCarriersLazyQuery({
    variables: { clientId },
  });
  const [fetchConsignees, { data: consignees, loading: consigneesLoading }] = useGetConsigneesLazyQuery({
    variables: { clientId },
  });

  const handleTabChange = (key: string) => {
    setActiveTabKey(key);
    // Manually call `window.history.pushState` to avoid the router from triggering the loader and rerender page.
    window.history.pushState(null, "", `#${key}`);
  };

  const defineDataToDisplay = () => {
    switch (activeTabKey) {
      case ClientUsers.Vendor:
        return vendors?.vendors;
      case ClientUsers.Carrier:
        return carriers?.carriers;
      case ClientUsers.Consignee:
        return consignees?.consignees;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    switch (hash.slice(1)) {
      case ClientUsers.Vendor:
        setActiveTabKey(ClientUsers.Vendor);
        break;
      case ClientUsers.Carrier:
        setActiveTabKey(ClientUsers.Carrier);
        break;
      case ClientUsers.Consignee:
        setActiveTabKey(ClientUsers.Consignee);
        break;
    }
  }, [hash]);

  useEffect(() => {
    if (activeTabKey === ClientUsers.Vendor) {
      fetchVendors();
    }
    if (activeTabKey === ClientUsers.Carrier) {
      fetchCarriers();
    }
    if (activeTabKey === ClientUsers.Consignee) {
      fetchConsignees();
    }
  }, [activeTabKey, fetchCarriers, fetchConsignees, fetchVendors]);

  return (
    <PageContainer
      loading={vendorsLoading || carriersLoading || consigneesLoading}
      tabList={[
        {
          tab: t("users.vendors"),
          key: ClientUsers.Vendor,
        },
        {
          tab: t("users.carriers"),
          key: ClientUsers.Carrier,
        },
        {
          tab: t("users.consignees"),
          key: ClientUsers.Consignee,
        },
      ]}
      onTabChange={handleTabChange}
      tabActiveKey={activeTabKey}
    >
      <PageHeader
        extra={
          <Link to={{ pathname: RoutePath.CreateUser, search: `?${UrlSearchParams.User}=${activeTabKey}` }}>
            <Button type="primary" icon={<PlusIcon />}>
              {t("button.createUser", { user: t(`users.${activeTabKey}`) })}
            </Button>
          </Link>
        }
        style={{ padding: 0 }}
      />
      <UsersTable user={activeTabKey} dataSource={defineDataToDisplay()} />
    </PageContainer>
  );
};

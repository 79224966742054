import { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Table } from "antd";
import dayjs from "dayjs";

import {
  GetVendorsQuery,
  GetCarriersQuery,
  GetConsigneesQuery,
  Vendors,
  Carriers,
  Consignees,
  useDeleteVendorMutation,
  useDeleteCarrierMutation,
  useDeleteConsigneeMutation,
} from "graphql-api";

import { EditIcon } from "assets";
import { ClientUsers, RoutePath, UrlSearchParams } from "constant";
import { DeleteButton, TruncatedText } from "components";
import { useNotificationContext } from "context";

type DataSource =
  | GetVendorsQuery["vendors"]
  | GetCarriersQuery["carriers"]
  | GetConsigneesQuery["consignees"];

type UserColumn = Vendors | Carriers | Consignees;

interface Props {
  dataSource?: DataSource;
  user: string;
}

export const UsersTable: FC<Props> = props => {
  const { dataSource, user } = props;
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useNotificationContext();

  const [deleteVendorMutation] = useDeleteVendorMutation();
  const [deleteCarrierMutation] = useDeleteCarrierMutation();
  const [deleteConsigneeMutation] = useDeleteConsigneeMutation();

  const handleDeleteUser = (id: string) => {
    // TODO: TBD https://dev.azure.com/dev-house-gmbh/trace.way/_backlogs/backlog/trace.way%20Team/Epics/?workitem=1273
    switch (user) {
      case ClientUsers.Vendor:
        deleteVendorMutation({
          variables: { id },
          refetchQueries: ["GetVendors"],
          onCompleted: () =>
            notifySuccess(t("notifications.deleteSuccess", { entity: t(`users.${ClientUsers.Vendor}`) })),
          onError: error => notifyError(t("notifications.graphqlError"), error.message),
        });
        return;
      case ClientUsers.Carrier:
        deleteCarrierMutation({
          variables: { id },
          refetchQueries: ["GetCarriers"],
          onCompleted: () =>
            notifySuccess(t("notifications.deleteSuccess", { entity: t(`users.${ClientUsers.Carrier}`) })),
          onError: error => notifyError(t("notifications.graphqlError"), error.message),
        });
        return;
      case ClientUsers.Consignee:
        deleteConsigneeMutation({
          variables: { id },
          refetchQueries: ["GetConsignees"],
          onCompleted: () =>
            notifySuccess(t("notifications.deleteSuccess", { entity: t(`users.${ClientUsers.Consignee}`) })),
          onError: error => notifyError(t("notifications.graphqlError"), error.message),
        });
        return;
    }
  };

  return (
    <Table
      /** Can't omit nested specific uncompatible types from GraphQL. See {@link DataSource} to know the types. */
      dataSource={dataSource as any}
      rowKey={record => record.id}
      rowClassName="app-table-row"
    >
      <Table.Column
        key="id"
        title={t("table.userId")}
        dataIndex="id"
        render={value => <TruncatedText value={value} />}
      />
      <Table.Column<UserColumn>
        key="name"
        title={t("table.name")}
        dataIndex="name"
        sorter={(a, b) => a.name.localeCompare(b.name)}
      />
      {user !== ClientUsers.Carrier && (
        <Table.Column
          key="address"
          title={t("table.address")}
          dataIndex="address"
          sorter={(a: Vendors | Consignees, b: Vendors | Consignees) =>
            (a.address ?? "").localeCompare(b.address ?? "")
          }
        />
      )}
      <Table.Column<UserColumn>
        key="createdAt"
        title={t("table.dateAdded")}
        dataIndex="createdAt"
        sorter={(a, b) => a.createdAt.localeCompare(b.createdAt)}
        render={value => <span>{dayjs(value).format("DD.MM.YYYY")}</span>}
      />
      <Table.Column<UserColumn>
        key="actions"
        title={t("common.actions")}
        fixed="right"
        align="center"
        render={(_, record) => (
          <>
            <Link
              to={{
                pathname: `${RoutePath.EditUser}/${record.id}`,
                search: `?${UrlSearchParams.User}=${user}`,
              }}
            >
              <Button type="text" icon={<EditIcon />} />
            </Link>
            <DeleteButton onDelete={() => handleDeleteUser(record.id)} />
          </>
        )}
      />
    </Table>
  );
};

import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { Avatar, Button, Dropdown, Flex, Layout, MenuProps, Typography } from "antd";
import dayjs from "dayjs";

import { Colors } from "theme";
import { LogoutIcon } from "assets";
import { FALLBACK_LNG } from "constant";
import { useAuthContext } from "context";

import EnFlag from "assets/icons/en.svg";
import DeFlag from "assets/icons/de.svg";

require("dayjs/locale/de");
require("dayjs/locale/en");

const LANGUAGES = [
  { key: "en", displayName: "EN" },
  { key: "de", displayName: "DE" },
];

export const AppHeader = () => {
  const { i18n, t } = useTranslation();
  const classes = useStyles();
  let location = useLocation();
  const { logout } = useAuthContext();

  const items: MenuProps["items"] = LANGUAGES.map(({ key, displayName }) => ({
    key,
    label: displayName,
    onClick: () => {
      i18n.changeLanguage(key);
      dayjs.locale(key);
    },
    icon: <Avatar size={20} src={key === FALLBACK_LNG ? DeFlag : EnFlag} className={classes.menuItem} />,
  }));

  const defineHeaderName = useCallback(() => {
    const currentPath = location.pathname.replace(/^\/([^\/]*).*$/, "$1");

    return t([`navigation.${currentPath}`, "navigation.dashboard"]);
  }, [location.pathname, t]);

  return (
    <Layout.Header className={classes.header}>
      <Flex align="center" justify="space-between" className={classes.container}>
        <Typography.Text className={classes.title} strong>
          {defineHeaderName()}
        </Typography.Text>
        <Flex align="center" justify="space-between">
          <Dropdown menu={{ items }}>
            <Button type="link">
              <Flex align="center" justify="space-between">
                <Avatar
                  size={20}
                  src={i18n.resolvedLanguage === FALLBACK_LNG ? DeFlag : EnFlag}
                  className={classes.menuItem}
                />
                <span className={classes.language}>{`${i18n.language.toUpperCase()}`}</span>
              </Flex>
            </Button>
          </Dropdown>
          <LogoutIcon className={classes.logout} onClick={() => logout()} />
        </Flex>
      </Flex>
    </Layout.Header>
  );
};

const useStyles = createUseStyles({
  header: {
    height: "100px",
    display: "flex",
    alignItems: "center",
    backgroundColor: Colors.White,
    paddingInline: "24px",
  },
  container: {
    width: "100%",
    height: "100px",
    paddingTop: "30px",
    paddingBottom: "25px",
  },
  title: {
    fontSize: "30px",
  },
  language: {
    fontSize: "16px",
    color: "black",
  },
  menuItem: {
    marginRight: 8,
  },
  logout: {
    cursor: "pointer",
  },
});

import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Carriers,
  Clients,
  Consignees,
  DeliveryProgress_Enum,
  DeliveryStatus_Enum,
  RideStatus_Enum,
  Vendors_Insert_Input,
} from "graphql-api";
import { IApiException, RestApiClient } from "rest-api";

import { CreateEditDelivery, GeocodingResponse, KeycloakRoleResponseDto } from "interfaces";
import { useAuthContext, useNotificationContext } from "context";

const restApiClient: RestApiClient = RestApiClient.getInstance();

const useKeycloakCLI = () => {
  const [keycloakRoles, setKeycloakRoles] = useState<KeycloakRoleResponseDto[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /** Get existing Keycloak roles to have required information for new user. */
  const getKeycloakRoles = useCallback(() => {
    if (!keycloakRoles?.length) {
      setIsLoading(true);
      restApiClient
        .getKeycloakRoles()
        .then(
          (result: KeycloakRoleResponseDto[]) => setKeycloakRoles(result),
          (error: IApiException) => error // TODO: discuss how to handle error.
        )
        .finally(() => setIsLoading(false));
    }
  }, [keycloakRoles]);

  /** Creates new Keycloak user with web-client role. */
  const createNewCompany = useCallback(
    (data: Clients) =>
      restApiClient.createKeycloakClient(data).then(
        (result: string) => result,
        (error: IApiException) => {
          throw error;
        }
      ),
    []
  );

  /** Creates new Keycloak user with Vendor / Carrier / Consignee role. */
  const createNewUser = useCallback(
    (data: Vendors_Insert_Input | Carriers | Consignees, user: string) => {
      const userRole = keycloakRoles!.find(role => role.Name === user);

      return restApiClient.createKeycloakUser(data, userRole!).then(
        (result: string) => result,
        (error: IApiException) => {
          throw error;
        }
      );
    },
    [keycloakRoles]
  );

  return { isLoading, getKeycloakRoles, createNewCompany, createNewUser };
};

const useCreateDelivery = () => {
  const { clientId } = useAuthContext();

  const createInitialState = useCallback(
    (): CreateEditDelivery => ({
      clientId,
      progress: DeliveryProgress_Enum.Scheduled,
      status: DeliveryStatus_Enum.Confirmed,
      rides: [
        {
          status: RideStatus_Enum.Pending,
          rideToDestination: false,
          createdInEditMode: false,
          products: [{ createdInEditMode: false }],
        },
      ],
    }),
    [clientId]
  );

  const [delivery] = useState(createInitialState());

  return { delivery };
};

const useGeocodingAPI = () => {
  const { notifyError } = useNotificationContext();
  const { t } = useTranslation();

  const getAvailableAddresses = useCallback(
    (place: string) =>
      restApiClient.getPlacesFromAddress(place).then(
        (result: GeocodingResponse) => result,
        (error: IApiException) => {
          notifyError(t("notifications.error", { statusCode: error.status }), error.message);
          return undefined;
        }
      ),
    [notifyError, t]
  );
  return { getAvailableAddresses };
};

export { useKeycloakCLI, useCreateDelivery, useGeocodingAPI };

import Keycloak, { KeycloakInitOptions } from "keycloak-js";
import { AuthLocalStorageKeys, UserRole } from "constant";

export class KeycloakService {
  static instance: KeycloakService;

  static getInstance() {
    if (KeycloakService.instance) {
      return KeycloakService.instance;
    } else {
      return new KeycloakService();
    }
  }

  public readonly keycloak: Keycloak;
  public readonly refreshTime: number;
  public initialized = false;

  constructor() {
    if (KeycloakService.instance) {
      throw new Error("KeycloakService instance already exists. Use KeycloakService.getInstance() instead");
    }
    this.keycloak = new Keycloak({
      url: process.env.REACT_APP_KEYCLOAK_URL,
      realm: process.env.REACT_APP_KEYCLOAK_REALM!,
      clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID!,
    });

    this.refreshTime = 1000;

    KeycloakService.instance = this;
  }

  initialize = async (options?: KeycloakInitOptions) => {
    const token = localStorage.getItem(AuthLocalStorageKeys.AUTH_TOKEN) ?? undefined;
    const refreshToken = localStorage.getItem(AuthLocalStorageKeys.REFRESH_TOKEN) ?? undefined;

    await this.keycloak.init({
      onLoad: "login-required",
      token,
      refreshToken,
      checkLoginIframe: false,
      redirectUri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URI,
      ...options,
    });

    localStorage.setItem(AuthLocalStorageKeys.AUTH_TOKEN, this.keycloak.token ?? "");
    localStorage.setItem(AuthLocalStorageKeys.REFRESH_TOKEN, this.keycloak.refreshToken ?? "");

    this.initialized = true;

    return true;
  };

  get isLoggedIn() {
    return this.keycloak.authenticated;
  }

  get isAdminUser() {
    return this.keycloak.realmAccess?.roles.includes(UserRole.ADMIN);
  }

  get isClient() {
    return this.keycloak.realmAccess?.roles.includes(UserRole.CLIENT);
  }

  setOnAuthSuccess(callback: () => void) {
    this.keycloak.onAuthSuccess = callback;
    return this;
  }

  setOnAuthRefreshSuccess(callback: () => void) {
    this.keycloak.onAuthRefreshSuccess = callback;
    return this;
  }

  login = () => this.keycloak.login;

  logout = () => {
    if (this.initialized) {
      this.keycloak.logout({
        redirectUri: this.keycloak.createLoginUrl(),
      });
    }
  };

  updateToken = async () => {
    const refreshed = await this.keycloak.updateToken(Number(this.refreshTime || 600));

    if (refreshed) {
      localStorage.setItem(AuthLocalStorageKeys.AUTH_TOKEN, this.keycloak.token ?? "");
      localStorage.setItem(AuthLocalStorageKeys.REFRESH_TOKEN, this.keycloak.refreshToken ?? "");
    }

    return refreshed;
  };
}

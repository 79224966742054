import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SelectProps } from "antd";

import { DebounceSelect } from "components/debounceSelect";
import { useGeocodingAPI } from "hooks";

export const SelectAddress: FC<SelectProps> = props => {
  const { t } = useTranslation();
  const { getAvailableAddresses } = useGeocodingAPI();

  const fetchAddressList = async (search: string) => {
    const result = await getAvailableAddresses(search);

    return (result?.features ?? []).map(feature => ({
      label: feature.place_name,
      value: feature.place_name,
      feature,
    }));
  };

  return (
    <DebounceSelect
      {...props}
      placeholder={t("form.placeholder.address")}
      fetchOptions={fetchAddressList}
      style={{ width: "100%" }}
    />
  );
};

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { en, de } from "assets";
import { FALLBACK_LNG } from "constant";

i18n.use(initReactI18next).init({
  resources: { en, de },
  fallbackLng: FALLBACK_LNG,

  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
});

export default i18n;

import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import {
  CompaniesPage,
  DashboardPage,
  ErrorPage,
  SettingsPage,
  AccessDeniedPage,
  CreateCompanyPage,
  EditCompanyPage,
  DeliveriesPage,
  UsersPage,
  CreateUserPage,
  EditUserPage,
  TrucksPage,
  CreateTruckPage,
  EditTruckPage,
  CreateDeliveryPage,
  EditDeliveryPage,
} from "pages";
import { AppLayout, Loader } from "components";
import { RoutePath } from "constant";
import { ProtectedAdminRoute } from "./components/protectedAdminRoute";
import { ProtectedClientRoute } from "./components/protectedClientRoute";
import { AuthRoute } from "./components/authRoute";
import { useAuthContext } from "context";

// TODO: read more about route patterns.
export const AppRouter = () => {
  const { isAuthenticated, isInitialized } = useAuthContext();

  // Keycloak job in progress.
  if (!isInitialized && !isAuthenticated) {
    return <Loader />;
  }

  return (
    <Routes>
      <Route
        element={
          <AuthRoute>
            <AppLayout />
          </AuthRoute>
        }
        path={RoutePath.Main}
      >
        <Route index element={<DashboardPage />} />
        <Route
          element={
            <ProtectedAdminRoute>
              <Suspense fallback={<Loader />}>
                <CompaniesPage />
              </Suspense>
            </ProtectedAdminRoute>
          }
          errorElement={<ErrorPage />}
          path={RoutePath.Companies}
        />
        <Route
          element={
            <ProtectedAdminRoute>
              <CreateCompanyPage />
            </ProtectedAdminRoute>
          }
          path={RoutePath.CreateCompany}
        />
        <Route
          element={
            <ProtectedAdminRoute>
              <Suspense fallback={<Loader />}>
                <EditCompanyPage />
              </Suspense>
            </ProtectedAdminRoute>
          }
          path={`${RoutePath.EditCompany}/:id`}
        />
        <Route
          element={
            <ProtectedClientRoute>
              <Suspense fallback={<Loader />}>
                <DeliveriesPage />
              </Suspense>
            </ProtectedClientRoute>
          }
          path={RoutePath.Deliveries}
        />
        <Route
          element={
            <ProtectedClientRoute>
              <Suspense fallback={<Loader />}>
                <CreateDeliveryPage />
              </Suspense>
            </ProtectedClientRoute>
          }
          path={RoutePath.CreateDelivery}
        />
        <Route
          element={
            <ProtectedClientRoute>
              <Suspense fallback={<Loader />}>
                <EditDeliveryPage />
              </Suspense>
            </ProtectedClientRoute>
          }
          path={`${RoutePath.EditDelivery}/:id`}
        />
        <Route
          element={
            <ProtectedClientRoute>
              <UsersPage />
            </ProtectedClientRoute>
          }
          path={RoutePath.Users}
        />
        <Route
          element={
            <ProtectedClientRoute>
              <CreateUserPage />
            </ProtectedClientRoute>
          }
          path={RoutePath.CreateUser}
        />
        <Route
          element={
            <ProtectedClientRoute>
              <Suspense fallback={<Loader />}>
                <EditUserPage />
              </Suspense>
            </ProtectedClientRoute>
          }
          path={`${RoutePath.EditUser}/:id`}
        />
        <Route
          element={
            <ProtectedClientRoute>
              <Suspense fallback={<Loader />}>
                <TrucksPage />
              </Suspense>
            </ProtectedClientRoute>
          }
          path={RoutePath.Trucks}
        />
        <Route
          element={
            <ProtectedClientRoute>
              <Suspense fallback={<Loader />}>
                <CreateTruckPage />
              </Suspense>
            </ProtectedClientRoute>
          }
          path={RoutePath.CreateTruck}
        />
        <Route
          element={
            <ProtectedClientRoute>
              <Suspense fallback={<Loader />}>
                <EditTruckPage />
              </Suspense>
            </ProtectedClientRoute>
          }
          path={`${RoutePath.EditTruck}/:id`}
        />
        <Route
          element={
            <ProtectedClientRoute>
              <SettingsPage />
            </ProtectedClientRoute>
          }
          path={RoutePath.Settings}
        />
      </Route>
      <Route element={<AccessDeniedPage />} path={RoutePath.AccessDenied} />
    </Routes>
  );
};

import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Popconfirm } from "antd";

import { DeleteIcon } from "assets";
import { Colors } from "theme";

interface Props {
  onDelete: () => void;
}

export const DeleteButton: FC<Props> = props => {
  const { onDelete } = props;
  const { t } = useTranslation();

  return (
    <Popconfirm
      key="delete"
      okText={t("button.delete")}
      cancelText={t("button.cancel")}
      okType="danger"
      title={t("common.confirmText")}
      cancelButtonProps={{ style: { backgroundColor: Colors.White } }}
      okButtonProps={{ type: "primary" }}
      overlayInnerStyle={{ minWidth: "140px" }}
      onConfirm={onDelete}
    >
      <Button type="text" icon={<DeleteIcon />} />
    </Popconfirm>
  );
};

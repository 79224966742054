import { FC } from "react";
import { Navigate } from "react-router-dom";

import { RoutePath, UserRole } from "constant";
import { useAuthContext } from "context";

interface Props {
  children: JSX.Element;
}

export const ProtectedClientRoute: FC<Props> = ({ children }) => {
  const { userRole } = useAuthContext();

  if (userRole !== UserRole.CLIENT) {
    return <Navigate to={RoutePath.AccessDenied} replace />;
  }

  return children;
};

import { useParams } from "react-router-dom";
import { PageContainer } from "@ant-design/pro-components";

import { useGetClientByIdSuspenseQuery } from "graphql-api";

import { CompanyInformation } from "components";

export const EditCompanyPage = () => {
  let { id } = useParams();
  const { data } = useGetClientByIdSuspenseQuery({ variables: { id } });

  return (
    <PageContainer>
      <CompanyInformation isEditing initialValues={data.clients_by_pk ?? undefined} />
    </PageContainer>
  );
};

/** Convert Postgresql Point type (x,y) to Mapbox Position [x,y]. */
export const parsePointToPosition = (coordinates?: string) => {
  if (coordinates) {
    return JSON.parse(coordinates.replace(/^\((.+)\)$/, "[$1]"));
  }
  return undefined;
};

/** Convert Mapbox Position [x,y] to Postgresql Point type (x,y). */
export const parsePositionToPoint = (coordinates?: string[]) => {
  if (coordinates) {
    return `(${coordinates.toString()})`;
  }
  return undefined;
};

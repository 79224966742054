import { FC, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Row } from "antd";

import { DeliveryCard } from "./deliveryCard";
import { RideFormList, TruckCard } from "components";
import { CreateEditDelivery } from "interfaces";

interface Props {
  isEditing?: boolean;
  initialValues: CreateEditDelivery | undefined;
  handleFinish: (values: CreateEditDelivery, rideIdsToDelete: string[], productIdsToDelete: string[]) => void;
}

export const DeliveryForm: FC<Props> = props => {
  const { initialValues, handleFinish, isEditing } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [rideIdsToDelete, setRideIdsToDelete] = useState<string[]>([]);
  const [productIdsToDelete, setProductIdsToDelete] = useState<string[]>([]);

  const saveRideToDelete = useCallback((rideId: string) => {
    setRideIdsToDelete(prev => [...prev, rideId]);
  }, []);

  const saveProductsToDelete = useCallback((productId: string) => {
    setProductIdsToDelete(prev => [...prev, productId]);
  }, []);

  return (
    <Form
      initialValues={initialValues}
      layout="vertical"
      onFinish={values => handleFinish(values, rideIdsToDelete, productIdsToDelete)}
    >
      <Row gutter={[16, 16]}>
        <Col span={7}>
          <DeliveryCard />
          <TruckCard isEditing={isEditing} />
        </Col>
        <Col flex={1}>
          <RideFormList
            isEditing={isEditing}
            saveRideToDelete={saveRideToDelete}
            saveProductsToDelete={saveProductsToDelete}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} justify="end" wrap style={{ marginTop: "16px" }}>
        <Col>
          <Button type="default" onClick={() => navigate(-1)}>
            {t("button.cancel")}
          </Button>
        </Col>
        <Col>
          <Button type="primary" htmlType="submit">
            {isEditing ? t("button.save") : t("button.create")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { AuthLocalStorageKeys } from "constant";
import { Carriers, Clients, Consignees, Vendors_Insert_Input } from "graphql-api";
import { ClientRequestDto, CreateKeycloakUser, KeycloakRoleResponseDto } from "interfaces";

export class RestApiClient {
  static instance: RestApiClient;

  static getInstance() {
    // eslint-disable-next-line eqeqeq
    if (RestApiClient.instance == null) {
      RestApiClient.instance = new RestApiClient();
    }

    return this.instance;
  }

  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = this.createAxiosInstance();
  }

  /** Get existing Keycloak roles to have required information for new user. */
  public getKeycloakRoles() {
    let _options: AxiosRequestConfig = {
      method: "GET",
      url: `/roles`,
      cancelToken: undefined,
    };

    return this.processAxiosRequest(_options);
  }

  /*
   * Creates new Keycloak user with web-client role.
   * Dump password will be changed on first login.
   */
  public createKeycloakClient(data: Clients) {
    const { email, companyName } = data;

    const request: ClientRequestDto = {
      Username: companyName,
      Email: email,
      FirstName: companyName,
      LastName: companyName,
      Password: "qwerty123", // TODO: will be random value in the future.
    };

    let _options: AxiosRequestConfig = {
      method: "POST",
      url: `/client`,
      cancelToken: undefined,
      data: request,
    };

    return this.processAxiosRequest(_options);
  }

  /*
   * Creates new Keycloak user with Vendor / Carrier / Consignee role.
   * Dump password will be changed on first login.
   */
  public createKeycloakUser(
    data: Vendors_Insert_Input | Carriers | Consignees,
    role: KeycloakRoleResponseDto
  ) {
    const { name, email } = data;

    const request: CreateKeycloakUser = {
      Username: name!,
      Email: email!,
      FirstName: name!,
      LastName: name!,
      Password: "qwerty123", // TODO: will be random value in the future.
      Roles: [role],
    };

    let _options: AxiosRequestConfig = {
      method: "POST",
      url: `/user`,
      cancelToken: undefined,
      data: request,
    };

    return this.processAxiosRequest(_options);
  }

  /** Fetches address suggestions based on user input. */
  public getPlacesFromAddress = (place: string) => {
    let _options: AxiosRequestConfig = {
      method: "GET",
      url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${place}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
      cancelToken: undefined,
    };

    return axios
      .request(_options)
      .catch((_error: any) => {
        if (this.isAxiosError(_error) && _error.response) {
          return _error.response;
        } else {
          throw _error;
        }
      })
      .then((_response: AxiosResponse) => this.processAxiosResponse(_response));
  };

  private createAxiosInstance() {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_KEYCLOAK_CLI_URL,
    });

    axiosInstance.interceptors.request.use(async config => {
      const token = localStorage.getItem(AuthLocalStorageKeys.AUTH_TOKEN);

      if (token) {
        config.headers!.Authorization = `Bearer ${token}`;
      }

      return config;
    });

    return axiosInstance;
  }

  private throwException(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result?: any
  ): any {
    if (result !== null && result !== undefined) {
      throw result;
    } else {
      throw new ApiException(message, status, response, headers, null);
    }
  }

  private isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
  }

  private processAxiosResponse(response: AxiosResponse): Promise<any> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }

    if (status === 400) {
      return this.throwException(
        "In case of invalid parameters",
        status,
        JSON.parse(response.data),
        _headers
      );
    } else if (status === 401) {
      return this.throwException(
        "In case the Bearer token isn't specified or invalid",
        status,
        response.data,
        _headers
      );
    } else if (status === 500) {
      const _responseText = response.data;
      return this.throwException("In case of any internal server error", status, _responseText, _headers);
    } else if (status === 200 || status === 201) {
      return Promise.resolve(response.data);
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return this.throwException("An unexpected server error occurred.", status, _responseText, _headers);
    }
    return Promise.resolve(null as any);
  }

  private processAxiosRequest(config: AxiosRequestConfig): Promise<any> {
    return this.axiosInstance
      .request(config)
      .catch((_error: any) => {
        if (this.isAxiosError(_error) && _error.response) {
          return _error.response;
        } else {
          throw _error;
        }
      })
      .then((_response: AxiosResponse) => this.processAxiosResponse(_response));
  }
}

class ApiException extends Error implements IApiException {
  message: string;
  status: number;
  response: string;
  headers: { [key: string]: any };
  result: any;

  constructor(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result: any
  ) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
  }

  protected isApiException = true;

  static isApiException(obj: any): obj is ApiException {
    return obj.isApiException === true;
  }
}

export interface IApiException {
  message: string;
  status: number;
  response: string;
  headers: { [key: string]: any };
  result: any;
}

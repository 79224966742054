import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "@ant-design/pro-components";

import { useCreateDeliveryMutation } from "graphql-api";

import { DeliveryForm } from "components";
import { useCreateDelivery } from "hooks";
import { CreateEditDelivery } from "interfaces";
import { useNotificationContext } from "context";
import { RoutePath } from "constant";

export const CreateDeliveryPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useNotificationContext();
  const { delivery } = useCreateDelivery();

  const [createDeliveryMutation, { loading }] = useCreateDeliveryMutation();

  const handleFinish = useCallback(
    (values: CreateEditDelivery) => {
      const { rides, carrierId, truck, ...rest } = values;

      // To be sure that delivery has at least one ride to vendor and has ride to destination.
      if (rides.length >= 2 && rides[rides.length - 1].rideToDestination) {
        createDeliveryMutation({
          variables: {
            data: {
              ...rest,
              truckId: truck!.id,
              rides: {
                data: rides.map(ride => {
                  const { id, rideToDestination, createdInEditMode, products, ...rest } = ride;

                  return {
                    ...rest,
                    products: {
                      data: products.map(product => {
                        const { id, rideId, createdInEditMode, ...rest } = product;

                        return rest;
                      }),
                    },
                  };
                }),
              },
            },
          },
          onCompleted: () => {
            notifySuccess(t("notifications.createSuccess", { entity: t("common.delivery") }));
            navigate(RoutePath.Deliveries);
          },
          onError(error) {
            notifyError(t("notifications.graphqlError"), error.message);
          },
        });
      } else {
        notifyError(t("notifications.graphqlError"), t("notifications.emptyRides"));
      }
    },
    [createDeliveryMutation, navigate, notifyError, notifySuccess, t]
  );

  return (
    <PageContainer loading={loading}>
      <DeliveryForm initialValues={delivery} handleFinish={handleFinish} />
    </PageContainer>
  );
};

import { FC } from "react";
import { Navigate } from "react-router-dom";

import { RoutePath } from "constant";
import { useAuthContext } from "context";

interface Props {
  children: JSX.Element;
}

export const AuthRoute: FC<Props> = ({ children }) => {
  const { userRole } = useAuthContext();

  // Thera are no available roles for app. Accepts only web-abmin and web-client.
  if (!userRole) {
    return <Navigate to={RoutePath.AccessDenied} replace />;
  }

  return children;
};

import { FC, useState, useEffect, useCallback } from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { Card, Form, Input, Select } from "antd";

import {
  GetTrucksByCarrierIdQuery,
  useGetCarriersSuspenseQuery,
  useGetTrucksByCarrierIdLazyQuery,
} from "graphql-api";

import { useAuthContext, useNotificationContext } from "context";
import { Colors } from "theme";

interface Props {
  isEditing?: boolean;
}

export const TruckCard: FC<Props> = props => {
  const { isEditing } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const form = Form.useFormInstance();
  const { clientId } = useAuthContext();
  const { notifyError } = useNotificationContext();

  const [trucks, setTrucks] = useState<GetTrucksByCarrierIdQuery["trucks"]>();
  const [canShowTruckSelector, setCanShowTruckSelector] = useState(false);
  const [canShowTruckInfo, setCanShowTruckInfo] = useState(false);

  const { data: carriers } = useGetCarriersSuspenseQuery({ variables: { clientId } });
  const [getTrucksByCarrierId] = useGetTrucksByCarrierIdLazyQuery();

  const handleSelectCarrier = useCallback(
    (carrierId: string) => {
      getTrucksByCarrierId({
        variables: { carrierId },
        onCompleted: response => {
          setTrucks(response.trucks);
          setCanShowTruckSelector(true);
        },
        onError(error) {
          notifyError(t("notifications.graphqlError"), error.message);
        },
      });
    },
    [getTrucksByCarrierId, notifyError, t]
  );

  const handleSelectTruck = (truckId: string) => {
    // Clean up only fields for this component.
    form.resetFields([
      ["truck", "driversName"],
      ["truck", "trailerNumber"],
      ["truck", "externalId"],
    ]);

    const truck = trucks?.find(truck => truck.id === truckId);
    form.setFieldValue("truck", truck);
    setCanShowTruckInfo(true);
  };

  useEffect(() => {
    if (isEditing) {
      const carrierId = form.getFieldValue("carrierId");
      handleSelectCarrier(carrierId);
      setCanShowTruckInfo(true);
    }
  }, [form, handleSelectCarrier, isEditing]);

  return (
    <Card bordered={false} title={t("components.truckCard.title")} className={classes.card}>
      <Form.Item label={t("users.carrier")} name="carrierId" rules={[{ required: true }]}>
        <Select
          placeholder={t("form.placeholder.carrier")}
          options={carriers.carriers.map(carrier => ({
            label: carrier.name,
            value: carrier.id,
          }))}
          onChange={handleSelectCarrier}
        />
      </Form.Item>
      <Form.Item
        label={t("components.truckCard.title")}
        hidden={!canShowTruckSelector}
        name={["truck", "id"]}
        rules={[{ required: true }]}
      >
        <Select
          placeholder={t("form.placeholder.truck")}
          options={trucks?.map(truck => ({
            label: truck.truckLicense,
            value: truck.id,
          }))}
          onChange={handleSelectTruck}
        />
      </Form.Item>

      <Form.Item
        hidden={!canShowTruckInfo}
        label={t("form.label.driverName")}
        name={["truck", "driversName"]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        hidden={!canShowTruckInfo}
        label={t("form.label.trailerNumber")}
        name={["truck", "trailerNumber"]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item hidden={!canShowTruckInfo} label={t("form.label.externalId")} name={["truck", "externalId"]}>
        <Input disabled />
      </Form.Item>
    </Card>
  );
};

const useStyles = createUseStyles({
  card: {
    marginTop: "16px",

    "& * .ant-input-disabled": {
      color: Colors.Black,
    },
  },
});

import { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Flex, Table, Tag, Typography } from "antd";
import dayjs from "dayjs";

import {
  Deliveries,
  DeliveryProgress_Enum,
  GetDeliveriesQuery,
  Rides,
  useDeleteDeliveryMutation,
} from "graphql-api";

import { toHoursAndMinutes } from "utils";
import { Colors } from "theme";
import { EditIcon } from "assets";
import { DeleteButton, TruncatedText } from "components";
import { RoutePath } from "constant";
import { useNotificationContext } from "context";

interface Props {
  dataSource: GetDeliveriesQuery;
}

export const DeliveriesTable: FC<Props> = props => {
  const { dataSource } = props;
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useNotificationContext();

  const [deleteDeliveryMutation] = useDeleteDeliveryMutation();

  const handleDeleteDelivery = (id: string) => {
    deleteDeliveryMutation({
      variables: { id },
      refetchQueries: ["GetDeliveries"],
      onCompleted: () => {
        notifySuccess(t("notifications.deleteSuccess", { entity: t("common.delivery") }));
      },
      onError(error) {
        notifyError(t("notifications.graphqlError"), error.message);
      },
    });
  };

  return (
    <Table dataSource={dataSource.deliveries} rowKey={record => record.id} rowClassName="app-table-row">
      <Table.Column<Deliveries>
        key="id"
        title={t("table.deliveryId")}
        dataIndex="id"
        render={value => <TruncatedText value={value} />}
      />
      <Table.Column<Deliveries>
        key="rides"
        title={t("table.destination")}
        dataIndex="rides"
        sorter={(a, b) => a.timeLocal.localeCompare(b.timeLocal)}
        render={(value: Rides[]) => {
          const lastRide = value.find(ride => ride.consignee && !ride.vendor);
          if (lastRide) {
            return <span>{lastRide.consignee!.name}</span>;
          }
          return null;
        }}
      />
      <Table.Column<Deliveries>
        key="timeLocal"
        title={t("table.deliveryTime")}
        dataIndex="timeLocal"
        sorter={(a, b) => a.timeLocal.localeCompare(b.timeLocal)}
        render={value => <span>{dayjs(value).format("DD MMM | HH:mm")}</span>}
      />
      <Table.Column<Deliveries>
        key="truck.driversName"
        title={t("table.driver")}
        dataIndex={["truck", "driversName"]}
        sorter={(a, b) => (a.truck?.driversName ?? "").localeCompare(b.truck?.driversName ?? "")}
      />
      <Table.Column<Deliveries>
        key="truck.truckLicense"
        title={t("table.truckLicense")}
        dataIndex={["truck", "truckLicense"]}
        sorter={(a, b) => (a.truck?.truckLicense ?? "").localeCompare(b.truck?.truckLicense ?? "")}
      />
      <Table.Column<Deliveries>
        key="delays_aggregate.aggregate.sum.delayTime"
        title={t("table.delay")}
        dataIndex={["delays_aggregate", "aggregate", "sum", "delayTime"]}
        sorter={(a, b) =>
          (a.delays_aggregate?.aggregate?.sum?.delayTime ?? 0) -
          (b.delays_aggregate?.aggregate?.sum?.delayTime ?? 0)
        }
        render={value => (
          <Typography.Text strong type="danger" style={{ fontSize: 16 }}>
            {value ? toHoursAndMinutes(value) : null}
          </Typography.Text>
        )}
      />
      <Table.Column<Deliveries>
        key="progress"
        title={t("table.progress")}
        dataIndex="progress"
        sorter={(a, b) => a.progress.localeCompare(b.progress)}
        render={value => (
          <Tag color={value === DeliveryProgress_Enum.Active ? Colors.Cyprus : Colors.Aluminium}>{value}</Tag>
        )}
      />
      <Table.Column<Deliveries>
        key="actions"
        title={t("common.actions")}
        fixed="right"
        align="center"
        render={(_, record) => (
          <Flex>
            <Link to={`${RoutePath.EditDelivery}/${record.id}`}>
              <Button type="text" icon={<EditIcon />} />
            </Link>
            <DeleteButton onDelete={() => handleDeleteDelivery(record.id)} />
          </Flex>
        )}
      />
    </Table>
  );
};

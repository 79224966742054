import { FC, ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";

import { KeycloakService, apolloClient } from "config";
import { AuthLocalStorageKeys, UserRole } from "constant";

interface AuthContextType {
  isInitialized: boolean;
  isAuthenticated: boolean;
  userRole: string | undefined;
  clientId: string | undefined;
  logout: () => void;
}

interface AuthProviderProps {
  children?: ReactNode;
}

const initialState: AuthContextType = {
  isInitialized: false,
  isAuthenticated: false,
  userRole: undefined,
  clientId: undefined,
  logout: () => {},
};

const AuthContext = createContext<AuthContextType>(initialState);
let keycloak = KeycloakService.getInstance();

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState<string>();
  const [clientId, setClientId] = useState<string>();

  const authenticateUser = useCallback(async () => {
    if (!keycloak.initialized) {
      await keycloak.initialize();

      setIsInitialized(keycloak.initialized);

      if (keycloak.isAdminUser) {
        setUserRole(UserRole.ADMIN);
        localStorage.setItem(AuthLocalStorageKeys.USER_ROLE, UserRole.ADMIN);
      }

      if (keycloak.isClient) {
        setUserRole(UserRole.CLIENT);
        setClientId(keycloak.keycloak?.tokenParsed?.sub!);
        localStorage.setItem(AuthLocalStorageKeys.USER_ROLE, UserRole.CLIENT);
        localStorage.setItem(AuthLocalStorageKeys.CLIENT_ID, keycloak.keycloak?.tokenParsed?.sub!);
      }

      keycloak.setOnAuthSuccess(() => setIsAuthenticated(true));
    }
  }, []);

  const logout = useCallback(async () => {
    localStorage.clear();
    await apolloClient.clearStore();
    keycloak.logout();
  }, []);

  useEffect(() => {
    authenticateUser();
  }, [authenticateUser]);

  return (
    <AuthContext.Provider value={{ isInitialized, isAuthenticated, userRole, clientId, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

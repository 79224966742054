import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { AuthLocalStorageKeys } from "constant";

const { REACT_APP_API_URL, REACT_APP_HASURA_ADMIN_SECRET } = process.env;

/** Uri to make a requests. */
const httpLink = createHttpLink({
  uri: REACT_APP_API_URL,
});

/** Add authorization token and user role if it's defined. */
const authLink = setContext((_, { headers }) => {
  // get the credencials from local storage if it exists.
  const token = localStorage.getItem(AuthLocalStorageKeys.AUTH_TOKEN);
  const role = localStorage.getItem(AuthLocalStorageKeys.USER_ROLE);

  // return the headers to the context so httpLink can read them.
  return {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      ...(role && { "X-Hasura-Role": role }),
    },
  };
});

/** Apollo Client initialisation */
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  headers: {
    "x-hasura-admin-secret": REACT_APP_HASURA_ADMIN_SECRET!,
  },
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

export { apolloClient };

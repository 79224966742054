import "react-phone-number-input/style.css";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import PhoneInput, { Value } from "react-phone-number-input";

import { Colors } from "theme";

interface Props {
  value?: Value;
  onChange?: (value?: Value) => void;
}

export const AppPhoneInput: FC<Props> = props => {
  const { value, onChange, ...rest } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const triggerFormChange = (changedValue?: Value) => {
    onChange?.(changedValue);
  };

  return (
    <PhoneInput
      defaultCountry="DE"
      placeholder={t("form.placeholder.phone")}
      className={classes.phoneInput}
      value={value}
      onChange={value => triggerFormChange(value)}
      numberInputProps={{
        ...rest,
        className: classes.input,
      }}
    />
  );
};

const useStyles = createUseStyles({
  phoneInput: {
    "& input": {
      "&:focus": {
        borderColor: Colors.Cyprus,
        borderInlineEndWidth: "1px",
        outline: 0,
      },
      "&:hover": {
        borderColor: Colors.Cyprus,
        borderInlineEndWidth: "1px",
        outline: 0,
      },
    },
  },
  input: {
    boxSizing: "border-box",
    borderLeftStyle: "solid",
    borderTopStyle: "solid",
    borderRadius: "6px",
    borderColor: "rgb(217, 217, 217)",
    borderWidth: "1px",
    minHeight: "32px",
  },
});

import { FC, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Form, Input, Row } from "antd";

import { Clients, useCreateClientMutation, useUpdateClientMutation } from "graphql-api";
import { IApiException } from "rest-api";

import { AppPhoneInput } from "../appPhoneInput";
import { useKeycloakCLI } from "hooks";
import { Loader } from "components";
import { RoutePath } from "constant";
import { useNotificationContext } from "context";

interface Props {
  isEditing?: boolean;
  initialValues?: Clients;
}

export const CompanyInformation: FC<Props> = props => {
  const { isEditing, initialValues } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { createNewCompany } = useKeycloakCLI();
  const [createClientMutation] = useCreateClientMutation();
  const [updateClientMutation, { loading: updateClientLoading }] = useUpdateClientMutation();
  const { notifySuccess, notifyError } = useNotificationContext();

  const [isRequestsProcessing, setIsRequestsProcessing] = useState(false);

  const handleSubmit = useCallback(
    (values: Clients) => {
      // Create Keycloak user and Hasura client.
      if (!isEditing) {
        setIsRequestsProcessing(true);

        createNewCompany(values).then(
          (userId: string) => {
            createClientMutation({
              variables: {
                data: {
                  ...values,
                  id: userId,
                },
              },
              onCompleted: () => {
                setIsRequestsProcessing(false);
                notifySuccess(t("notifications.createSuccess", { entity: t("common.company") }));
                navigate(RoutePath.Companies);
              },
              onError(error) {
                setIsRequestsProcessing(false);
                notifyError(t("notifications.graphqlError"), error.message);
              },
            });
          },
          (error: IApiException) => {
            const { status } = error;

            setIsRequestsProcessing(false);
            notifyError(
              t("notifications.error", { statusCode: status }),
              t("notifications.createError", {
                entity: t("common.company"),
                statusCode: status,
              })
            );
          }
        );
      } else {
        // Only update existing Hasura client.
        // TODO: TBD https://dev.azure.com/dev-house-gmbh/trace.way/_backlogs/backlog/trace.way%20Team/Epics/?workitem=1273
        updateClientMutation({
          variables: { id: initialValues?.id, _set: values },
          onCompleted: () => {
            notifySuccess(t("notifications.editSuccess", { entity: t("common.company") }));
            navigate(RoutePath.Companies);
          },
        });
      }
    },
    [
      createClientMutation,
      createNewCompany,
      initialValues?.id,
      isEditing,
      navigate,
      notifyError,
      notifySuccess,
      t,
      updateClientMutation,
    ]
  );

  if (isRequestsProcessing || updateClientLoading) {
    return <Loader />;
  }

  return (
    <Card title={t("components.companyInformation.title")} bordered={false}>
      <Form<Clients> layout="vertical" initialValues={initialValues} onFinish={handleSubmit}>
        <Row gutter={[32, 32]} wrap>
          <Col flex={1}>
            <Form.Item label={t("form.label.companyName")} name="companyName" rules={[{ required: true }]}>
              <Input placeholder={t("form.placeholder.companyName")} />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item label={t("form.label.contactName")} name="name" rules={[{ required: true }]}>
              <Input placeholder={t("form.placeholder.contactName")} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[32, 32]} wrap>
          <Col flex={1}>
            <Form.Item label={t("form.label.address")} name="address">
              <Input placeholder={t("form.placeholder.address")} />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item label={t("form.label.email")} name="email" rules={[{ required: true, type: "email" }]}>
              <Input placeholder={t("form.placeholder.email")} type="email" disabled={isEditing} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[32, 32]} wrap>
          <Col flex={1} span={12}>
            <Form.Item label={t("form.label.phone")} name="phone">
              <AppPhoneInput />
            </Form.Item>
          </Col>
          <Col flex={1} />
        </Row>
        <Row gutter={[32, 32]} justify="end" wrap>
          <Col>
            <Button type="default" onClick={() => navigate(-1)}>
              {t("button.cancel")}
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              {isEditing ? t("button.save") : t("button.create")}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

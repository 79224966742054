import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Button, Flex, Typography } from "antd";

import { RoutePath } from "constant";
import { ForbiddenImage } from "assets";
import { Colors } from "theme";
import { useAuthContext } from "context";

export const AccessDeniedPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { logout, userRole } = useAuthContext();

  return (
    <Flex vertical align="center" justify="center" gap="small" className={classes.container}>
      <ForbiddenImage />
      <Typography.Title>{t("pages.accessDenied.title")}</Typography.Title>
      <Typography.Text className={classes.text}>
        {userRole ? t("pages.accessDenied.restrictedAccess") : t("pages.accessDenied.appRestrictedAccess")}
      </Typography.Text>
      <Typography.Text className={classes.text}>{t("pages.accessDenied.contactAdmin")}</Typography.Text>
      {userRole ? (
        <Link to={RoutePath.Main}>
          <Button type="link">{t("navigation.goToDashboard")}</Button>
        </Link>
      ) : (
        <Button type="primary" onClick={() => logout()} className={classes.logout}>
          {t("pages.accessDenied.switchAccount")}
        </Button>
      )}
    </Flex>
  );
};

const useStyles = createUseStyles({
  container: {
    backgroundColor: Colors.IceBlue,
    height: "100vh",
  },
  text: {
    fontSize: 18,
  },
  logout: {
    marginTop: "15px",
  },
});

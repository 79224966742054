import { StrictMode } from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";

import { AppSider } from "./sider";
import { AppHeader } from "./header";

// Apply StrictMode here cause of Keycloak initialization.
export const AppLayout = () => (
  <StrictMode>
    <Layout style={{ minHeight: "100vh" }}>
      <AppSider />
      <Layout>
        <AppHeader />
        <Layout.Content>
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  </StrictMode>
);

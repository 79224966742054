import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row, Form, Input, Button, Select } from "antd";

import {
  useGetCarriersSuspenseQuery,
  useCreateTruckMutation,
  Trucks_Insert_Input,
  GetTruckByIdQuery,
  useUpdateTruckMutation,
} from "graphql-api";

import { useAuthContext, useNotificationContext } from "context";
import { Loader } from "components";
import { RoutePath } from "constant";

interface Props {
  isEditing?: boolean;
  initialValues?: GetTruckByIdQuery["trucks_by_pk"];
}

export const TruckForm: FC<Props> = props => {
  const { isEditing, initialValues } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId } = useAuthContext();
  const { notifySuccess, notifyError } = useNotificationContext();

  const { data: carriers } = useGetCarriersSuspenseQuery({ variables: { clientId } });
  const [createTruckMutation, { loading: createTruckLoading }] = useCreateTruckMutation();
  const [updateTruckMutation, { loading: updateTruckLoading }] = useUpdateTruckMutation();

  const handleSubmit = (values: Trucks_Insert_Input) => {
    if (!isEditing) {
      createTruckMutation({
        variables: {
          data: {
            ...values,
            clientId,
          },
        },
        onCompleted: () => {
          notifySuccess(t("notifications.createSuccess", { entity: t("common.truck") }));
          navigate(RoutePath.Trucks);
        },
        onError(error) {
          notifyError(t("notifications.graphqlError"), error.message);
        },
      });
    } else {
      updateTruckMutation({
        variables: { id: initialValues?.id, data: values },
        onCompleted: () => {
          notifySuccess(t("notifications.editSuccess", { entity: t("common.truck") }));
          navigate(RoutePath.Trucks);
        },
        onError(error) {
          notifyError(t("notifications.graphqlError"), error.message);
        },
      });
    }
  };

  if (createTruckLoading || updateTruckLoading) {
    return <Loader />;
  }

  return (
    <Card bordered={false}>
      <Form<Trucks_Insert_Input>
        layout="vertical"
        initialValues={initialValues as any}
        onFinish={handleSubmit}
      >
        <Row gutter={[32, 32]} wrap>
          <Col flex={1} span={12}>
            <Form.Item label={t("users.carrier")} name="carrierId" rules={[{ required: true }]}>
              <Select
                placeholder={t("form.placeholder.carrier")}
                options={carriers.carriers.map(carrier => ({
                  label: carrier.name,
                  value: carrier.id,
                }))}
              />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item label={t("form.label.truckLicense")} name="truckLicense" rules={[{ required: true }]}>
              <Input placeholder={t("form.placeholder.truckLicense")} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[32, 32]} wrap>
          <Col flex={1}>
            <Form.Item label={t("form.label.trailerNumber")} name="trailerNumber">
              <Input placeholder={t("form.placeholder.trailerNumber")} />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item label={t("form.label.externalId")} name="externalId">
              <Input placeholder={t("form.placeholder.externalId")} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[32, 32]} wrap>
          <Col flex={1} span={12}>
            <Form.Item label={t("form.label.driverName")} name="driversName">
              <Input placeholder={t("form.placeholder.driverName")} />
            </Form.Item>
          </Col>
          <Col flex={1} />
        </Row>
        <Row gutter={[32, 32]} justify="end" wrap>
          <Col>
            <Button type="default" onClick={() => navigate(-1)}>
              {t("button.cancel")}
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              {isEditing ? t("button.save") : t("button.create")}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

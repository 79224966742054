const FALLBACK_LNG = "de";

enum AuthLocalStorageKeys {
  AUTH_TOKEN = "accessToken",
  REFRESH_TOKEN = "refreshToken",
  USER_ROLE = "userRole",
  CLIENT_ID = "clientId",
}

enum UserRole {
  ADMIN = "web-admin",
  CLIENT = "web-client",
}

enum ClientUsers {
  Vendor = "vendor",
  Carrier = "carrier",
  Consignee = "consignee",
}

enum RoutePath {
  Main = "/",
  Companies = "/companies",
  Settings = "/settings",
  AccessDenied = "/accessDenied",
  CreateCompany = "/createCompany",
  EditCompany = "/editCompany",
  Deliveries = "/deliveries",
  Users = "/users",
  CreateUser = "/createUser",
  EditUser = "/editUser",
  Trucks = "/trucks",
  CreateTruck = "/createTruck",
  EditTruck = "/editTruck",
  CreateDelivery = "/createDelivery",
  EditDelivery = "/editDelivery",
}

enum MenuItems {
  Dashboard,
  Companies,
  Settings,
  Deliveries,
  Users,
  Trucks,
}

enum Languages {
  EN = "en",
  DE = "de",
}

enum UrlSearchParams {
  User = "user",
}

export {
  AuthLocalStorageKeys,
  UserRole,
  RoutePath,
  MenuItems,
  FALLBACK_LNG,
  ClientUsers,
  Languages,
  UrlSearchParams,
};

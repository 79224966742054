import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PageContainer } from "@ant-design/pro-components";
import { Button } from "antd";

import { useGetDeliveriesSuspenseQuery } from "graphql-api";

import { DeliveriesTable } from "components";
import { PlusIcon } from "assets";
import { RoutePath } from "constant";

export const DeliveriesPage = () => {
  const { t } = useTranslation();
  const { data } = useGetDeliveriesSuspenseQuery();

  return (
    <PageContainer
      extra={
        <Link to={RoutePath.CreateDelivery}>
          <Button type="primary" icon={<PlusIcon />}>
            {t("button.createDelivery")}
          </Button>
        </Link>
      }
    >
      <DeliveriesTable dataSource={data} />
    </PageContainer>
  );
};
